package com.tflucke.budget.controllers

import com.tflucke.webroutes.{Http,APIRoute,APIRouteBody}
import play.api.libs.json.{JsValue,Reads,Writes,Json}

object UserController {

def registerUser() = new APIRouteBody[com.tflucke.budget.shared.models.UserRegistration, com.tflucke.budget.shared.models.UserAuthorization]("POST", s"/v1/user?", "application/json") {
    protected def convertBody(body: com.tflucke.budget.shared.models.UserRegistration): Http.Request =
        Json.stringify(Json.toJson(body))
    protected def convertResult(xhr: Http.Response): com.tflucke.budget.shared.models.UserAuthorization =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.UserAuthorization]
    protected def acceptHeader = "application/json"
    protected def contentTypeHeader = "application/json"
}


def accessToken() = new APIRouteBody[com.tflucke.budget.shared.models.GrantRequest, com.tflucke.budget.shared.models.UserAuthorization]("POST", s"/v1/authorize?", "application/json") {
    protected def convertBody(body: com.tflucke.budget.shared.models.GrantRequest): Http.Request =
        Json.stringify(Json.toJson(body))
    protected def convertResult(xhr: Http.Response): com.tflucke.budget.shared.models.UserAuthorization =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.UserAuthorization]
    protected def acceptHeader = "application/json"
    protected def contentTypeHeader = "application/json"
}


def revokeAccessToken() = new APIRouteBody[com.tflucke.budget.shared.models.RefreshRequest, String]("DELETE", s"/v1/authorize?", "text/plain") {
    protected def convertBody(body: com.tflucke.budget.shared.models.RefreshRequest): Http.Request =
        Json.stringify(Json.toJson(body))
    protected def convertResult(xhr: Http.Response): String =
        xhr.responseText
    protected def acceptHeader = "text/plain"
    protected def contentTypeHeader = "application/json"
}

}

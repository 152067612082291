package com.tflucke.webroutes

import org.scalajs.dom.XMLHttpRequest
import org.scalajs.dom.ext.{Ajax,AjaxException}
import scala.concurrent.{Future,ExecutionContext}
import scala.util.{Try,Success,Failure}

object Http {
  def submit(
    method: String,
    url: String,
    body: Option[Http.Request],
    timeout: Int,
    headers: Headers,
    format: String
  )(implicit ec: ExecutionContext): Future[Http.Response] = Ajax(
    method,
    url,
    body.getOrElse(null),
    timeout,
    headers.map,
    false,
    format
  ).transform(wrapFailureException)

  type Request = org.scalajs.dom.ext.Ajax.InputData

  class Response(xhr: XMLHttpRequest) {
    val responseText = xhr.responseText
    val status = xhr.status
    val statusText = xhr.statusText
    def header(name: String) = Option(xhr.getResponseHeader(name))
  }

  private def wrapFailureException[T](x: Try[XMLHttpRequest]) = x match {
    case Success(res) => Success(new Http.Response(res))
    case Failure(ex: AjaxException) if ex.isTimeout && ex.xhr.status == 0 =>
      Failure(TimeoutException(ex))
    case Failure(ex: AjaxException) => Failure(new HTTPException(ex))
    case Failure(ex) => Failure(ex)
  }
}

package com.tflucke.budget.controllers

import com.tflucke.webroutes.{Http,APIRoute,APIRouteBody}
import play.api.libs.json.{JsValue,Reads,Writes,Json}

object CategoryController {

def getCategory(id: Long) = new APIRoute[com.tflucke.budget.shared.models.Category]("GET", s"/v1/category/${id}?id=${id}", "application/json") {
    protected def convert(xhr: Http.Response): com.tflucke.budget.shared.models.Category =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.Category]
    protected def acceptHeader = "application/json"
}


def getCategoryByName(name: String) = new APIRoute[com.tflucke.budget.shared.models.Category]("GET", s"/v1/category/?name=${name}", "application/json") {
    protected def convert(xhr: Http.Response): com.tflucke.budget.shared.models.Category =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.Category]
    protected def acceptHeader = "application/json"
}


def listCategories() = new APIRoute[Seq[com.tflucke.budget.shared.models.Category]]("GET", s"/v1/category?", "application/json") {
    protected def convert(xhr: Http.Response): Seq[com.tflucke.budget.shared.models.Category] =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[Seq[com.tflucke.budget.shared.models.Category]]
    protected def acceptHeader = "application/json"
}


def createCategory() = new APIRouteBody[com.tflucke.budget.shared.models.Category, com.tflucke.budget.shared.models.Category]("POST", s"/v1/category?", "application/json") {
    protected def convertBody(body: com.tflucke.budget.shared.models.Category): Http.Request =
        Json.stringify(Json.toJson(body))
    protected def convertResult(xhr: Http.Response): com.tflucke.budget.shared.models.Category =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.Category]
    protected def acceptHeader = "application/json"
    protected def contentTypeHeader = "application/json"
}


def createCategoryBatch() = new APIRouteBody[Seq[com.tflucke.budget.shared.models.Category], Seq[com.tflucke.budget.shared.models.Category]]("POST", s"/v1/category/batch?", "application/json") {
    protected def convertBody(body: Seq[com.tflucke.budget.shared.models.Category]): Http.Request =
        Json.stringify(Json.toJson(body))
    protected def convertResult(xhr: Http.Response): Seq[com.tflucke.budget.shared.models.Category] =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[Seq[com.tflucke.budget.shared.models.Category]]
    protected def acceptHeader = "application/json"
    protected def contentTypeHeader = "application/json"
}


def updateCategory(id: Long) = new APIRouteBody[com.tflucke.budget.shared.models.Category, com.tflucke.budget.shared.models.Category]("PUT", s"/v1/category/${id}?id=${id}", "application/json") {
    protected def convertBody(body: com.tflucke.budget.shared.models.Category): Http.Request =
        Json.stringify(Json.toJson(body))
    protected def convertResult(xhr: Http.Response): com.tflucke.budget.shared.models.Category =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.Category]
    protected def acceptHeader = "application/json"
    protected def contentTypeHeader = "application/json"
}


def deleteCategory(id: Long) = new APIRoute[com.tflucke.budget.shared.models.Category]("DELETE", s"/v1/category/${id}?id=${id}", "application/json") {
    protected def convert(xhr: Http.Response): com.tflucke.budget.shared.models.Category =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.Category]
    protected def acceptHeader = "application/json"
}

}

package com.tflucke.budget.controllers

import com.tflucke.webroutes.{Http,APIRoute,APIRouteBody}
import play.api.libs.json.{JsValue,Reads,Writes,Json}

object FinancialAccountController {

def getAccount(id: Long) = new APIRoute[com.tflucke.budget.shared.models.FinancialAccount]("GET", s"/v1/account/${id}?id=${id}", "application/json") {
    protected def convert(xhr: Http.Response): com.tflucke.budget.shared.models.FinancialAccount =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.FinancialAccount]
    protected def acceptHeader = "application/json"
}


def getAccountByName(name: String) = new APIRoute[com.tflucke.budget.shared.models.FinancialAccount]("GET", s"/v1/account/?name=${name}", "application/json") {
    protected def convert(xhr: Http.Response): com.tflucke.budget.shared.models.FinancialAccount =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.FinancialAccount]
    protected def acceptHeader = "application/json"
}


def listAccounts() = new APIRoute[Seq[com.tflucke.budget.shared.models.FinancialAccount]]("GET", s"/v1/account?", "application/json") {
    protected def convert(xhr: Http.Response): Seq[com.tflucke.budget.shared.models.FinancialAccount] =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[Seq[com.tflucke.budget.shared.models.FinancialAccount]]
    protected def acceptHeader = "application/json"
}


def createAccount() = new APIRouteBody[com.tflucke.budget.shared.models.FinancialAccount, com.tflucke.budget.shared.models.FinancialAccount]("POST", s"/v1/account?", "application/json") {
    protected def convertBody(body: com.tflucke.budget.shared.models.FinancialAccount): Http.Request =
        Json.stringify(Json.toJson(body))
    protected def convertResult(xhr: Http.Response): com.tflucke.budget.shared.models.FinancialAccount =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.FinancialAccount]
    protected def acceptHeader = "application/json"
    protected def contentTypeHeader = "application/json"
}


def createAccountBatch() = new APIRouteBody[Seq[com.tflucke.budget.shared.models.FinancialAccount], Seq[com.tflucke.budget.shared.models.FinancialAccount]]("POST", s"/v1/account/batch?", "application/json") {
    protected def convertBody(body: Seq[com.tflucke.budget.shared.models.FinancialAccount]): Http.Request =
        Json.stringify(Json.toJson(body))
    protected def convertResult(xhr: Http.Response): Seq[com.tflucke.budget.shared.models.FinancialAccount] =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[Seq[com.tflucke.budget.shared.models.FinancialAccount]]
    protected def acceptHeader = "application/json"
    protected def contentTypeHeader = "application/json"
}


def updateAccount(id: Long) = new APIRouteBody[com.tflucke.budget.shared.models.FinancialAccount, com.tflucke.budget.shared.models.FinancialAccount]("PUT", s"/v1/account/${id}?id=${id}", "application/json") {
    protected def convertBody(body: com.tflucke.budget.shared.models.FinancialAccount): Http.Request =
        Json.stringify(Json.toJson(body))
    protected def convertResult(xhr: Http.Response): com.tflucke.budget.shared.models.FinancialAccount =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.FinancialAccount]
    protected def acceptHeader = "application/json"
    protected def contentTypeHeader = "application/json"
}


def deleteAccount(id: Long) = new APIRoute[com.tflucke.budget.shared.models.FinancialAccount]("DELETE", s"/v1/account/${id}?id=${id}", "application/json") {
    protected def convert(xhr: Http.Response): com.tflucke.budget.shared.models.FinancialAccount =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.FinancialAccount]
    protected def acceptHeader = "application/json"
}

}

package com.tflucke.budget.controllers

import com.tflucke.webroutes.{Http,APIRoute,APIRouteBody}
import play.api.libs.json.{JsValue,Reads,Writes,Json}

object FinancialEventController {

def createEvent() = new APIRouteBody[com.tflucke.budget.shared.models.FinancialEvent, com.tflucke.budget.shared.models.FinancialEvent]("POST", s"/v1/event?", "application/json") {
    protected def convertBody(body: com.tflucke.budget.shared.models.FinancialEvent): Http.Request =
        Json.stringify(Json.toJson(body))
    protected def convertResult(xhr: Http.Response): com.tflucke.budget.shared.models.FinancialEvent =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.FinancialEvent]
    protected def acceptHeader = "application/json"
    protected def contentTypeHeader = "application/json"
}


def createEventBatch() = new APIRouteBody[Seq[com.tflucke.budget.shared.models.FinancialEvent], Seq[com.tflucke.budget.shared.models.FinancialEvent]]("POST", s"/v1/event/batch?", "application/json") {
    protected def convertBody(body: Seq[com.tflucke.budget.shared.models.FinancialEvent]): Http.Request =
        Json.stringify(Json.toJson(body))
    protected def convertResult(xhr: Http.Response): Seq[com.tflucke.budget.shared.models.FinancialEvent] =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[Seq[com.tflucke.budget.shared.models.FinancialEvent]]
    protected def acceptHeader = "application/json"
    protected def contentTypeHeader = "application/json"
}


def updateEvent(id: Long) = new APIRouteBody[com.tflucke.budget.shared.models.FinancialEvent, com.tflucke.budget.shared.models.FinancialEvent]("PUT", s"/v1/event/${id}?id=${id}", "application/json") {
    protected def convertBody(body: com.tflucke.budget.shared.models.FinancialEvent): Http.Request =
        Json.stringify(Json.toJson(body))
    protected def convertResult(xhr: Http.Response): com.tflucke.budget.shared.models.FinancialEvent =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.FinancialEvent]
    protected def acceptHeader = "application/json"
    protected def contentTypeHeader = "application/json"
}


def getEvent(id: Long) = new APIRoute[com.tflucke.budget.shared.models.FinancialEvent]("GET", s"/v1/event/${id}?id=${id}", "application/json") {
    protected def convert(xhr: Http.Response): com.tflucke.budget.shared.models.FinancialEvent =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.FinancialEvent]
    protected def acceptHeader = "application/json"
}


def listEvents() = new APIRoute[Seq[com.tflucke.budget.shared.models.FinancialEvent]]("GET", s"/v1/event?", "application/json") {
    protected def convert(xhr: Http.Response): Seq[com.tflucke.budget.shared.models.FinancialEvent] =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[Seq[com.tflucke.budget.shared.models.FinancialEvent]]
    protected def acceptHeader = "application/json"
}


def listEventsBySubcategory(subcategories: Seq[Long]) = new APIRoute[Seq[com.tflucke.budget.shared.models.FinancialEvent]]("GET", s"/v1/event/?${subcategories.map(v => s"subcategories=$v").mkString("&")}", "application/json") {
    protected def convert(xhr: Http.Response): Seq[com.tflucke.budget.shared.models.FinancialEvent] =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[Seq[com.tflucke.budget.shared.models.FinancialEvent]]
    protected def acceptHeader = "application/json"
}


def deleteEvent(id: Long) = new APIRoute[com.tflucke.budget.shared.models.FinancialEvent]("DELETE", s"/v1/event/${id}?id=${id}", "application/json") {
    protected def convert(xhr: Http.Response): com.tflucke.budget.shared.models.FinancialEvent =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.FinancialEvent]
    protected def acceptHeader = "application/json"
}

}

package com.tflucke.webroutes

case class Headers(val map: Map[String, String]) {
  def +(header: (String, String)) = Headers(this.map + header)
  def -(header: String) = Headers(this.map - header)
}

object Headers {
  def empty = Headers(Map.empty[String, String])
}

package com.tflucke.budget.controllers

import com.tflucke.webroutes.{Http,APIRoute,APIRouteBody}
import play.api.libs.json.{JsValue,Reads,Writes,Json}

object OrganizationController {

def getOrganization(id: Long) = new APIRoute[com.tflucke.budget.shared.models.Organization]("GET", s"/v1/organization/${id}?id=${id}", "application/json") {
    protected def convert(xhr: Http.Response): com.tflucke.budget.shared.models.Organization =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.Organization]
    protected def acceptHeader = "application/json"
}


def getOrganizationByName(name: String) = new APIRoute[com.tflucke.budget.shared.models.Organization]("GET", s"/v1/organization/?name=${name}", "application/json") {
    protected def convert(xhr: Http.Response): com.tflucke.budget.shared.models.Organization =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.Organization]
    protected def acceptHeader = "application/json"
}


def queryOrganizations(q: String) = new APIRoute[Seq[com.tflucke.budget.shared.models.Organization]]("GET", s"/v1/organization?q=${q}", "application/json") {
    protected def convert(xhr: Http.Response): Seq[com.tflucke.budget.shared.models.Organization] =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[Seq[com.tflucke.budget.shared.models.Organization]]
    protected def acceptHeader = "application/json"
}


def createOrganization() = new APIRouteBody[com.tflucke.budget.shared.models.Organization, com.tflucke.budget.shared.models.Organization]("POST", s"/v1/organization?", "application/json") {
    protected def convertBody(body: com.tflucke.budget.shared.models.Organization): Http.Request =
        Json.stringify(Json.toJson(body))
    protected def convertResult(xhr: Http.Response): com.tflucke.budget.shared.models.Organization =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.Organization]
    protected def acceptHeader = "application/json"
    protected def contentTypeHeader = "application/json"
}


def updateOrganization(id: Long) = new APIRouteBody[com.tflucke.budget.shared.models.Organization, com.tflucke.budget.shared.models.Organization]("PUT", s"/v1/organization/${id}?id=${id}", "application/json") {
    protected def convertBody(body: com.tflucke.budget.shared.models.Organization): Http.Request =
        Json.stringify(Json.toJson(body))
    protected def convertResult(xhr: Http.Response): com.tflucke.budget.shared.models.Organization =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.Organization]
    protected def acceptHeader = "application/json"
    protected def contentTypeHeader = "application/json"
}


def deleteOrganization(id: Long) = new APIRoute[com.tflucke.budget.shared.models.Organization]("DELETE", s"/v1/organization/${id}?id=${id}", "application/json") {
    protected def convert(xhr: Http.Response): com.tflucke.budget.shared.models.Organization =
        Json.using[Json.WithDefaultValues].parse(xhr.responseText).as[com.tflucke.budget.shared.models.Organization]
    protected def acceptHeader = "application/json"
}

}

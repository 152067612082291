package com.tflucke.webroutes

import scala.concurrent.{Future,ExecutionContext}
import scala.util.{Try,Success,Failure}

abstract class APIRoute[T](val method: String, val url: String, format: String) {
  protected def convert(xhr: Http.Response): T
  protected def acceptHeader: String

  def apply(timeout: Int = 0)(implicit
    ec: ExecutionContext,
    headers: Headers = Headers.empty
  ) : Future[T] = Http.submit(
    method,
    url,
    None,
    timeout,
    (headers + ("Accept" -> acceptHeader)),
    format
  ).map(convert _)
}

abstract class APIRouteBody[B, R](
  val method: String,
  val url: String,
  format: String
) {
  protected def convertBody(body: B): Http.Request
  protected def convertResult(resp: Http.Response): R
  protected def contentTypeHeader: String
  protected def acceptHeader: String

  def apply(body: B, timeout: Int = 0)(implicit
    ec: ExecutionContext,
    headers: Headers = Headers.empty
  ) : Future[R] = try {
    Http.submit(
      method,
      url,
      Some(convertBody(body)),
      timeout,
      (headers
        + ("Accept" -> acceptHeader)
        + ("Content-Type" -> contentTypeHeader)),
      format
    ).map(convertResult _)
  } catch {
    case ex: Exception => Future.failed(ex)
  }
}

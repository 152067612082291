package com.tflucke.webroutes

import org.scalajs.dom.ext.{Ajax,AjaxException}

class HTTPException(ex: AjaxException) extends Exception {
  type ParserFn[T] = (String) => T

  initCause(ex)

  override val getMessage = ex.xhr.statusText

  val statusCode = ex.xhr.status
  val responseText = ex.xhr.responseText
  def responseType = ex.xhr.responseType
  val statusText = ex.xhr.statusText
  def responseXML() = Option(ex.xhr.responseXML)
  def responseObject[T](implicit fn: ParserFn[T]) = fn(ex.xhr.responseText)
}

object HTTPException {
  def unapply(x: HTTPException): Option[(Int, String)] =
    Some((x.statusCode, x.responseText))
}

package typings.d3

import org.scalablytyped.runtime.NumberDictionary
import org.scalablytyped.runtime.StringDictionary
import org.scalajs.dom.Blob
import org.scalajs.dom.Document
import org.scalajs.dom.Element
import org.scalajs.dom.HTMLAnchorElement
import org.scalajs.dom.HTMLAreaElement
import org.scalajs.dom.HTMLAudioElement
import org.scalajs.dom.HTMLBRElement
import org.scalajs.dom.HTMLBaseElement
import org.scalajs.dom.HTMLBodyElement
import org.scalajs.dom.HTMLButtonElement
import org.scalajs.dom.HTMLCanvasElement
import org.scalajs.dom.HTMLDListElement
import org.scalajs.dom.HTMLDataListElement
import org.scalajs.dom.HTMLDivElement
import org.scalajs.dom.HTMLElement
import org.scalajs.dom.HTMLEmbedElement
import org.scalajs.dom.HTMLFieldSetElement
import org.scalajs.dom.HTMLFormElement
import org.scalajs.dom.HTMLHRElement
import org.scalajs.dom.HTMLHeadElement
import org.scalajs.dom.HTMLHeadingElement
import org.scalajs.dom.HTMLHtmlElement
import org.scalajs.dom.HTMLIFrameElement
import org.scalajs.dom.HTMLImageElement
import org.scalajs.dom.HTMLInputElement
import org.scalajs.dom.HTMLLIElement
import org.scalajs.dom.HTMLLabelElement
import org.scalajs.dom.HTMLLegendElement
import org.scalajs.dom.HTMLLinkElement
import org.scalajs.dom.HTMLMapElement
import org.scalajs.dom.HTMLMenuElement
import org.scalajs.dom.HTMLMetaElement
import org.scalajs.dom.HTMLModElement
import org.scalajs.dom.HTMLOListElement
import org.scalajs.dom.HTMLObjectElement
import org.scalajs.dom.HTMLOptGroupElement
import org.scalajs.dom.HTMLOptionElement
import org.scalajs.dom.HTMLParagraphElement
import org.scalajs.dom.HTMLParamElement
import org.scalajs.dom.HTMLPreElement
import org.scalajs.dom.HTMLProgressElement
import org.scalajs.dom.HTMLQuoteElement
import org.scalajs.dom.HTMLScriptElement
import org.scalajs.dom.HTMLSelectElement
import org.scalajs.dom.HTMLSourceElement
import org.scalajs.dom.HTMLSpanElement
import org.scalajs.dom.HTMLStyleElement
import org.scalajs.dom.HTMLTableCaptionElement
import org.scalajs.dom.HTMLTableColElement
import org.scalajs.dom.HTMLTableElement
import org.scalajs.dom.HTMLTableRowElement
import org.scalajs.dom.HTMLTableSectionElement
import org.scalajs.dom.HTMLTextAreaElement
import org.scalajs.dom.HTMLTitleElement
import org.scalajs.dom.HTMLTrackElement
import org.scalajs.dom.HTMLUListElement
import org.scalajs.dom.HTMLVideoElement
import org.scalajs.dom.Node
import org.scalajs.dom.NodeList
import org.scalajs.dom.RequestInit
import org.scalajs.dom.SVGCircleElement
import org.scalajs.dom.SVGClipPathElement
import org.scalajs.dom.SVGDefsElement
import org.scalajs.dom.SVGDescElement
import org.scalajs.dom.SVGEllipseElement
import org.scalajs.dom.SVGFEBlendElement
import org.scalajs.dom.SVGFEColorMatrixElement
import org.scalajs.dom.SVGFEComponentTransferElement
import org.scalajs.dom.SVGFECompositeElement
import org.scalajs.dom.SVGFEConvolveMatrixElement
import org.scalajs.dom.SVGFEDiffuseLightingElement
import org.scalajs.dom.SVGFEDisplacementMapElement
import org.scalajs.dom.SVGFEDistantLightElement
import org.scalajs.dom.SVGFEFloodElement
import org.scalajs.dom.SVGFEFuncAElement
import org.scalajs.dom.SVGFEFuncBElement
import org.scalajs.dom.SVGFEFuncGElement
import org.scalajs.dom.SVGFEFuncRElement
import org.scalajs.dom.SVGFEGaussianBlurElement
import org.scalajs.dom.SVGFEImageElement
import org.scalajs.dom.SVGFEMergeElement
import org.scalajs.dom.SVGFEMergeNodeElement
import org.scalajs.dom.SVGFEMorphologyElement
import org.scalajs.dom.SVGFEOffsetElement
import org.scalajs.dom.SVGFEPointLightElement
import org.scalajs.dom.SVGFESpecularLightingElement
import org.scalajs.dom.SVGFESpotLightElement
import org.scalajs.dom.SVGFETileElement
import org.scalajs.dom.SVGFETurbulenceElement
import org.scalajs.dom.SVGFilterElement
import org.scalajs.dom.SVGGElement
import org.scalajs.dom.SVGImageElement
import org.scalajs.dom.SVGLineElement
import org.scalajs.dom.SVGLinearGradientElement
import org.scalajs.dom.SVGMarkerElement
import org.scalajs.dom.SVGMaskElement
import org.scalajs.dom.SVGMetadataElement
import org.scalajs.dom.SVGPathElement
import org.scalajs.dom.SVGPatternElement
import org.scalajs.dom.SVGPolygonElement
import org.scalajs.dom.SVGPolylineElement
import org.scalajs.dom.SVGRadialGradientElement
import org.scalajs.dom.SVGRectElement
import org.scalajs.dom.SVGSVGElement
import org.scalajs.dom.SVGStopElement
import org.scalajs.dom.SVGSwitchElement
import org.scalajs.dom.SVGSymbolElement
import org.scalajs.dom.SVGTSpanElement
import org.scalajs.dom.SVGTextElement
import org.scalajs.dom.SVGTextPathElement
import org.scalajs.dom.SVGUseElement
import org.scalajs.dom.SVGViewElement
import org.scalajs.dom.Window
import typings.d3.d3Strings.`implicit`
import typings.d3.d3Strings.`object`
import typings.d3.d3Strings.`var`
import typings.d3.d3Strings.a
import typings.d3.d3Strings.abbr
import typings.d3.d3Strings.address
import typings.d3.d3Strings.applet
import typings.d3.d3Strings.area
import typings.d3.d3Strings.article
import typings.d3.d3Strings.aside
import typings.d3.d3Strings.audio
import typings.d3.d3Strings.b
import typings.d3.d3Strings.base
import typings.d3.d3Strings.bdi
import typings.d3.d3Strings.bdo
import typings.d3.d3Strings.blockquote
import typings.d3.d3Strings.body
import typings.d3.d3Strings.br
import typings.d3.d3Strings.button
import typings.d3.d3Strings.canvas
import typings.d3.d3Strings.caption
import typings.d3.d3Strings.circle
import typings.d3.d3Strings.cite
import typings.d3.d3Strings.clipPath
import typings.d3.d3Strings.code
import typings.d3.d3Strings.col
import typings.d3.d3Strings.colgroup
import typings.d3.d3Strings.data
import typings.d3.d3Strings.datalist
import typings.d3.d3Strings.dd
import typings.d3.d3Strings.defs
import typings.d3.d3Strings.del
import typings.d3.d3Strings.desc
import typings.d3.d3Strings.details
import typings.d3.d3Strings.dfn
import typings.d3.d3Strings.dialog
import typings.d3.d3Strings.dir
import typings.d3.d3Strings.div
import typings.d3.d3Strings.dl
import typings.d3.d3Strings.dt
import typings.d3.d3Strings.ellipse
import typings.d3.d3Strings.em
import typings.d3.d3Strings.embed
import typings.d3.d3Strings.feBlend
import typings.d3.d3Strings.feColorMatrix
import typings.d3.d3Strings.feComponentTransfer
import typings.d3.d3Strings.feComposite
import typings.d3.d3Strings.feConvolveMatrix
import typings.d3.d3Strings.feDiffuseLighting
import typings.d3.d3Strings.feDisplacementMap
import typings.d3.d3Strings.feDistantLight
import typings.d3.d3Strings.feFlood
import typings.d3.d3Strings.feFuncA
import typings.d3.d3Strings.feFuncB
import typings.d3.d3Strings.feFuncG
import typings.d3.d3Strings.feFuncR
import typings.d3.d3Strings.feGaussianBlur
import typings.d3.d3Strings.feImage
import typings.d3.d3Strings.feMerge
import typings.d3.d3Strings.feMergeNode
import typings.d3.d3Strings.feMorphology
import typings.d3.d3Strings.feOffset
import typings.d3.d3Strings.fePointLight
import typings.d3.d3Strings.feSpecularLighting
import typings.d3.d3Strings.feSpotLight
import typings.d3.d3Strings.feTile
import typings.d3.d3Strings.feTurbulence
import typings.d3.d3Strings.fieldset
import typings.d3.d3Strings.figcaption
import typings.d3.d3Strings.figure
import typings.d3.d3Strings.filter
import typings.d3.d3Strings.font
import typings.d3.d3Strings.footer
import typings.d3.d3Strings.foreignObject
import typings.d3.d3Strings.form
import typings.d3.d3Strings.frame
import typings.d3.d3Strings.frameset
import typings.d3.d3Strings.g
import typings.d3.d3Strings.h1
import typings.d3.d3Strings.h2
import typings.d3.d3Strings.h3
import typings.d3.d3Strings.h4
import typings.d3.d3Strings.h5
import typings.d3.d3Strings.h6
import typings.d3.d3Strings.head
import typings.d3.d3Strings.header
import typings.d3.d3Strings.hgroup
import typings.d3.d3Strings.hr
import typings.d3.d3Strings.html
import typings.d3.d3Strings.i
import typings.d3.d3Strings.iframe
import typings.d3.d3Strings.image
import typings.d3.d3Strings.img
import typings.d3.d3Strings.input
import typings.d3.d3Strings.ins
import typings.d3.d3Strings.kbd
import typings.d3.d3Strings.label
import typings.d3.d3Strings.legend
import typings.d3.d3Strings.li
import typings.d3.d3Strings.line
import typings.d3.d3Strings.linearGradient
import typings.d3.d3Strings.link
import typings.d3.d3Strings.main
import typings.d3.d3Strings.map
import typings.d3.d3Strings.mark
import typings.d3.d3Strings.marker
import typings.d3.d3Strings.marquee
import typings.d3.d3Strings.mask
import typings.d3.d3Strings.menu
import typings.d3.d3Strings.meta
import typings.d3.d3Strings.metadata
import typings.d3.d3Strings.meter
import typings.d3.d3Strings.nav
import typings.d3.d3Strings.noscript
import typings.d3.d3Strings.ol
import typings.d3.d3Strings.optgroup
import typings.d3.d3Strings.option
import typings.d3.d3Strings.output
import typings.d3.d3Strings.p
import typings.d3.d3Strings.param
import typings.d3.d3Strings.path
import typings.d3.d3Strings.pattern
import typings.d3.d3Strings.picture
import typings.d3.d3Strings.polygon
import typings.d3.d3Strings.polyline
import typings.d3.d3Strings.pre
import typings.d3.d3Strings.progress
import typings.d3.d3Strings.q
import typings.d3.d3Strings.radialGradient
import typings.d3.d3Strings.rect
import typings.d3.d3Strings.rp
import typings.d3.d3Strings.rt
import typings.d3.d3Strings.ruby
import typings.d3.d3Strings.s
import typings.d3.d3Strings.samp
import typings.d3.d3Strings.script
import typings.d3.d3Strings.section
import typings.d3.d3Strings.select
import typings.d3.d3Strings.slot
import typings.d3.d3Strings.small
import typings.d3.d3Strings.source
import typings.d3.d3Strings.span
import typings.d3.d3Strings.stop
import typings.d3.d3Strings.strong
import typings.d3.d3Strings.style
import typings.d3.d3Strings.sub
import typings.d3.d3Strings.summary
import typings.d3.d3Strings.sup
import typings.d3.d3Strings.svg
import typings.d3.d3Strings.switch
import typings.d3.d3Strings.symbol
import typings.d3.d3Strings.table
import typings.d3.d3Strings.tbody
import typings.d3.d3Strings.td
import typings.d3.d3Strings.template
import typings.d3.d3Strings.text
import typings.d3.d3Strings.textPath
import typings.d3.d3Strings.textarea
import typings.d3.d3Strings.tfoot
import typings.d3.d3Strings.th
import typings.d3.d3Strings.thead
import typings.d3.d3Strings.time
import typings.d3.d3Strings.title
import typings.d3.d3Strings.tr
import typings.d3.d3Strings.track
import typings.d3.d3Strings.tspan
import typings.d3.d3Strings.u
import typings.d3.d3Strings.ul
import typings.d3.d3Strings.use
import typings.d3.d3Strings.video
import typings.d3.d3Strings.view
import typings.d3.d3Strings.wbr
import typings.d3Array.anon.FnCall
import typings.d3Array.mod.Bisector_
import typings.d3Array.mod.HistogramGeneratorDate
import typings.d3Array.mod.HistogramGeneratorNumber
import typings.d3Array.mod.Numeric
import typings.d3Array.mod.Primitive
import typings.d3Axis.mod.Axis
import typings.d3Axis.mod.AxisDomain
import typings.d3Axis.mod.AxisScale
import typings.d3Brush.mod.BrushBehavior
import typings.d3Brush.mod.BrushSelection_
import typings.d3Chord.mod.ChordLayout
import typings.d3Chord.mod.RibbonArrowGenerator
import typings.d3Chord.mod.RibbonGenerator
import typings.d3Chord.mod.RibbonSubgroup
import typings.d3Chord.mod.Ribbon_
import typings.d3Color.mod.ColorCommonInstance
import typings.d3Color.mod.ColorFactory
import typings.d3Color.mod.CubehelixColorFactory
import typings.d3Color.mod.GrayColorFactory
import typings.d3Color.mod.HCLColorFactory
import typings.d3Color.mod.HSLColorFactory
import typings.d3Color.mod.LCHColorFactory
import typings.d3Color.mod.LabColorFactory
import typings.d3Color.mod.RGBColorFactory
import typings.d3Contour.mod.ContourDensity_
import typings.d3Contour.mod.Contours_
import typings.d3Delaunay.mod.Delaunay.GetCoordinate
import typings.d3Delaunay.mod.Delaunay.Point
import typings.d3Dispatch.mod.Dispatch_
import typings.d3Drag.mod.DragBehavior
import typings.d3Drag.mod.DraggedElementBaseType
import typings.d3Drag.mod.SubjectPosition
import typings.d3Dsv.mod.DSV
import typings.d3Dsv.mod.DSVParsedArray
import typings.d3Dsv.mod.DSVRowArray
import typings.d3Dsv.mod.DSVRowString
import typings.d3Ease.mod.BackEasingFactory
import typings.d3Ease.mod.ElasticEasingFactory
import typings.d3Ease.mod.PolynomialEasingFactory
import typings.d3Fetch.anon.PartialHTMLImageElement
import typings.d3Force.mod.ForceCenter_
import typings.d3Force.mod.ForceCollide_
import typings.d3Force.mod.ForceLink_
import typings.d3Force.mod.ForceManyBody_
import typings.d3Force.mod.ForceRadial_
import typings.d3Force.mod.ForceX_
import typings.d3Force.mod.ForceY_
import typings.d3Force.mod.Simulation
import typings.d3Force.mod.SimulationLinkDatum
import typings.d3Force.mod.SimulationNodeDatum
import typings.d3Format.anon.ValueOf
import typings.d3Format.mod.FormatLocaleDefinition
import typings.d3Format.mod.FormatLocaleObject
import typings.d3Format.mod.FormatSpecifierObject
import typings.d3Geo.anon.`0`
import typings.d3Geo.mod.ExtendedFeature
import typings.d3Geo.mod.ExtendedFeatureCollection
import typings.d3Geo.mod.ExtendedGeometryCollection
import typings.d3Geo.mod.GeoCircleGenerator
import typings.d3Geo.mod.GeoConicProjection
import typings.d3Geo.mod.GeoContext
import typings.d3Geo.mod.GeoGeometryObjects
import typings.d3Geo.mod.GeoGraticuleGenerator
import typings.d3Geo.mod.GeoIdentityTransform
import typings.d3Geo.mod.GeoPath_
import typings.d3Geo.mod.GeoPermissibleObjects
import typings.d3Geo.mod.GeoProjection_
import typings.d3Geo.mod.GeoRawProjection
import typings.d3Geo.mod.GeoRotation_
import typings.d3Geo.mod.GeoStreamWrapper
import typings.d3Geo.mod.GeoStream_
import typings.d3Geo.mod.GeoTransformPrototype
import typings.d3Hierarchy.mod.ClusterLayout
import typings.d3Hierarchy.mod.HierarchyNode
import typings.d3Hierarchy.mod.HierarchyRectangularNode
import typings.d3Hierarchy.mod.PackCircle
import typings.d3Hierarchy.mod.PackLayout
import typings.d3Hierarchy.mod.PackRadius
import typings.d3Hierarchy.mod.PartitionLayout
import typings.d3Hierarchy.mod.RatioSquarifyTilingFactory
import typings.d3Hierarchy.mod.StratifyOperator
import typings.d3Hierarchy.mod.TreeLayout
import typings.d3Hierarchy.mod.TreemapLayout
import typings.d3Interpolate.anon.ToString
import typings.d3Interpolate.mod.ArrayInterpolator
import typings.d3Interpolate.mod.ColorGammaInterpolationFactory
import typings.d3Interpolate.mod.NumberArray
import typings.d3Interpolate.mod.ZoomInterpolator
import typings.d3Interpolate.mod.ZoomView
import typings.d3Path.mod.Path_
import typings.d3Quadtree.mod.Quadtree_
import typings.d3Random.mod.RandomBates_
import typings.d3Random.mod.RandomBernoulli_
import typings.d3Random.mod.RandomBeta_
import typings.d3Random.mod.RandomBinomial_
import typings.d3Random.mod.RandomCauchy_
import typings.d3Random.mod.RandomExponential_
import typings.d3Random.mod.RandomGamma_
import typings.d3Random.mod.RandomGeometric_
import typings.d3Random.mod.RandomInt_
import typings.d3Random.mod.RandomIrwinHall_
import typings.d3Random.mod.RandomLogNormal_
import typings.d3Random.mod.RandomLogistic_
import typings.d3Random.mod.RandomNormal_
import typings.d3Random.mod.RandomPareto_
import typings.d3Random.mod.RandomPoisson_
import typings.d3Random.mod.RandomUniform_
import typings.d3Random.mod.RandomWeibull_
import typings.d3Scale.mod.NumberValue
import typings.d3Scale.mod.ScaleBand_
import typings.d3Scale.mod.ScaleDiverging_
import typings.d3Scale.mod.ScaleIdentity_
import typings.d3Scale.mod.ScaleLinear_
import typings.d3Scale.mod.ScaleLogarithmic
import typings.d3Scale.mod.ScaleOrdinal_
import typings.d3Scale.mod.ScalePoint_
import typings.d3Scale.mod.ScalePower
import typings.d3Scale.mod.ScaleQuantile_
import typings.d3Scale.mod.ScaleQuantize_
import typings.d3Scale.mod.ScaleRadial_
import typings.d3Scale.mod.ScaleSequentialQuantile_
import typings.d3Scale.mod.ScaleSequential_
import typings.d3Scale.mod.ScaleSymLog_
import typings.d3Scale.mod.ScaleThreshold_
import typings.d3Scale.mod.ScaleTime_
import typings.d3Selection.mod.BaseType
import typings.d3Selection.mod.EnterElement
import typings.d3Selection.mod.Local_
import typings.d3Selection.mod.NamespaceLocalObject
import typings.d3Selection.mod.NamespaceMap
import typings.d3Selection.mod.SelectionFn
import typings.d3Selection.mod.Selection_
import typings.d3Shape.mod.Arc_
import typings.d3Shape.mod.AreaRadial_
import typings.d3Shape.mod.Area_
import typings.d3Shape.mod.CurveBundleFactory
import typings.d3Shape.mod.CurveCardinalFactory
import typings.d3Shape.mod.CurveCatmullRomFactory
import typings.d3Shape.mod.CurveFactory
import typings.d3Shape.mod.DefaultArcObject
import typings.d3Shape.mod.DefaultLinkObject
import typings.d3Shape.mod.LineRadial_
import typings.d3Shape.mod.Line_
import typings.d3Shape.mod.LinkRadial_
import typings.d3Shape.mod.Link_
import typings.d3Shape.mod.Pie_
import typings.d3Shape.mod.RadialArea_
import typings.d3Shape.mod.RadialLine_
import typings.d3Shape.mod.Series
import typings.d3Shape.mod.Stack_
import typings.d3Shape.mod.SymbolType
import typings.d3Shape.mod.Symbol_
import typings.d3Time.mod.CountableTimeInterval
import typings.d3Time.mod.TimeInterval_
import typings.d3TimeFormat.mod.TimeLocaleDefinition
import typings.d3TimeFormat.mod.TimeLocaleObject
import typings.d3Timer.mod.Timer_
import typings.d3Transition.mod.Transition_
import typings.d3Zoom.mod.ZoomBehavior
import typings.d3Zoom.mod.ZoomedElementBaseType
import typings.geojson.mod.GeoJsonProperties
import typings.geojson.mod.MultiLineString
import typings.std.ArrayLike
import typings.std.HTMLAppletElement
import typings.std.HTMLDataElement
import typings.std.HTMLDetailsElement
import typings.std.HTMLDialogElement
import typings.std.HTMLDirectoryElement
import typings.std.HTMLFontElement
import typings.std.HTMLFrameElement
import typings.std.HTMLFrameSetElement
import typings.std.HTMLMarqueeElement
import typings.std.HTMLMeterElement
import typings.std.HTMLOutputElement
import typings.std.HTMLPictureElement
import typings.std.HTMLSlotElement
import typings.std.HTMLTableDataCellElement
import typings.std.HTMLTableHeaderCellElement
import typings.std.HTMLTemplateElement
import typings.std.HTMLTimeElement
import typings.std.SVGForeignObjectElement
import typings.std.XMLDocument
import org.scalablytyped.runtime.StObject
import scala.scalajs.js
import scala.scalajs.js.`|`
import scala.scalajs.js.annotation.{JSGlobalScope, JSGlobal, JSImport, JSName, JSBracketAccess}

object mod {
  
  @JSImport("d3", JSImport.Namespace)
  @js.native
  val ^ : js.Any = js.native
  
  @JSImport("d3", "Adder")
  @js.native
  /**
    * Creates a full precision adder for IEEE 754 floating point numbers, setting its initial value to 0.
    */
  class Adder ()
    extends typings.d3Array.mod.Adder
  
  @JSImport("d3", "Delaunay")
  @js.native
  class Delaunay[P] protected ()
    extends typings.d3Delaunay.mod.Delaunay[P] {
    /**
      * Returns the Delaunay triangulation for the given flat array [x0, y0, x1, y1, …] of points.
      */
    def this(points: ArrayLike[Double]) = this()
  }
  /* static members */
  object Delaunay {
    
    @JSImport("d3", "Delaunay")
    @js.native
    val ^ : js.Any = js.native
    
    @scala.inline
    def from(points: js.Iterable[Point]): typings.d3Delaunay.mod.Delaunay[Point] = ^.asInstanceOf[js.Dynamic].applyDynamic("from")(points.asInstanceOf[js.Any]).asInstanceOf[typings.d3Delaunay.mod.Delaunay[Point]]
    /**
      * Returns the Delaunay triangulation for the given array or iterable of points where each point is an array in the form: [x, y].
      */
    @scala.inline
    def from(points: ArrayLike[Point]): typings.d3Delaunay.mod.Delaunay[Point] = ^.asInstanceOf[js.Dynamic].applyDynamic("from")(points.asInstanceOf[js.Any]).asInstanceOf[typings.d3Delaunay.mod.Delaunay[Point]]
    @scala.inline
    def from[P](
      points: js.Iterable[P],
      getX: GetCoordinate[P, ArrayLike[P] | js.Iterable[P]],
      getY: GetCoordinate[P, ArrayLike[P] | js.Iterable[P]]
    ): typings.d3Delaunay.mod.Delaunay[P] = (^.asInstanceOf[js.Dynamic].applyDynamic("from")(points.asInstanceOf[js.Any], getX.asInstanceOf[js.Any], getY.asInstanceOf[js.Any])).asInstanceOf[typings.d3Delaunay.mod.Delaunay[P]]
    @scala.inline
    def from[P](
      points: js.Iterable[P],
      getX: GetCoordinate[P, ArrayLike[P] | js.Iterable[P]],
      getY: GetCoordinate[P, ArrayLike[P] | js.Iterable[P]],
      that: Any
    ): typings.d3Delaunay.mod.Delaunay[P] = (^.asInstanceOf[js.Dynamic].applyDynamic("from")(points.asInstanceOf[js.Any], getX.asInstanceOf[js.Any], getY.asInstanceOf[js.Any], that.asInstanceOf[js.Any])).asInstanceOf[typings.d3Delaunay.mod.Delaunay[P]]
    /**
      * Returns the Delaunay triangulation for the given array or iterable of points.
      * Otherwise, the getX and getY functions are invoked for each point in order, and must return the respective x- and y-coordinate for each point.
      * If that is specified, the functions getX and getY are invoked with that as this.
      * (See Array.from for reference.)
      */
    @scala.inline
    def from[P](
      points: ArrayLike[P],
      getX: GetCoordinate[P, ArrayLike[P] | js.Iterable[P]],
      getY: GetCoordinate[P, ArrayLike[P] | js.Iterable[P]]
    ): typings.d3Delaunay.mod.Delaunay[P] = (^.asInstanceOf[js.Dynamic].applyDynamic("from")(points.asInstanceOf[js.Any], getX.asInstanceOf[js.Any], getY.asInstanceOf[js.Any])).asInstanceOf[typings.d3Delaunay.mod.Delaunay[P]]
    @scala.inline
    def from[P](
      points: ArrayLike[P],
      getX: GetCoordinate[P, ArrayLike[P] | js.Iterable[P]],
      getY: GetCoordinate[P, ArrayLike[P] | js.Iterable[P]],
      that: Any
    ): typings.d3Delaunay.mod.Delaunay[P] = (^.asInstanceOf[js.Dynamic].applyDynamic("from")(points.asInstanceOf[js.Any], getX.asInstanceOf[js.Any], getY.asInstanceOf[js.Any], that.asInstanceOf[js.Any])).asInstanceOf[typings.d3Delaunay.mod.Delaunay[P]]
  }
  
  @JSImport("d3", "FormatSpecifier")
  @js.native
  class FormatSpecifier_ protected ()
    extends typings.d3Format.mod.FormatSpecifier_ {
    /**
      * Given the specified specifier object, returning an object with exposed fields that correspond to the format specification mini-language and a toString method that reconstructs the specifier.
      * @param specifier A specifier object.
      */
    def this(specifier: FormatSpecifierObject) = this()
  }
  
  @JSImport("d3", "InternMap")
  @js.native
  class InternMap[K, V] ()
    extends typings.d3Array.mod.InternMap[K, V]
  
  @JSImport("d3", "InternSet")
  @js.native
  class InternSet[T] ()
    extends typings.d3Array.mod.InternSet[T]
  
  @JSImport("d3", "Voronoi")
  @js.native
  class Voronoi[P] ()
    extends typings.d3Delaunay.mod.Voronoi[P]
  
  @JSImport("d3", "ZoomTransform")
  @js.native
  class ZoomTransform_ protected ()
    extends typings.d3Zoom.mod.ZoomTransform_ {
    /**
      * Returns a transform with scale k and translation (x, y).
      */
    def this(k: Double, x: Double, y: Double) = this()
  }
  
  @scala.inline
  def active[Datum, PElement /* <: BaseType */, PDatum](): (Transition_[Null, Datum, PElement, PDatum]) | Null = ^.asInstanceOf[js.Dynamic].applyDynamic("active")().asInstanceOf[(Transition_[Null, Datum, PElement, PDatum]) | Null]
  @scala.inline
  def active[Datum, PElement /* <: BaseType */, PDatum](node: Document): (Transition_[Document, Datum, PElement, PDatum]) | Null = ^.asInstanceOf[js.Dynamic].applyDynamic("active")(node.asInstanceOf[js.Any]).asInstanceOf[(Transition_[Document, Datum, PElement, PDatum]) | Null]
  @scala.inline
  def active[Datum, PElement /* <: BaseType */, PDatum](node: Document, name: String): (Transition_[Document, Datum, PElement, PDatum]) | Null = (^.asInstanceOf[js.Dynamic].applyDynamic("active")(node.asInstanceOf[js.Any], name.asInstanceOf[js.Any])).asInstanceOf[(Transition_[Document, Datum, PElement, PDatum]) | Null]
  @scala.inline
  def active[Datum, PElement /* <: BaseType */, PDatum](node: Element): (Transition_[Element, Datum, PElement, PDatum]) | Null = ^.asInstanceOf[js.Dynamic].applyDynamic("active")(node.asInstanceOf[js.Any]).asInstanceOf[(Transition_[Element, Datum, PElement, PDatum]) | Null]
  @scala.inline
  def active[Datum, PElement /* <: BaseType */, PDatum](node: Element, name: String): (Transition_[Element, Datum, PElement, PDatum]) | Null = (^.asInstanceOf[js.Dynamic].applyDynamic("active")(node.asInstanceOf[js.Any], name.asInstanceOf[js.Any])).asInstanceOf[(Transition_[Element, Datum, PElement, PDatum]) | Null]
  @scala.inline
  def active[Datum, PElement /* <: BaseType */, PDatum](node: Window): (Transition_[Window, Datum, PElement, PDatum]) | Null = ^.asInstanceOf[js.Dynamic].applyDynamic("active")(node.asInstanceOf[js.Any]).asInstanceOf[(Transition_[Window, Datum, PElement, PDatum]) | Null]
  @scala.inline
  def active[Datum, PElement /* <: BaseType */, PDatum](node: Window, name: String): (Transition_[Window, Datum, PElement, PDatum]) | Null = (^.asInstanceOf[js.Dynamic].applyDynamic("active")(node.asInstanceOf[js.Any], name.asInstanceOf[js.Any])).asInstanceOf[(Transition_[Window, Datum, PElement, PDatum]) | Null]
  @scala.inline
  def active[Datum, PElement /* <: BaseType */, PDatum](node: Null, name: String): (Transition_[Null, Datum, PElement, PDatum]) | Null = (^.asInstanceOf[js.Dynamic].applyDynamic("active")(node.asInstanceOf[js.Any], name.asInstanceOf[js.Any])).asInstanceOf[(Transition_[Null, Datum, PElement, PDatum]) | Null]
  @scala.inline
  def active[Datum, PElement /* <: BaseType */, PDatum](node: EnterElement): (Transition_[EnterElement, Datum, PElement, PDatum]) | Null = ^.asInstanceOf[js.Dynamic].applyDynamic("active")(node.asInstanceOf[js.Any]).asInstanceOf[(Transition_[EnterElement, Datum, PElement, PDatum]) | Null]
  @scala.inline
  def active[Datum, PElement /* <: BaseType */, PDatum](node: EnterElement, name: String): (Transition_[EnterElement, Datum, PElement, PDatum]) | Null = (^.asInstanceOf[js.Dynamic].applyDynamic("active")(node.asInstanceOf[js.Any], name.asInstanceOf[js.Any])).asInstanceOf[(Transition_[EnterElement, Datum, PElement, PDatum]) | Null]
  
  @scala.inline
  def arc(): Arc_[Any, DefaultArcObject] = ^.asInstanceOf[js.Dynamic].applyDynamic("arc")().asInstanceOf[Arc_[Any, DefaultArcObject]]
  
  @scala.inline
  def arc_Datum[Datum](): Arc_[Any, Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("arc")().asInstanceOf[Arc_[Any, Datum]]
  
  @scala.inline
  def arc_ThisDatum[This, Datum](): Arc_[This, Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("arc")().asInstanceOf[Arc_[This, Datum]]
  
  @scala.inline
  def area[Datum](): Area_[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("area")().asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](x: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double]): Area_[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any]).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](
    x: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double],
    y0: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double]
  ): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](
    x: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double],
    y0: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double],
    y1: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double]
  ): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](
    x: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double],
    y0: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double],
    y1: Double
  ): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](x: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double], y0: Double): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](
    x: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double],
    y0: Double,
    y1: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double]
  ): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](
    x: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double],
    y0: Double,
    y1: Double
  ): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](
    x: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double],
    y0: Unit,
    y1: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double]
  ): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](
    x: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double],
    y0: Unit,
    y1: Double
  ): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](x: Double): Area_[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any]).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](x: Double, y0: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double]): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](
    x: Double,
    y0: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double],
    y1: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double]
  ): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](
    x: Double,
    y0: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double],
    y1: Double
  ): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](x: Double, y0: Double): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](
    x: Double,
    y0: Double,
    y1: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double]
  ): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](x: Double, y0: Double, y1: Double): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](
    x: Double,
    y0: Unit,
    y1: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double]
  ): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](x: Double, y0: Unit, y1: Double): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](x: Unit, y0: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double]): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](
    x: Unit,
    y0: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double],
    y1: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double]
  ): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](
    x: Unit,
    y0: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double],
    y1: Double
  ): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](x: Unit, y0: Double): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](
    x: Unit,
    y0: Double,
    y1: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double]
  ): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](x: Unit, y0: Double, y1: Double): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](
    x: Unit,
    y0: Unit,
    y1: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double]
  ): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  @scala.inline
  def area[Datum](x: Unit, y0: Unit, y1: Double): Area_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("area")(x.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Area_[Datum]]
  
  @scala.inline
  def areaRadial(): AreaRadial_[js.Tuple2[Double, Double]] = ^.asInstanceOf[js.Dynamic].applyDynamic("areaRadial")().asInstanceOf[AreaRadial_[js.Tuple2[Double, Double]]]
  
  @scala.inline
  def areaRadial_Datum[Datum](): AreaRadial_[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("areaRadial")().asInstanceOf[AreaRadial_[Datum]]
  
  @scala.inline
  def ascending(): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("ascending")().asInstanceOf[Double]
  @scala.inline
  def ascending(a: Unit, b: Primitive): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("ascending")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def ascending(a: Primitive): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("ascending")(a.asInstanceOf[js.Any]).asInstanceOf[Double]
  @scala.inline
  def ascending(a: Primitive, b: Primitive): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("ascending")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[Double]
  
  @scala.inline
  def autoType[ParsedRow /* <: js.UndefOr[js.Object | Null] */, Columns /* <: String */](// tslint:disable-next-line:no-unnecessary-generics
  `object`: js.Array[String]): ParsedRow = ^.asInstanceOf[js.Dynamic].applyDynamic("autoType")(`object`.asInstanceOf[js.Any]).asInstanceOf[ParsedRow]
  @scala.inline
  def autoType[ParsedRow /* <: js.UndefOr[js.Object | Null] */, Columns /* <: String */](// tslint:disable-next-line:no-unnecessary-generics
  `object`: DSVRowString[Columns]): ParsedRow = ^.asInstanceOf[js.Dynamic].applyDynamic("autoType")(`object`.asInstanceOf[js.Any]).asInstanceOf[ParsedRow]
  
  @scala.inline
  def axisBottom[Domain /* <: AxisDomain */](scale: AxisScale[Domain]): Axis[Domain] = ^.asInstanceOf[js.Dynamic].applyDynamic("axisBottom")(scale.asInstanceOf[js.Any]).asInstanceOf[Axis[Domain]]
  
  @scala.inline
  def axisLeft[Domain /* <: AxisDomain */](scale: AxisScale[Domain]): Axis[Domain] = ^.asInstanceOf[js.Dynamic].applyDynamic("axisLeft")(scale.asInstanceOf[js.Any]).asInstanceOf[Axis[Domain]]
  
  @scala.inline
  def axisRight[Domain /* <: AxisDomain */](scale: AxisScale[Domain]): Axis[Domain] = ^.asInstanceOf[js.Dynamic].applyDynamic("axisRight")(scale.asInstanceOf[js.Any]).asInstanceOf[Axis[Domain]]
  
  @scala.inline
  def axisTop[Domain /* <: AxisDomain */](scale: AxisScale[Domain]): Axis[Domain] = ^.asInstanceOf[js.Dynamic].applyDynamic("axisTop")(scale.asInstanceOf[js.Any]).asInstanceOf[Axis[Domain]]
  
  @scala.inline
  def bin(): HistogramGeneratorNumber[Double, Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("bin")().asInstanceOf[HistogramGeneratorNumber[Double, Double]]
  
  @scala.inline
  def `bin_DatumValue_UnionDate<undefined>_HistogramGeneratorDate`[Datum, Value /* <: js.UndefOr[js.Date] */](): HistogramGeneratorDate[Datum, Value] = ^.asInstanceOf[js.Dynamic].applyDynamic("bin")().asInstanceOf[HistogramGeneratorDate[Datum, Value]]
  
  @scala.inline
  def `bin_DatumValue_UnionDouble<undefined>`[Datum, Value /* <: js.UndefOr[Double] */](): HistogramGeneratorNumber[Datum, Value] = ^.asInstanceOf[js.Dynamic].applyDynamic("bin")().asInstanceOf[HistogramGeneratorNumber[Datum, Value]]
  
  @scala.inline
  def bisect(array: ArrayLike[String], x: String): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisect")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisect(array: ArrayLike[String], x: String, lo: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisect")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisect(array: ArrayLike[String], x: String, lo: Double, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisect")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisect(array: ArrayLike[String], x: String, lo: Unit, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisect")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisect(array: ArrayLike[js.Date], x: js.Date): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisect")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisect(array: ArrayLike[js.Date], x: js.Date, lo: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisect")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisect(array: ArrayLike[js.Date], x: js.Date, lo: Double, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisect")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisect(array: ArrayLike[js.Date], x: js.Date, lo: Unit, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisect")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisect(array: ArrayLike[Double], x: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisect")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisect(array: ArrayLike[Double], x: Double, lo: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisect")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisect(array: ArrayLike[Double], x: Double, lo: Double, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisect")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisect(array: ArrayLike[Double], x: Double, lo: Unit, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisect")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  
  @scala.inline
  def bisectCenter(array: ArrayLike[String], x: String): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectCenter")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectCenter(array: ArrayLike[String], x: String, lo: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectCenter")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectCenter(array: ArrayLike[String], x: String, lo: Double, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectCenter")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectCenter(array: ArrayLike[String], x: String, lo: Unit, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectCenter")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectCenter(array: ArrayLike[js.Date], x: js.Date): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectCenter")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectCenter(array: ArrayLike[js.Date], x: js.Date, lo: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectCenter")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectCenter(array: ArrayLike[js.Date], x: js.Date, lo: Double, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectCenter")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectCenter(array: ArrayLike[js.Date], x: js.Date, lo: Unit, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectCenter")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectCenter(array: ArrayLike[Double], x: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectCenter")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectCenter(array: ArrayLike[Double], x: Double, lo: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectCenter")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectCenter(array: ArrayLike[Double], x: Double, lo: Double, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectCenter")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectCenter(array: ArrayLike[Double], x: Double, lo: Unit, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectCenter")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  
  @scala.inline
  def bisectLeft(array: ArrayLike[String], x: String): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectLeft")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectLeft(array: ArrayLike[String], x: String, lo: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectLeft")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectLeft(array: ArrayLike[String], x: String, lo: Double, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectLeft")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectLeft(array: ArrayLike[String], x: String, lo: Unit, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectLeft")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectLeft(array: ArrayLike[js.Date], x: js.Date): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectLeft")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectLeft(array: ArrayLike[js.Date], x: js.Date, lo: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectLeft")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectLeft(array: ArrayLike[js.Date], x: js.Date, lo: Double, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectLeft")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectLeft(array: ArrayLike[js.Date], x: js.Date, lo: Unit, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectLeft")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectLeft(array: ArrayLike[Double], x: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectLeft")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectLeft(array: ArrayLike[Double], x: Double, lo: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectLeft")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectLeft(array: ArrayLike[Double], x: Double, lo: Double, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectLeft")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectLeft(array: ArrayLike[Double], x: Double, lo: Unit, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectLeft")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  
  @scala.inline
  def bisectRight(array: ArrayLike[String], x: String): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectRight")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectRight(array: ArrayLike[String], x: String, lo: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectRight")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectRight(array: ArrayLike[String], x: String, lo: Double, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectRight")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectRight(array: ArrayLike[String], x: String, lo: Unit, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectRight")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectRight(array: ArrayLike[js.Date], x: js.Date): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectRight")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectRight(array: ArrayLike[js.Date], x: js.Date, lo: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectRight")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectRight(array: ArrayLike[js.Date], x: js.Date, lo: Double, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectRight")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectRight(array: ArrayLike[js.Date], x: js.Date, lo: Unit, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectRight")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectRight(array: ArrayLike[Double], x: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectRight")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectRight(array: ArrayLike[Double], x: Double, lo: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectRight")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectRight(array: ArrayLike[Double], x: Double, lo: Double, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectRight")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def bisectRight(array: ArrayLike[Double], x: Double, lo: Unit, hi: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("bisectRight")(array.asInstanceOf[js.Any], x.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[Double]
  
  @scala.inline
  def bisector[T, U](accessor: js.Function1[/* x */ T, U]): Bisector_[T, U] = ^.asInstanceOf[js.Dynamic].applyDynamic("bisector")(accessor.asInstanceOf[js.Any]).asInstanceOf[Bisector_[T, U]]
  @scala.inline
  def bisector[T, U](comparator: js.Function2[/* a */ T, /* b */ U, Double]): Bisector_[T, U] = ^.asInstanceOf[js.Dynamic].applyDynamic("bisector")(comparator.asInstanceOf[js.Any]).asInstanceOf[Bisector_[T, U]]
  
  @scala.inline
  def blob(url: String): js.Promise[Blob] = ^.asInstanceOf[js.Dynamic].applyDynamic("blob")(url.asInstanceOf[js.Any]).asInstanceOf[js.Promise[Blob]]
  @scala.inline
  def blob(url: String, init: RequestInit): js.Promise[Blob] = (^.asInstanceOf[js.Dynamic].applyDynamic("blob")(url.asInstanceOf[js.Any], init.asInstanceOf[js.Any])).asInstanceOf[js.Promise[Blob]]
  
  @scala.inline
  def brush[Datum](): BrushBehavior[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("brush")().asInstanceOf[BrushBehavior[Datum]]
  
  @scala.inline
  def brushSelection(node: SVGGElement): BrushSelection_ | Null = ^.asInstanceOf[js.Dynamic].applyDynamic("brushSelection")(node.asInstanceOf[js.Any]).asInstanceOf[BrushSelection_ | Null]
  
  @scala.inline
  def brushX[Datum](): BrushBehavior[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("brushX")().asInstanceOf[BrushBehavior[Datum]]
  
  @scala.inline
  def brushY[Datum](): BrushBehavior[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("brushY")().asInstanceOf[BrushBehavior[Datum]]
  
  @scala.inline
  def buffer(url: String): js.Promise[js.typedarray.ArrayBuffer] = ^.asInstanceOf[js.Dynamic].applyDynamic("buffer")(url.asInstanceOf[js.Any]).asInstanceOf[js.Promise[js.typedarray.ArrayBuffer]]
  @scala.inline
  def buffer(url: String, init: RequestInit): js.Promise[js.typedarray.ArrayBuffer] = (^.asInstanceOf[js.Dynamic].applyDynamic("buffer")(url.asInstanceOf[js.Any], init.asInstanceOf[js.Any])).asInstanceOf[js.Promise[js.typedarray.ArrayBuffer]]
  
  @scala.inline
  def chord(): ChordLayout = ^.asInstanceOf[js.Dynamic].applyDynamic("chord")().asInstanceOf[ChordLayout]
  
  @scala.inline
  def chordDirected(): ChordLayout = ^.asInstanceOf[js.Dynamic].applyDynamic("chordDirected")().asInstanceOf[ChordLayout]
  
  @scala.inline
  def chordTranspose(): ChordLayout = ^.asInstanceOf[js.Dynamic].applyDynamic("chordTranspose")().asInstanceOf[ChordLayout]
  
  @scala.inline
  def cluster[Datum](): ClusterLayout[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("cluster")().asInstanceOf[ClusterLayout[Datum]]
  
  @JSImport("d3", "color")
  @js.native
  val color: ColorFactory = js.native
  
  @scala.inline
  def contourDensity[Datum](): ContourDensity_[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("contourDensity")().asInstanceOf[ContourDensity_[Datum]]
  
  @scala.inline
  def contours(): Contours_ = ^.asInstanceOf[js.Dynamic].applyDynamic("contours")().asInstanceOf[Contours_]
  
  @scala.inline
  def count(iterable: js.Iterable[Any]): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("count")(iterable.asInstanceOf[js.Any]).asInstanceOf[Double]
  @scala.inline
  def count[TObject](
    iterable: js.Iterable[TObject],
    accessor: js.Function2[/* a */ TObject, /* b */ TObject, js.UndefOr[Double | Null]]
  ): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("count")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[Double]
  
  @scala.inline
  def create[NewGElement /* <: Element */](name: String): Selection_[NewGElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[NewGElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_a(name: a): Selection_[HTMLAnchorElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLAnchorElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_abbr(name: abbr): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_address(name: address): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_applet(name: applet): Selection_[HTMLAppletElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLAppletElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_area(name: area): Selection_[HTMLAreaElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLAreaElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_article(name: article): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_aside(name: aside): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_audio(name: audio): Selection_[HTMLAudioElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLAudioElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_b(name: b): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_base(name: base): Selection_[HTMLBaseElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLBaseElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_bdi(name: bdi): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_bdo(name: bdo): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_blockquote(name: blockquote): Selection_[HTMLQuoteElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLQuoteElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_body(name: body): Selection_[HTMLBodyElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLBodyElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_br(name: br): Selection_[HTMLBRElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLBRElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_button(name: button): Selection_[HTMLButtonElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLButtonElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_canvas(name: canvas): Selection_[HTMLCanvasElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLCanvasElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_caption(name: caption): Selection_[HTMLTableCaptionElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLTableCaptionElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_circle(name: circle): Selection_[SVGCircleElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGCircleElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_cite(name: cite): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_clipPath(name: clipPath): Selection_[SVGClipPathElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGClipPathElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_code(name: code): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_col(name: col): Selection_[HTMLTableColElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLTableColElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_colgroup(name: colgroup): Selection_[HTMLTableColElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLTableColElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_data(name: data): Selection_[HTMLDataElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLDataElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_datalist(name: datalist): Selection_[HTMLDataListElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLDataListElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_dd(name: dd): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_defs(name: defs): Selection_[SVGDefsElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGDefsElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_del(name: del): Selection_[HTMLModElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLModElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_desc(name: desc): Selection_[SVGDescElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGDescElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_details(name: details): Selection_[HTMLDetailsElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLDetailsElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_dfn(name: dfn): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_dialog(name: dialog): Selection_[HTMLDialogElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLDialogElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_dir(name: dir): Selection_[HTMLDirectoryElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLDirectoryElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_div(name: div): Selection_[HTMLDivElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLDivElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_dl(name: dl): Selection_[HTMLDListElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLDListElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_dt(name: dt): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_ellipse(name: ellipse): Selection_[SVGEllipseElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGEllipseElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_em(name: em): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_embed(name: embed): Selection_[HTMLEmbedElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLEmbedElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feBlend(name: feBlend): Selection_[SVGFEBlendElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFEBlendElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feColorMatrix(name: feColorMatrix): Selection_[SVGFEColorMatrixElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFEColorMatrixElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feComponentTransfer(name: feComponentTransfer): Selection_[SVGFEComponentTransferElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFEComponentTransferElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feComposite(name: feComposite): Selection_[SVGFECompositeElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFECompositeElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feConvolveMatrix(name: feConvolveMatrix): Selection_[SVGFEConvolveMatrixElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFEConvolveMatrixElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feDiffuseLighting(name: feDiffuseLighting): Selection_[SVGFEDiffuseLightingElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFEDiffuseLightingElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feDisplacementMap(name: feDisplacementMap): Selection_[SVGFEDisplacementMapElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFEDisplacementMapElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feDistantLight(name: feDistantLight): Selection_[SVGFEDistantLightElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFEDistantLightElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feFlood(name: feFlood): Selection_[SVGFEFloodElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFEFloodElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feFuncA(name: feFuncA): Selection_[SVGFEFuncAElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFEFuncAElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feFuncB(name: feFuncB): Selection_[SVGFEFuncBElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFEFuncBElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feFuncG(name: feFuncG): Selection_[SVGFEFuncGElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFEFuncGElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feFuncR(name: feFuncR): Selection_[SVGFEFuncRElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFEFuncRElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feGaussianBlur(name: feGaussianBlur): Selection_[SVGFEGaussianBlurElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFEGaussianBlurElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feImage(name: feImage): Selection_[SVGFEImageElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFEImageElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feMerge(name: feMerge): Selection_[SVGFEMergeElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFEMergeElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feMergeNode(name: feMergeNode): Selection_[SVGFEMergeNodeElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFEMergeNodeElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feMorphology(name: feMorphology): Selection_[SVGFEMorphologyElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFEMorphologyElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feOffset(name: feOffset): Selection_[SVGFEOffsetElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFEOffsetElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_fePointLight(name: fePointLight): Selection_[SVGFEPointLightElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFEPointLightElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feSpecularLighting(name: feSpecularLighting): Selection_[SVGFESpecularLightingElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFESpecularLightingElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feSpotLight(name: feSpotLight): Selection_[SVGFESpotLightElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFESpotLightElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feTile(name: feTile): Selection_[SVGFETileElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFETileElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_feTurbulence(name: feTurbulence): Selection_[SVGFETurbulenceElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFETurbulenceElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_fieldset(name: fieldset): Selection_[HTMLFieldSetElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLFieldSetElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_figcaption(name: figcaption): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_figure(name: figure): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_filter(name: filter): Selection_[SVGFilterElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGFilterElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_font(name: font): Selection_[HTMLFontElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLFontElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_footer(name: footer): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_foreignObject(name: foreignObject): Selection_[SVGForeignObjectElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGForeignObjectElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_form(name: form): Selection_[HTMLFormElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLFormElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_frame(name: frame): Selection_[HTMLFrameElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLFrameElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_frameset(name: frameset): Selection_[HTMLFrameSetElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLFrameSetElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_g(name: g): Selection_[SVGGElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGGElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_h1(name: h1): Selection_[HTMLHeadingElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLHeadingElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_h2(name: h2): Selection_[HTMLHeadingElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLHeadingElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_h3(name: h3): Selection_[HTMLHeadingElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLHeadingElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_h4(name: h4): Selection_[HTMLHeadingElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLHeadingElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_h5(name: h5): Selection_[HTMLHeadingElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLHeadingElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_h6(name: h6): Selection_[HTMLHeadingElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLHeadingElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_head(name: head): Selection_[HTMLHeadElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLHeadElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_header(name: header): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_hgroup(name: hgroup): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_hr(name: hr): Selection_[HTMLHRElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLHRElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_html(name: html): Selection_[HTMLHtmlElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLHtmlElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_i(name: i): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_iframe(name: iframe): Selection_[HTMLIFrameElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLIFrameElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_image(name: image): Selection_[SVGImageElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGImageElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_img(name: img): Selection_[HTMLImageElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLImageElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_input(name: input): Selection_[HTMLInputElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLInputElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_ins(name: ins): Selection_[HTMLModElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLModElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_kbd(name: kbd): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_label(name: label): Selection_[HTMLLabelElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLLabelElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_legend(name: legend): Selection_[HTMLLegendElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLLegendElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_li(name: li): Selection_[HTMLLIElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLLIElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_line(name: line): Selection_[SVGLineElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGLineElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_linearGradient(name: linearGradient): Selection_[SVGLinearGradientElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGLinearGradientElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_link(name: link): Selection_[HTMLLinkElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLLinkElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_main(name: main): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_map(name: map): Selection_[HTMLMapElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLMapElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_mark(name: mark): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_marker(name: marker): Selection_[SVGMarkerElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGMarkerElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_marquee(name: marquee): Selection_[HTMLMarqueeElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLMarqueeElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_mask(name: mask): Selection_[SVGMaskElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGMaskElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_menu(name: menu): Selection_[HTMLMenuElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLMenuElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_meta(name: meta): Selection_[HTMLMetaElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLMetaElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_metadata(name: metadata): Selection_[SVGMetadataElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGMetadataElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_meter(name: meter): Selection_[HTMLMeterElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLMeterElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_nav(name: nav): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_noscript(name: noscript): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_object(name: `object`): Selection_[HTMLObjectElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLObjectElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_ol(name: ol): Selection_[HTMLOListElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLOListElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_optgroup(name: optgroup): Selection_[HTMLOptGroupElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLOptGroupElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_option(name: option): Selection_[HTMLOptionElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLOptionElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_output(name: output): Selection_[HTMLOutputElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLOutputElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_p(name: p): Selection_[HTMLParagraphElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLParagraphElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_param(name: param): Selection_[HTMLParamElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLParamElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_path(name: path): Selection_[SVGPathElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGPathElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_pattern(name: pattern): Selection_[SVGPatternElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGPatternElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_picture(name: picture): Selection_[HTMLPictureElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLPictureElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_polygon(name: polygon): Selection_[SVGPolygonElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGPolygonElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_polyline(name: polyline): Selection_[SVGPolylineElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGPolylineElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_pre(name: pre): Selection_[HTMLPreElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLPreElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_progress(name: progress): Selection_[HTMLProgressElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLProgressElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_q(name: q): Selection_[HTMLQuoteElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLQuoteElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_radialGradient(name: radialGradient): Selection_[SVGRadialGradientElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGRadialGradientElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_rect(name: rect): Selection_[SVGRectElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGRectElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_rp(name: rp): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_rt(name: rt): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_ruby(name: ruby): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_s(name: s): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_samp(name: samp): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_script(name: script): Selection_[HTMLScriptElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLScriptElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_section(name: section): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_select(name: select): Selection_[HTMLSelectElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLSelectElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_slot(name: slot): Selection_[HTMLSlotElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLSlotElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_small(name: small): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_source(name: source): Selection_[HTMLSourceElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLSourceElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_span(name: span): Selection_[HTMLSpanElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLSpanElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_stop(name: stop): Selection_[SVGStopElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGStopElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_strong(name: strong): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_style(name: style): Selection_[HTMLStyleElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLStyleElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_sub(name: sub): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_summary(name: summary): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_sup(name: sup): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_svg(name: svg): Selection_[SVGSVGElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGSVGElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_switch(name: switch): Selection_[SVGSwitchElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGSwitchElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_symbol(name: symbol): Selection_[SVGSymbolElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGSymbolElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_table(name: table): Selection_[HTMLTableElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLTableElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_tbody(name: tbody): Selection_[HTMLTableSectionElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLTableSectionElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_td(name: td): Selection_[HTMLTableDataCellElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLTableDataCellElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_template(name: template): Selection_[HTMLTemplateElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLTemplateElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_text(name: text): Selection_[SVGTextElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGTextElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_textPath(name: textPath): Selection_[SVGTextPathElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGTextPathElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_textarea(name: textarea): Selection_[HTMLTextAreaElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLTextAreaElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_tfoot(name: tfoot): Selection_[HTMLTableSectionElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLTableSectionElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_th(name: th): Selection_[HTMLTableHeaderCellElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLTableHeaderCellElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_thead(name: thead): Selection_[HTMLTableSectionElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLTableSectionElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_time(name: time): Selection_[HTMLTimeElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLTimeElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_title(name: title): Selection_[HTMLTitleElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLTitleElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_tr(name: tr): Selection_[HTMLTableRowElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLTableRowElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_track(name: track): Selection_[HTMLTrackElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLTrackElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_tspan(name: tspan): Selection_[SVGTSpanElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGTSpanElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_u(name: u): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_ul(name: ul): Selection_[HTMLUListElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLUListElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_use(name: use): Selection_[SVGUseElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGUseElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_var(name: `var`): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_video(name: video): Selection_[HTMLVideoElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLVideoElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_view(name: view): Selection_[SVGViewElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[SVGViewElement, Unit, Null, Unit]]
  
  @scala.inline
  def create_wbr(name: wbr): Selection_[HTMLElement, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("create")(name.asInstanceOf[js.Any]).asInstanceOf[Selection_[HTMLElement, Unit, Null, Unit]]
  
  @scala.inline
  def creator[NewGElement /* <: Element */](name: String): js.ThisFunction0[/* this */ BaseType, NewGElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, NewGElement]]
  
  @scala.inline
  def creator_a(name: a): js.ThisFunction0[/* this */ BaseType, HTMLAnchorElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLAnchorElement]]
  
  @scala.inline
  def creator_abbr(name: abbr): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_address(name: address): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_applet(name: applet): js.ThisFunction0[/* this */ BaseType, HTMLAppletElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLAppletElement]]
  
  @scala.inline
  def creator_area(name: area): js.ThisFunction0[/* this */ BaseType, HTMLAreaElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLAreaElement]]
  
  @scala.inline
  def creator_article(name: article): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_aside(name: aside): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_audio(name: audio): js.ThisFunction0[/* this */ BaseType, HTMLAudioElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLAudioElement]]
  
  @scala.inline
  def creator_b(name: b): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_base(name: base): js.ThisFunction0[/* this */ BaseType, HTMLBaseElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLBaseElement]]
  
  @scala.inline
  def creator_bdi(name: bdi): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_bdo(name: bdo): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_blockquote(name: blockquote): js.ThisFunction0[/* this */ BaseType, HTMLQuoteElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLQuoteElement]]
  
  @scala.inline
  def creator_body(name: body): js.ThisFunction0[/* this */ BaseType, HTMLBodyElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLBodyElement]]
  
  @scala.inline
  def creator_br(name: br): js.ThisFunction0[/* this */ BaseType, HTMLBRElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLBRElement]]
  
  @scala.inline
  def creator_button(name: button): js.ThisFunction0[/* this */ BaseType, HTMLButtonElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLButtonElement]]
  
  @scala.inline
  def creator_canvas(name: canvas): js.ThisFunction0[/* this */ BaseType, HTMLCanvasElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLCanvasElement]]
  
  @scala.inline
  def creator_caption(name: caption): js.ThisFunction0[/* this */ BaseType, HTMLTableCaptionElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLTableCaptionElement]]
  
  @scala.inline
  def creator_circle(name: circle): js.ThisFunction0[/* this */ BaseType, SVGCircleElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGCircleElement]]
  
  @scala.inline
  def creator_cite(name: cite): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_clipPath(name: clipPath): js.ThisFunction0[/* this */ BaseType, SVGClipPathElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGClipPathElement]]
  
  @scala.inline
  def creator_code(name: code): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_col(name: col): js.ThisFunction0[/* this */ BaseType, HTMLTableColElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLTableColElement]]
  
  @scala.inline
  def creator_colgroup(name: colgroup): js.ThisFunction0[/* this */ BaseType, HTMLTableColElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLTableColElement]]
  
  @scala.inline
  def creator_data(name: data): js.ThisFunction0[/* this */ BaseType, HTMLDataElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLDataElement]]
  
  @scala.inline
  def creator_datalist(name: datalist): js.ThisFunction0[/* this */ BaseType, HTMLDataListElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLDataListElement]]
  
  @scala.inline
  def creator_dd(name: dd): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_defs(name: defs): js.ThisFunction0[/* this */ BaseType, SVGDefsElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGDefsElement]]
  
  @scala.inline
  def creator_del(name: del): js.ThisFunction0[/* this */ BaseType, HTMLModElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLModElement]]
  
  @scala.inline
  def creator_desc(name: desc): js.ThisFunction0[/* this */ BaseType, SVGDescElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGDescElement]]
  
  @scala.inline
  def creator_details(name: details): js.ThisFunction0[/* this */ BaseType, HTMLDetailsElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLDetailsElement]]
  
  @scala.inline
  def creator_dfn(name: dfn): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_dialog(name: dialog): js.ThisFunction0[/* this */ BaseType, HTMLDialogElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLDialogElement]]
  
  @scala.inline
  def creator_dir(name: dir): js.ThisFunction0[/* this */ BaseType, HTMLDirectoryElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLDirectoryElement]]
  
  @scala.inline
  def creator_div(name: div): js.ThisFunction0[/* this */ BaseType, HTMLDivElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLDivElement]]
  
  @scala.inline
  def creator_dl(name: dl): js.ThisFunction0[/* this */ BaseType, HTMLDListElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLDListElement]]
  
  @scala.inline
  def creator_dt(name: dt): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_ellipse(name: ellipse): js.ThisFunction0[/* this */ BaseType, SVGEllipseElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGEllipseElement]]
  
  @scala.inline
  def creator_em(name: em): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_embed(name: embed): js.ThisFunction0[/* this */ BaseType, HTMLEmbedElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLEmbedElement]]
  
  @scala.inline
  def creator_feBlend(name: feBlend): js.ThisFunction0[/* this */ BaseType, SVGFEBlendElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFEBlendElement]]
  
  @scala.inline
  def creator_feColorMatrix(name: feColorMatrix): js.ThisFunction0[/* this */ BaseType, SVGFEColorMatrixElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFEColorMatrixElement]]
  
  @scala.inline
  def creator_feComponentTransfer(name: feComponentTransfer): js.ThisFunction0[/* this */ BaseType, SVGFEComponentTransferElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFEComponentTransferElement]]
  
  @scala.inline
  def creator_feComposite(name: feComposite): js.ThisFunction0[/* this */ BaseType, SVGFECompositeElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFECompositeElement]]
  
  @scala.inline
  def creator_feConvolveMatrix(name: feConvolveMatrix): js.ThisFunction0[/* this */ BaseType, SVGFEConvolveMatrixElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFEConvolveMatrixElement]]
  
  @scala.inline
  def creator_feDiffuseLighting(name: feDiffuseLighting): js.ThisFunction0[/* this */ BaseType, SVGFEDiffuseLightingElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFEDiffuseLightingElement]]
  
  @scala.inline
  def creator_feDisplacementMap(name: feDisplacementMap): js.ThisFunction0[/* this */ BaseType, SVGFEDisplacementMapElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFEDisplacementMapElement]]
  
  @scala.inline
  def creator_feDistantLight(name: feDistantLight): js.ThisFunction0[/* this */ BaseType, SVGFEDistantLightElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFEDistantLightElement]]
  
  @scala.inline
  def creator_feFlood(name: feFlood): js.ThisFunction0[/* this */ BaseType, SVGFEFloodElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFEFloodElement]]
  
  @scala.inline
  def creator_feFuncA(name: feFuncA): js.ThisFunction0[/* this */ BaseType, SVGFEFuncAElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFEFuncAElement]]
  
  @scala.inline
  def creator_feFuncB(name: feFuncB): js.ThisFunction0[/* this */ BaseType, SVGFEFuncBElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFEFuncBElement]]
  
  @scala.inline
  def creator_feFuncG(name: feFuncG): js.ThisFunction0[/* this */ BaseType, SVGFEFuncGElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFEFuncGElement]]
  
  @scala.inline
  def creator_feFuncR(name: feFuncR): js.ThisFunction0[/* this */ BaseType, SVGFEFuncRElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFEFuncRElement]]
  
  @scala.inline
  def creator_feGaussianBlur(name: feGaussianBlur): js.ThisFunction0[/* this */ BaseType, SVGFEGaussianBlurElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFEGaussianBlurElement]]
  
  @scala.inline
  def creator_feImage(name: feImage): js.ThisFunction0[/* this */ BaseType, SVGFEImageElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFEImageElement]]
  
  @scala.inline
  def creator_feMerge(name: feMerge): js.ThisFunction0[/* this */ BaseType, SVGFEMergeElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFEMergeElement]]
  
  @scala.inline
  def creator_feMergeNode(name: feMergeNode): js.ThisFunction0[/* this */ BaseType, SVGFEMergeNodeElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFEMergeNodeElement]]
  
  @scala.inline
  def creator_feMorphology(name: feMorphology): js.ThisFunction0[/* this */ BaseType, SVGFEMorphologyElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFEMorphologyElement]]
  
  @scala.inline
  def creator_feOffset(name: feOffset): js.ThisFunction0[/* this */ BaseType, SVGFEOffsetElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFEOffsetElement]]
  
  @scala.inline
  def creator_fePointLight(name: fePointLight): js.ThisFunction0[/* this */ BaseType, SVGFEPointLightElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFEPointLightElement]]
  
  @scala.inline
  def creator_feSpecularLighting(name: feSpecularLighting): js.ThisFunction0[/* this */ BaseType, SVGFESpecularLightingElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFESpecularLightingElement]]
  
  @scala.inline
  def creator_feSpotLight(name: feSpotLight): js.ThisFunction0[/* this */ BaseType, SVGFESpotLightElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFESpotLightElement]]
  
  @scala.inline
  def creator_feTile(name: feTile): js.ThisFunction0[/* this */ BaseType, SVGFETileElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFETileElement]]
  
  @scala.inline
  def creator_feTurbulence(name: feTurbulence): js.ThisFunction0[/* this */ BaseType, SVGFETurbulenceElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFETurbulenceElement]]
  
  @scala.inline
  def creator_fieldset(name: fieldset): js.ThisFunction0[/* this */ BaseType, HTMLFieldSetElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLFieldSetElement]]
  
  @scala.inline
  def creator_figcaption(name: figcaption): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_figure(name: figure): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_filter(name: filter): js.ThisFunction0[/* this */ BaseType, SVGFilterElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGFilterElement]]
  
  @scala.inline
  def creator_font(name: font): js.ThisFunction0[/* this */ BaseType, HTMLFontElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLFontElement]]
  
  @scala.inline
  def creator_footer(name: footer): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_foreignObject(name: foreignObject): js.ThisFunction0[/* this */ BaseType, SVGForeignObjectElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGForeignObjectElement]]
  
  @scala.inline
  def creator_form(name: form): js.ThisFunction0[/* this */ BaseType, HTMLFormElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLFormElement]]
  
  @scala.inline
  def creator_frame(name: frame): js.ThisFunction0[/* this */ BaseType, HTMLFrameElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLFrameElement]]
  
  @scala.inline
  def creator_frameset(name: frameset): js.ThisFunction0[/* this */ BaseType, HTMLFrameSetElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLFrameSetElement]]
  
  @scala.inline
  def creator_g(name: g): js.ThisFunction0[/* this */ BaseType, SVGGElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGGElement]]
  
  @scala.inline
  def creator_h1(name: h1): js.ThisFunction0[/* this */ BaseType, HTMLHeadingElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLHeadingElement]]
  
  @scala.inline
  def creator_h2(name: h2): js.ThisFunction0[/* this */ BaseType, HTMLHeadingElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLHeadingElement]]
  
  @scala.inline
  def creator_h3(name: h3): js.ThisFunction0[/* this */ BaseType, HTMLHeadingElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLHeadingElement]]
  
  @scala.inline
  def creator_h4(name: h4): js.ThisFunction0[/* this */ BaseType, HTMLHeadingElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLHeadingElement]]
  
  @scala.inline
  def creator_h5(name: h5): js.ThisFunction0[/* this */ BaseType, HTMLHeadingElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLHeadingElement]]
  
  @scala.inline
  def creator_h6(name: h6): js.ThisFunction0[/* this */ BaseType, HTMLHeadingElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLHeadingElement]]
  
  @scala.inline
  def creator_head(name: head): js.ThisFunction0[/* this */ BaseType, HTMLHeadElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLHeadElement]]
  
  @scala.inline
  def creator_header(name: header): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_hgroup(name: hgroup): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_hr(name: hr): js.ThisFunction0[/* this */ BaseType, HTMLHRElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLHRElement]]
  
  @scala.inline
  def creator_html(name: html): js.ThisFunction0[/* this */ BaseType, HTMLHtmlElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLHtmlElement]]
  
  @scala.inline
  def creator_i(name: i): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_iframe(name: iframe): js.ThisFunction0[/* this */ BaseType, HTMLIFrameElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLIFrameElement]]
  
  @scala.inline
  def creator_image(name: image): js.ThisFunction0[/* this */ BaseType, SVGImageElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGImageElement]]
  
  @scala.inline
  def creator_img(name: img): js.ThisFunction0[/* this */ BaseType, HTMLImageElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLImageElement]]
  
  @scala.inline
  def creator_input(name: input): js.ThisFunction0[/* this */ BaseType, HTMLInputElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLInputElement]]
  
  @scala.inline
  def creator_ins(name: ins): js.ThisFunction0[/* this */ BaseType, HTMLModElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLModElement]]
  
  @scala.inline
  def creator_kbd(name: kbd): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_label(name: label): js.ThisFunction0[/* this */ BaseType, HTMLLabelElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLLabelElement]]
  
  @scala.inline
  def creator_legend(name: legend): js.ThisFunction0[/* this */ BaseType, HTMLLegendElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLLegendElement]]
  
  @scala.inline
  def creator_li(name: li): js.ThisFunction0[/* this */ BaseType, HTMLLIElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLLIElement]]
  
  @scala.inline
  def creator_line(name: line): js.ThisFunction0[/* this */ BaseType, SVGLineElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGLineElement]]
  
  @scala.inline
  def creator_linearGradient(name: linearGradient): js.ThisFunction0[/* this */ BaseType, SVGLinearGradientElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGLinearGradientElement]]
  
  @scala.inline
  def creator_link(name: link): js.ThisFunction0[/* this */ BaseType, HTMLLinkElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLLinkElement]]
  
  @scala.inline
  def creator_main(name: main): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_map(name: map): js.ThisFunction0[/* this */ BaseType, HTMLMapElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLMapElement]]
  
  @scala.inline
  def creator_mark(name: mark): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_marker(name: marker): js.ThisFunction0[/* this */ BaseType, SVGMarkerElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGMarkerElement]]
  
  @scala.inline
  def creator_marquee(name: marquee): js.ThisFunction0[/* this */ BaseType, HTMLMarqueeElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLMarqueeElement]]
  
  @scala.inline
  def creator_mask(name: mask): js.ThisFunction0[/* this */ BaseType, SVGMaskElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGMaskElement]]
  
  @scala.inline
  def creator_menu(name: menu): js.ThisFunction0[/* this */ BaseType, HTMLMenuElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLMenuElement]]
  
  @scala.inline
  def creator_meta(name: meta): js.ThisFunction0[/* this */ BaseType, HTMLMetaElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLMetaElement]]
  
  @scala.inline
  def creator_metadata(name: metadata): js.ThisFunction0[/* this */ BaseType, SVGMetadataElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGMetadataElement]]
  
  @scala.inline
  def creator_meter(name: meter): js.ThisFunction0[/* this */ BaseType, HTMLMeterElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLMeterElement]]
  
  @scala.inline
  def creator_nav(name: nav): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_noscript(name: noscript): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_object(name: `object`): js.ThisFunction0[/* this */ BaseType, HTMLObjectElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLObjectElement]]
  
  @scala.inline
  def creator_ol(name: ol): js.ThisFunction0[/* this */ BaseType, HTMLOListElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLOListElement]]
  
  @scala.inline
  def creator_optgroup(name: optgroup): js.ThisFunction0[/* this */ BaseType, HTMLOptGroupElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLOptGroupElement]]
  
  @scala.inline
  def creator_option(name: option): js.ThisFunction0[/* this */ BaseType, HTMLOptionElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLOptionElement]]
  
  @scala.inline
  def creator_output(name: output): js.ThisFunction0[/* this */ BaseType, HTMLOutputElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLOutputElement]]
  
  @scala.inline
  def creator_p(name: p): js.ThisFunction0[/* this */ BaseType, HTMLParagraphElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLParagraphElement]]
  
  @scala.inline
  def creator_param(name: param): js.ThisFunction0[/* this */ BaseType, HTMLParamElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLParamElement]]
  
  @scala.inline
  def creator_path(name: path): js.ThisFunction0[/* this */ BaseType, SVGPathElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGPathElement]]
  
  @scala.inline
  def creator_pattern(name: pattern): js.ThisFunction0[/* this */ BaseType, SVGPatternElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGPatternElement]]
  
  @scala.inline
  def creator_picture(name: picture): js.ThisFunction0[/* this */ BaseType, HTMLPictureElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLPictureElement]]
  
  @scala.inline
  def creator_polygon(name: polygon): js.ThisFunction0[/* this */ BaseType, SVGPolygonElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGPolygonElement]]
  
  @scala.inline
  def creator_polyline(name: polyline): js.ThisFunction0[/* this */ BaseType, SVGPolylineElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGPolylineElement]]
  
  @scala.inline
  def creator_pre(name: pre): js.ThisFunction0[/* this */ BaseType, HTMLPreElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLPreElement]]
  
  @scala.inline
  def creator_progress(name: progress): js.ThisFunction0[/* this */ BaseType, HTMLProgressElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLProgressElement]]
  
  @scala.inline
  def creator_q(name: q): js.ThisFunction0[/* this */ BaseType, HTMLQuoteElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLQuoteElement]]
  
  @scala.inline
  def creator_radialGradient(name: radialGradient): js.ThisFunction0[/* this */ BaseType, SVGRadialGradientElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGRadialGradientElement]]
  
  @scala.inline
  def creator_rect(name: rect): js.ThisFunction0[/* this */ BaseType, SVGRectElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGRectElement]]
  
  @scala.inline
  def creator_rp(name: rp): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_rt(name: rt): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_ruby(name: ruby): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_s(name: s): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_samp(name: samp): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_script(name: script): js.ThisFunction0[/* this */ BaseType, HTMLScriptElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLScriptElement]]
  
  @scala.inline
  def creator_section(name: section): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_select(name: select): js.ThisFunction0[/* this */ BaseType, HTMLSelectElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLSelectElement]]
  
  @scala.inline
  def creator_slot(name: slot): js.ThisFunction0[/* this */ BaseType, HTMLSlotElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLSlotElement]]
  
  @scala.inline
  def creator_small(name: small): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_source(name: source): js.ThisFunction0[/* this */ BaseType, HTMLSourceElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLSourceElement]]
  
  @scala.inline
  def creator_span(name: span): js.ThisFunction0[/* this */ BaseType, HTMLSpanElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLSpanElement]]
  
  @scala.inline
  def creator_stop(name: stop): js.ThisFunction0[/* this */ BaseType, SVGStopElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGStopElement]]
  
  @scala.inline
  def creator_strong(name: strong): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_style(name: style): js.ThisFunction0[/* this */ BaseType, HTMLStyleElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLStyleElement]]
  
  @scala.inline
  def creator_sub(name: sub): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_summary(name: summary): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_sup(name: sup): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_svg(name: svg): js.ThisFunction0[/* this */ BaseType, SVGSVGElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGSVGElement]]
  
  @scala.inline
  def creator_switch(name: switch): js.ThisFunction0[/* this */ BaseType, SVGSwitchElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGSwitchElement]]
  
  @scala.inline
  def creator_symbol(name: symbol): js.ThisFunction0[/* this */ BaseType, SVGSymbolElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGSymbolElement]]
  
  @scala.inline
  def creator_table(name: table): js.ThisFunction0[/* this */ BaseType, HTMLTableElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLTableElement]]
  
  @scala.inline
  def creator_tbody(name: tbody): js.ThisFunction0[/* this */ BaseType, HTMLTableSectionElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLTableSectionElement]]
  
  @scala.inline
  def creator_td(name: td): js.ThisFunction0[/* this */ BaseType, HTMLTableDataCellElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLTableDataCellElement]]
  
  @scala.inline
  def creator_template(name: template): js.ThisFunction0[/* this */ BaseType, HTMLTemplateElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLTemplateElement]]
  
  @scala.inline
  def creator_text(name: text): js.ThisFunction0[/* this */ BaseType, SVGTextElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGTextElement]]
  
  @scala.inline
  def creator_textPath(name: textPath): js.ThisFunction0[/* this */ BaseType, SVGTextPathElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGTextPathElement]]
  
  @scala.inline
  def creator_textarea(name: textarea): js.ThisFunction0[/* this */ BaseType, HTMLTextAreaElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLTextAreaElement]]
  
  @scala.inline
  def creator_tfoot(name: tfoot): js.ThisFunction0[/* this */ BaseType, HTMLTableSectionElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLTableSectionElement]]
  
  @scala.inline
  def creator_th(name: th): js.ThisFunction0[/* this */ BaseType, HTMLTableHeaderCellElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLTableHeaderCellElement]]
  
  @scala.inline
  def creator_thead(name: thead): js.ThisFunction0[/* this */ BaseType, HTMLTableSectionElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLTableSectionElement]]
  
  @scala.inline
  def creator_time(name: time): js.ThisFunction0[/* this */ BaseType, HTMLTimeElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLTimeElement]]
  
  @scala.inline
  def creator_title(name: title): js.ThisFunction0[/* this */ BaseType, HTMLTitleElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLTitleElement]]
  
  @scala.inline
  def creator_tr(name: tr): js.ThisFunction0[/* this */ BaseType, HTMLTableRowElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLTableRowElement]]
  
  @scala.inline
  def creator_track(name: track): js.ThisFunction0[/* this */ BaseType, HTMLTrackElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLTrackElement]]
  
  @scala.inline
  def creator_tspan(name: tspan): js.ThisFunction0[/* this */ BaseType, SVGTSpanElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGTSpanElement]]
  
  @scala.inline
  def creator_u(name: u): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_ul(name: ul): js.ThisFunction0[/* this */ BaseType, HTMLUListElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLUListElement]]
  
  @scala.inline
  def creator_use(name: use): js.ThisFunction0[/* this */ BaseType, SVGUseElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGUseElement]]
  
  @scala.inline
  def creator_var(name: `var`): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def creator_video(name: video): js.ThisFunction0[/* this */ BaseType, HTMLVideoElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLVideoElement]]
  
  @scala.inline
  def creator_view(name: view): js.ThisFunction0[/* this */ BaseType, SVGViewElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, SVGViewElement]]
  
  @scala.inline
  def creator_wbr(name: wbr): js.ThisFunction0[/* this */ BaseType, HTMLElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("creator")(name.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, HTMLElement]]
  
  @scala.inline
  def cross[S, T](a: js.Iterable[S], b: js.Iterable[T]): js.Array[js.Tuple2[S, T]] = (^.asInstanceOf[js.Dynamic].applyDynamic("cross")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Tuple2[S, T]]]
  @scala.inline
  def cross[S, T, U](a: js.Iterable[S], b: js.Iterable[T], reducer: js.Function2[/* a */ S, /* b */ T, U]): js.Array[U] = (^.asInstanceOf[js.Dynamic].applyDynamic("cross")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any], reducer.asInstanceOf[js.Any])).asInstanceOf[js.Array[U]]
  
  @scala.inline
  def csv[Columns /* <: String */](url: String): js.Promise[DSVRowArray[Columns]] = ^.asInstanceOf[js.Dynamic].applyDynamic("csv")(url.asInstanceOf[js.Any]).asInstanceOf[js.Promise[DSVRowArray[Columns]]]
  @scala.inline
  def csv[Columns /* <: String */](url: String, init: RequestInit): js.Promise[DSVRowArray[Columns]] = (^.asInstanceOf[js.Dynamic].applyDynamic("csv")(url.asInstanceOf[js.Any], init.asInstanceOf[js.Any])).asInstanceOf[js.Promise[DSVRowArray[Columns]]]
  @scala.inline
  def csv[ParsedRow /* <: js.Object */, Columns /* <: String */](
    url: String,
    init: RequestInit,
    row: js.Function3[
      /* rawRow */ DSVRowString[Columns], 
      /* index */ Double, 
      /* columns */ js.Array[Columns], 
      js.UndefOr[ParsedRow | Null]
    ]
  ): js.Promise[DSVParsedArray[ParsedRow]] = (^.asInstanceOf[js.Dynamic].applyDynamic("csv")(url.asInstanceOf[js.Any], init.asInstanceOf[js.Any], row.asInstanceOf[js.Any])).asInstanceOf[js.Promise[DSVParsedArray[ParsedRow]]]
  @scala.inline
  def csv[ParsedRow /* <: js.Object */, Columns /* <: String */](
    url: String,
    row: js.Function3[
      /* rawRow */ DSVRowString[Columns], 
      /* index */ Double, 
      /* columns */ js.Array[Columns], 
      js.UndefOr[ParsedRow | Null]
    ]
  ): js.Promise[DSVParsedArray[ParsedRow]] = (^.asInstanceOf[js.Dynamic].applyDynamic("csv")(url.asInstanceOf[js.Any], row.asInstanceOf[js.Any])).asInstanceOf[js.Promise[DSVParsedArray[ParsedRow]]]
  
  @scala.inline
  def csvFormat[T /* <: js.Object */](rows: js.Array[T]): String = ^.asInstanceOf[js.Dynamic].applyDynamic("csvFormat")(rows.asInstanceOf[js.Any]).asInstanceOf[String]
  @scala.inline
  def csvFormat[T /* <: js.Object */](rows: js.Array[T], columns: js.Array[/* keyof T */ String]): String = (^.asInstanceOf[js.Dynamic].applyDynamic("csvFormat")(rows.asInstanceOf[js.Any], columns.asInstanceOf[js.Any])).asInstanceOf[String]
  
  @scala.inline
  def csvFormatBody[T /* <: js.Object */](rows: js.Array[T]): String = ^.asInstanceOf[js.Dynamic].applyDynamic("csvFormatBody")(rows.asInstanceOf[js.Any]).asInstanceOf[String]
  @scala.inline
  def csvFormatBody[T /* <: js.Object */](rows: js.Array[T], columns: js.Array[/* keyof T */ String]): String = (^.asInstanceOf[js.Dynamic].applyDynamic("csvFormatBody")(rows.asInstanceOf[js.Any], columns.asInstanceOf[js.Any])).asInstanceOf[String]
  
  @scala.inline
  def csvFormatRow(row: js.Array[String]): String = ^.asInstanceOf[js.Dynamic].applyDynamic("csvFormatRow")(row.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def csvFormatRows(rows: js.Array[js.Array[String]]): String = ^.asInstanceOf[js.Dynamic].applyDynamic("csvFormatRows")(rows.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def csvFormatValue(value: String): String = ^.asInstanceOf[js.Dynamic].applyDynamic("csvFormatValue")(value.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def csvParse[Columns /* <: String */](csvString: String): DSVRowArray[Columns] = ^.asInstanceOf[js.Dynamic].applyDynamic("csvParse")(csvString.asInstanceOf[js.Any]).asInstanceOf[DSVRowArray[Columns]]
  @scala.inline
  def csvParse[ParsedRow /* <: js.Object */, Columns /* <: String */](
    csvString: String,
    row: js.Function3[
      /* rawRow */ DSVRowString[Columns], 
      /* index */ Double, 
      /* columns */ js.Array[Columns], 
      js.UndefOr[ParsedRow | Null]
    ]
  ): DSVParsedArray[ParsedRow] = (^.asInstanceOf[js.Dynamic].applyDynamic("csvParse")(csvString.asInstanceOf[js.Any], row.asInstanceOf[js.Any])).asInstanceOf[DSVParsedArray[ParsedRow]]
  
  @scala.inline
  def csvParseRows(csvString: String): js.Array[js.Array[String]] = ^.asInstanceOf[js.Dynamic].applyDynamic("csvParseRows")(csvString.asInstanceOf[js.Any]).asInstanceOf[js.Array[js.Array[String]]]
  @scala.inline
  def csvParseRows[ParsedRow /* <: js.Object */](
    csvString: String,
    row: js.Function2[/* rawRow */ js.Array[String], /* index */ Double, js.UndefOr[ParsedRow | Null]]
  ): js.Array[ParsedRow] = (^.asInstanceOf[js.Dynamic].applyDynamic("csvParseRows")(csvString.asInstanceOf[js.Any], row.asInstanceOf[js.Any])).asInstanceOf[js.Array[ParsedRow]]
  
  @JSImport("d3", "cubehelix")
  @js.native
  val cubehelix: CubehelixColorFactory = js.native
  
  @scala.inline
  def cumsum(iterable: js.Iterable[js.UndefOr[Numeric | Null]]): js.typedarray.Float64Array = ^.asInstanceOf[js.Dynamic].applyDynamic("cumsum")(iterable.asInstanceOf[js.Any]).asInstanceOf[js.typedarray.Float64Array]
  @scala.inline
  def cumsum[T](
    iterable: js.Iterable[T],
    accessor: js.Function3[/* element */ T, /* i */ Double, /* array */ js.Iterable[T], js.UndefOr[Double | Null]]
  ): js.typedarray.Float64Array = (^.asInstanceOf[js.Dynamic].applyDynamic("cumsum")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Float64Array]
  
  @JSImport("d3", "curveBasis")
  @js.native
  val curveBasis: CurveFactory = js.native
  
  @JSImport("d3", "curveBasisClosed")
  @js.native
  val curveBasisClosed: CurveFactory = js.native
  
  @JSImport("d3", "curveBasisOpen")
  @js.native
  val curveBasisOpen: CurveFactory = js.native
  
  @JSImport("d3", "curveBumpX")
  @js.native
  val curveBumpX: CurveFactory = js.native
  
  @JSImport("d3", "curveBumpY")
  @js.native
  val curveBumpY: CurveFactory = js.native
  
  @JSImport("d3", "curveBundle")
  @js.native
  val curveBundle: CurveBundleFactory = js.native
  
  @JSImport("d3", "curveCardinal")
  @js.native
  val curveCardinal: CurveCardinalFactory = js.native
  
  @JSImport("d3", "curveCardinalClosed")
  @js.native
  val curveCardinalClosed: CurveCardinalFactory = js.native
  
  @JSImport("d3", "curveCardinalOpen")
  @js.native
  val curveCardinalOpen: CurveCardinalFactory = js.native
  
  @JSImport("d3", "curveCatmullRom")
  @js.native
  val curveCatmullRom: CurveCatmullRomFactory = js.native
  
  @JSImport("d3", "curveCatmullRomClosed")
  @js.native
  val curveCatmullRomClosed: CurveCatmullRomFactory = js.native
  
  @JSImport("d3", "curveCatmullRomOpen")
  @js.native
  val curveCatmullRomOpen: CurveCatmullRomFactory = js.native
  
  @JSImport("d3", "curveLinear")
  @js.native
  val curveLinear: CurveFactory = js.native
  
  @JSImport("d3", "curveLinearClosed")
  @js.native
  val curveLinearClosed: CurveFactory = js.native
  
  @JSImport("d3", "curveMonotoneX")
  @js.native
  val curveMonotoneX: CurveFactory = js.native
  
  @JSImport("d3", "curveMonotoneY")
  @js.native
  val curveMonotoneY: CurveFactory = js.native
  
  @JSImport("d3", "curveNatural")
  @js.native
  val curveNatural: CurveFactory = js.native
  
  @JSImport("d3", "curveStep")
  @js.native
  val curveStep: CurveFactory = js.native
  
  @JSImport("d3", "curveStepAfter")
  @js.native
  val curveStepAfter: CurveFactory = js.native
  
  @JSImport("d3", "curveStepBefore")
  @js.native
  val curveStepBefore: CurveFactory = js.native
  
  @scala.inline
  def descending(): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("descending")().asInstanceOf[Double]
  @scala.inline
  def descending(a: Unit, b: Primitive): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("descending")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[Double]
  @scala.inline
  def descending(a: Primitive): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("descending")(a.asInstanceOf[js.Any]).asInstanceOf[Double]
  @scala.inline
  def descending(a: Primitive, b: Primitive): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("descending")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[Double]
  
  @scala.inline
  def deviation(iterable: js.Iterable[js.UndefOr[Numeric | Null]]): js.UndefOr[Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("deviation")(iterable.asInstanceOf[js.Any]).asInstanceOf[js.UndefOr[Double]]
  @scala.inline
  def deviation[T](
    iterable: js.Iterable[T],
    accessor: js.Function3[
      /* datum */ T, 
      /* index */ Double, 
      /* array */ js.Iterable[T], 
      js.UndefOr[Double | Null]
    ]
  ): js.UndefOr[Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("deviation")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[js.UndefOr[Double]]
  
  @scala.inline
  def difference[T](iterable: js.Iterable[T], others: js.Iterable[T]*): typings.d3Array.mod.InternSet[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("difference")(List(iterable.asInstanceOf[js.Any]).`++`(others.asInstanceOf[Seq[js.Any]]) :_*)).asInstanceOf[typings.d3Array.mod.InternSet[T]]
  
  @scala.inline
  def disjoint[T](a: js.Iterable[T], b: js.Iterable[T]): Boolean = (^.asInstanceOf[js.Dynamic].applyDynamic("disjoint")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[Boolean]
  
  @scala.inline
  def dispatch[T /* <: js.Object */](types: String*): Dispatch_[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("dispatch")(types.asInstanceOf[Seq[js.Any]] :_*)).asInstanceOf[Dispatch_[T]]
  
  @scala.inline
  def drag[GElement /* <: DraggedElementBaseType */, Datum](): DragBehavior[GElement, Datum, Datum | SubjectPosition] = ^.asInstanceOf[js.Dynamic].applyDynamic("drag")().asInstanceOf[DragBehavior[GElement, Datum, Datum | SubjectPosition]]
  
  @scala.inline
  def dragDisable(window: Window): Unit = ^.asInstanceOf[js.Dynamic].applyDynamic("dragDisable")(window.asInstanceOf[js.Any]).asInstanceOf[Unit]
  
  @scala.inline
  def dragEnable(window: Window): Unit = ^.asInstanceOf[js.Dynamic].applyDynamic("dragEnable")(window.asInstanceOf[js.Any]).asInstanceOf[Unit]
  @scala.inline
  def dragEnable(window: Window, noClick: Boolean): Unit = (^.asInstanceOf[js.Dynamic].applyDynamic("dragEnable")(window.asInstanceOf[js.Any], noClick.asInstanceOf[js.Any])).asInstanceOf[Unit]
  
  @scala.inline
  def drag_GElement_DraggedElementBaseTypeDatumSubject[GElement /* <: DraggedElementBaseType */, Datum, Subject](): DragBehavior[GElement, Datum, Subject] = ^.asInstanceOf[js.Dynamic].applyDynamic("drag")().asInstanceOf[DragBehavior[GElement, Datum, Subject]]
  
  @scala.inline
  def dsv[Columns /* <: String */](delimiter: String, url: String): js.Promise[DSVRowArray[Columns]] = (^.asInstanceOf[js.Dynamic].applyDynamic("dsv")(delimiter.asInstanceOf[js.Any], url.asInstanceOf[js.Any])).asInstanceOf[js.Promise[DSVRowArray[Columns]]]
  @scala.inline
  def dsv[Columns /* <: String */](delimiter: String, url: String, init: RequestInit): js.Promise[DSVRowArray[Columns]] = (^.asInstanceOf[js.Dynamic].applyDynamic("dsv")(delimiter.asInstanceOf[js.Any], url.asInstanceOf[js.Any], init.asInstanceOf[js.Any])).asInstanceOf[js.Promise[DSVRowArray[Columns]]]
  @scala.inline
  def dsv[ParsedRow /* <: js.Object */, Columns /* <: String */](
    delimiter: String,
    url: String,
    init: RequestInit,
    row: js.Function3[
      /* rawRow */ DSVRowString[Columns], 
      /* index */ Double, 
      /* columns */ js.Array[Columns], 
      js.UndefOr[ParsedRow | Null]
    ]
  ): js.Promise[DSVParsedArray[ParsedRow]] = (^.asInstanceOf[js.Dynamic].applyDynamic("dsv")(delimiter.asInstanceOf[js.Any], url.asInstanceOf[js.Any], init.asInstanceOf[js.Any], row.asInstanceOf[js.Any])).asInstanceOf[js.Promise[DSVParsedArray[ParsedRow]]]
  @scala.inline
  def dsv[ParsedRow /* <: js.Object */, Columns /* <: String */](
    delimiter: String,
    url: String,
    row: js.Function3[
      /* rawRow */ DSVRowString[Columns], 
      /* index */ Double, 
      /* columns */ js.Array[Columns], 
      js.UndefOr[ParsedRow | Null]
    ]
  ): js.Promise[DSVParsedArray[ParsedRow]] = (^.asInstanceOf[js.Dynamic].applyDynamic("dsv")(delimiter.asInstanceOf[js.Any], url.asInstanceOf[js.Any], row.asInstanceOf[js.Any])).asInstanceOf[js.Promise[DSVParsedArray[ParsedRow]]]
  
  @scala.inline
  def dsvFormat(delimiter: String): DSV = ^.asInstanceOf[js.Dynamic].applyDynamic("dsvFormat")(delimiter.asInstanceOf[js.Any]).asInstanceOf[DSV]
  
  @JSImport("d3", "easeBack")
  @js.native
  val easeBack: BackEasingFactory = js.native
  
  @JSImport("d3", "easeBackIn")
  @js.native
  val easeBackIn: BackEasingFactory = js.native
  
  @JSImport("d3", "easeBackInOut")
  @js.native
  val easeBackInOut: BackEasingFactory = js.native
  
  @JSImport("d3", "easeBackOut")
  @js.native
  val easeBackOut: BackEasingFactory = js.native
  
  @scala.inline
  def easeBounce(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeBounce")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeBounceIn(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeBounceIn")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeBounceInOut(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeBounceInOut")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeBounceOut(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeBounceOut")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeCircle(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeCircle")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeCircleIn(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeCircleIn")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeCircleInOut(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeCircleInOut")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeCircleOut(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeCircleOut")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeCubic(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeCubic")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeCubicIn(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeCubicIn")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeCubicInOut(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeCubicInOut")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeCubicOut(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeCubicOut")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @JSImport("d3", "easeElastic")
  @js.native
  val easeElastic: ElasticEasingFactory = js.native
  
  @JSImport("d3", "easeElasticIn")
  @js.native
  val easeElasticIn: ElasticEasingFactory = js.native
  
  @JSImport("d3", "easeElasticInOut")
  @js.native
  val easeElasticInOut: ElasticEasingFactory = js.native
  
  @JSImport("d3", "easeElasticOut")
  @js.native
  val easeElasticOut: ElasticEasingFactory = js.native
  
  @scala.inline
  def easeExp(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeExp")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeExpIn(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeExpIn")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeExpInOut(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeExpInOut")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeExpOut(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeExpOut")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeLinear(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeLinear")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @JSImport("d3", "easePoly")
  @js.native
  val easePoly: PolynomialEasingFactory = js.native
  
  @JSImport("d3", "easePolyIn")
  @js.native
  val easePolyIn: PolynomialEasingFactory = js.native
  
  @JSImport("d3", "easePolyInOut")
  @js.native
  val easePolyInOut: PolynomialEasingFactory = js.native
  
  @JSImport("d3", "easePolyOut")
  @js.native
  val easePolyOut: PolynomialEasingFactory = js.native
  
  @scala.inline
  def easeQuad(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeQuad")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeQuadIn(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeQuadIn")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeQuadInOut(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeQuadInOut")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeQuadOut(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeQuadOut")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeSin(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeSin")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeSinIn(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeSinIn")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeSinInOut(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeSinInOut")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def easeSinOut(normalizedTime: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("easeSinOut")(normalizedTime.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def every[T](
    iterable: js.Iterable[T],
    test: js.Function3[/* value */ T, /* index */ Double, /* iterable */ js.Iterable[T], Any]
  ): Boolean = (^.asInstanceOf[js.Dynamic].applyDynamic("every")(iterable.asInstanceOf[js.Any], test.asInstanceOf[js.Any])).asInstanceOf[Boolean]
  
  @scala.inline
  def extent(iterable: js.Iterable[String]): js.Tuple2[String | Unit, String | Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("extent")(iterable.asInstanceOf[js.Any]).asInstanceOf[js.Tuple2[String | Unit, String | Unit]]
  @scala.inline
  def extent[T](
    iterable: js.Iterable[T],
    accessor: js.Function3[
      /* datum */ T, 
      /* index */ Double, 
      /* array */ js.Iterable[T], 
      js.UndefOr[String | Null]
    ]
  ): js.Tuple2[String | Unit, String | Unit] = (^.asInstanceOf[js.Dynamic].applyDynamic("extent")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[js.Tuple2[String | Unit, String | Unit]]
  
  @scala.inline
  def extent_TU_Numeric[T, U /* <: Numeric */](
    iterable: js.Iterable[T],
    accessor: js.Function3[/* datum */ T, /* index */ Double, /* array */ js.Iterable[T], js.UndefOr[U | Null]]
  ): js.Tuple2[U | Unit, U | Unit] = (^.asInstanceOf[js.Dynamic].applyDynamic("extent")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[js.Tuple2[U | Unit, U | Unit]]
  
  @scala.inline
  def extent_T_Numeric[T /* <: Numeric */](iterable: js.Iterable[T]): js.Tuple2[T | Unit, T | Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("extent")(iterable.asInstanceOf[js.Any]).asInstanceOf[js.Tuple2[T | Unit, T | Unit]]
  
  @scala.inline
  def fcumsum(values: js.Iterable[js.UndefOr[Numeric | Null]]): js.typedarray.Float64Array = ^.asInstanceOf[js.Dynamic].applyDynamic("fcumsum")(values.asInstanceOf[js.Any]).asInstanceOf[js.typedarray.Float64Array]
  @scala.inline
  def fcumsum[T](
    values: js.Iterable[T],
    accessor: js.Function3[
      /* datum */ T, 
      /* index */ Double, 
      /* array */ js.Iterable[T], 
      js.UndefOr[Double | Null]
    ]
  ): js.typedarray.Float64Array = (^.asInstanceOf[js.Dynamic].applyDynamic("fcumsum")(values.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Float64Array]
  
  @scala.inline
  def filter[T](
    iterable: js.Iterable[T],
    test: js.Function3[/* value */ T, /* index */ Double, /* iterable */ js.Iterable[T], Any]
  ): js.Array[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("filter")(iterable.asInstanceOf[js.Any], test.asInstanceOf[js.Any])).asInstanceOf[js.Array[T]]
  
  @scala.inline
  def flatGroup[TObject, TKey](iterable: js.Iterable[TObject], key: js.Function1[/* value */ TObject, TKey]): js.Array[js.Tuple2[TKey, js.Array[TObject]]] = (^.asInstanceOf[js.Dynamic].applyDynamic("flatGroup")(iterable.asInstanceOf[js.Any], key.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Tuple2[TKey, js.Array[TObject]]]]
  @scala.inline
  def flatGroup[TObject, TKey1, TKey2](
    iterable: js.Iterable[TObject],
    key1: js.Function1[/* value */ TObject, TKey1],
    key2: js.Function1[/* value */ TObject, TKey2]
  ): js.Array[js.Tuple3[TKey1, TKey2, js.Array[TObject]]] = (^.asInstanceOf[js.Dynamic].applyDynamic("flatGroup")(iterable.asInstanceOf[js.Any], key1.asInstanceOf[js.Any], key2.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Tuple3[TKey1, TKey2, js.Array[TObject]]]]
  @scala.inline
  def flatGroup[TObject, TKey1, TKey2, TKey3](
    iterable: js.Iterable[TObject],
    key1: js.Function1[/* value */ TObject, TKey1],
    key2: js.Function1[/* value */ TObject, TKey2],
    key3: js.Function1[/* value */ TObject, TKey3]
  ): js.Array[js.Tuple4[TKey1, TKey2, TKey3, js.Array[TObject]]] = (^.asInstanceOf[js.Dynamic].applyDynamic("flatGroup")(iterable.asInstanceOf[js.Any], key1.asInstanceOf[js.Any], key2.asInstanceOf[js.Any], key3.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Tuple4[TKey1, TKey2, TKey3, js.Array[TObject]]]]
  
  @scala.inline
  def flatRollup[TObject, TReduce, TKey](
    iterable: js.Iterable[TObject],
    reduce: js.Function1[/* value */ js.Array[TObject], TReduce],
    key: js.Function1[/* value */ TObject, TKey]
  ): js.Array[js.Tuple2[TKey, TReduce]] = (^.asInstanceOf[js.Dynamic].applyDynamic("flatRollup")(iterable.asInstanceOf[js.Any], reduce.asInstanceOf[js.Any], key.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Tuple2[TKey, TReduce]]]
  @scala.inline
  def flatRollup[TObject, TReduce, TKey1, TKey2](
    iterable: js.Iterable[TObject],
    reduce: js.Function1[/* value */ js.Array[TObject], TReduce],
    key1: js.Function1[/* value */ TObject, TKey1],
    key2: js.Function1[/* value */ TObject, TKey2]
  ): js.Array[js.Tuple3[TKey1, TKey2, TReduce]] = (^.asInstanceOf[js.Dynamic].applyDynamic("flatRollup")(iterable.asInstanceOf[js.Any], reduce.asInstanceOf[js.Any], key1.asInstanceOf[js.Any], key2.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Tuple3[TKey1, TKey2, TReduce]]]
  @scala.inline
  def flatRollup[TObject, TReduce, TKey1, TKey2, TKey3](
    iterable: js.Iterable[TObject],
    reduce: js.Function1[/* value */ js.Array[TObject], TReduce],
    key1: js.Function1[/* value */ TObject, TKey1],
    key2: js.Function1[/* value */ TObject, TKey2],
    key3: js.Function1[/* value */ TObject, TKey3]
  ): js.Array[js.Tuple4[TKey1, TKey2, TKey3, TReduce]] = (^.asInstanceOf[js.Dynamic].applyDynamic("flatRollup")(iterable.asInstanceOf[js.Any], reduce.asInstanceOf[js.Any], key1.asInstanceOf[js.Any], key2.asInstanceOf[js.Any], key3.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Tuple4[TKey1, TKey2, TKey3, TReduce]]]
  
  @scala.inline
  def forceCenter[NodeDatum /* <: SimulationNodeDatum */](): ForceCenter_[NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("forceCenter")().asInstanceOf[ForceCenter_[NodeDatum]]
  @scala.inline
  def forceCenter[NodeDatum /* <: SimulationNodeDatum */](x: Double): ForceCenter_[NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("forceCenter")(x.asInstanceOf[js.Any]).asInstanceOf[ForceCenter_[NodeDatum]]
  @scala.inline
  def forceCenter[NodeDatum /* <: SimulationNodeDatum */](x: Double, y: Double): ForceCenter_[NodeDatum] = (^.asInstanceOf[js.Dynamic].applyDynamic("forceCenter")(x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[ForceCenter_[NodeDatum]]
  @scala.inline
  def forceCenter[NodeDatum /* <: SimulationNodeDatum */](x: Unit, y: Double): ForceCenter_[NodeDatum] = (^.asInstanceOf[js.Dynamic].applyDynamic("forceCenter")(x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[ForceCenter_[NodeDatum]]
  
  @scala.inline
  def forceCollide[NodeDatum /* <: SimulationNodeDatum */](): ForceCollide_[NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("forceCollide")().asInstanceOf[ForceCollide_[NodeDatum]]
  @scala.inline
  def forceCollide[NodeDatum /* <: SimulationNodeDatum */](
    radius: js.Function3[/* node */ NodeDatum, /* i */ Double, /* nodes */ js.Array[NodeDatum], Double]
  ): ForceCollide_[NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("forceCollide")(radius.asInstanceOf[js.Any]).asInstanceOf[ForceCollide_[NodeDatum]]
  @scala.inline
  def forceCollide[NodeDatum /* <: SimulationNodeDatum */](radius: Double): ForceCollide_[NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("forceCollide")(radius.asInstanceOf[js.Any]).asInstanceOf[ForceCollide_[NodeDatum]]
  
  @scala.inline
  def forceLink[NodeDatum /* <: SimulationNodeDatum */, LinksDatum /* <: SimulationLinkDatum[NodeDatum] */](): ForceLink_[NodeDatum, LinksDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("forceLink")().asInstanceOf[ForceLink_[NodeDatum, LinksDatum]]
  @scala.inline
  def forceLink[NodeDatum /* <: SimulationNodeDatum */, LinksDatum /* <: SimulationLinkDatum[NodeDatum] */](links: js.Array[LinksDatum]): ForceLink_[NodeDatum, LinksDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("forceLink")(links.asInstanceOf[js.Any]).asInstanceOf[ForceLink_[NodeDatum, LinksDatum]]
  
  @scala.inline
  def forceManyBody[NodeDatum /* <: SimulationNodeDatum */](): ForceManyBody_[NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("forceManyBody")().asInstanceOf[ForceManyBody_[NodeDatum]]
  
  @scala.inline
  def forceRadial[NodeDatum /* <: SimulationNodeDatum */](radius: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double]): ForceRadial_[NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("forceRadial")(radius.asInstanceOf[js.Any]).asInstanceOf[ForceRadial_[NodeDatum]]
  @scala.inline
  def forceRadial[NodeDatum /* <: SimulationNodeDatum */](
    radius: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double],
    x: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double]
  ): ForceRadial_[NodeDatum] = (^.asInstanceOf[js.Dynamic].applyDynamic("forceRadial")(radius.asInstanceOf[js.Any], x.asInstanceOf[js.Any])).asInstanceOf[ForceRadial_[NodeDatum]]
  @scala.inline
  def forceRadial[NodeDatum /* <: SimulationNodeDatum */](
    radius: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double],
    x: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double],
    y: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double]
  ): ForceRadial_[NodeDatum] = (^.asInstanceOf[js.Dynamic].applyDynamic("forceRadial")(radius.asInstanceOf[js.Any], x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[ForceRadial_[NodeDatum]]
  @scala.inline
  def forceRadial[NodeDatum /* <: SimulationNodeDatum */](
    radius: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double],
    x: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double],
    y: Double
  ): ForceRadial_[NodeDatum] = (^.asInstanceOf[js.Dynamic].applyDynamic("forceRadial")(radius.asInstanceOf[js.Any], x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[ForceRadial_[NodeDatum]]
  @scala.inline
  def forceRadial[NodeDatum /* <: SimulationNodeDatum */](
    radius: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double],
    x: Double
  ): ForceRadial_[NodeDatum] = (^.asInstanceOf[js.Dynamic].applyDynamic("forceRadial")(radius.asInstanceOf[js.Any], x.asInstanceOf[js.Any])).asInstanceOf[ForceRadial_[NodeDatum]]
  @scala.inline
  def forceRadial[NodeDatum /* <: SimulationNodeDatum */](
    radius: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double],
    x: Double,
    y: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double]
  ): ForceRadial_[NodeDatum] = (^.asInstanceOf[js.Dynamic].applyDynamic("forceRadial")(radius.asInstanceOf[js.Any], x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[ForceRadial_[NodeDatum]]
  @scala.inline
  def forceRadial[NodeDatum /* <: SimulationNodeDatum */](
    radius: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double],
    x: Double,
    y: Double
  ): ForceRadial_[NodeDatum] = (^.asInstanceOf[js.Dynamic].applyDynamic("forceRadial")(radius.asInstanceOf[js.Any], x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[ForceRadial_[NodeDatum]]
  @scala.inline
  def forceRadial[NodeDatum /* <: SimulationNodeDatum */](
    radius: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double],
    x: Unit,
    y: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double]
  ): ForceRadial_[NodeDatum] = (^.asInstanceOf[js.Dynamic].applyDynamic("forceRadial")(radius.asInstanceOf[js.Any], x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[ForceRadial_[NodeDatum]]
  @scala.inline
  def forceRadial[NodeDatum /* <: SimulationNodeDatum */](
    radius: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double],
    x: Unit,
    y: Double
  ): ForceRadial_[NodeDatum] = (^.asInstanceOf[js.Dynamic].applyDynamic("forceRadial")(radius.asInstanceOf[js.Any], x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[ForceRadial_[NodeDatum]]
  @scala.inline
  def forceRadial[NodeDatum /* <: SimulationNodeDatum */](radius: Double): ForceRadial_[NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("forceRadial")(radius.asInstanceOf[js.Any]).asInstanceOf[ForceRadial_[NodeDatum]]
  @scala.inline
  def forceRadial[NodeDatum /* <: SimulationNodeDatum */](
    radius: Double,
    x: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double]
  ): ForceRadial_[NodeDatum] = (^.asInstanceOf[js.Dynamic].applyDynamic("forceRadial")(radius.asInstanceOf[js.Any], x.asInstanceOf[js.Any])).asInstanceOf[ForceRadial_[NodeDatum]]
  @scala.inline
  def forceRadial[NodeDatum /* <: SimulationNodeDatum */](
    radius: Double,
    x: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double],
    y: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double]
  ): ForceRadial_[NodeDatum] = (^.asInstanceOf[js.Dynamic].applyDynamic("forceRadial")(radius.asInstanceOf[js.Any], x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[ForceRadial_[NodeDatum]]
  @scala.inline
  def forceRadial[NodeDatum /* <: SimulationNodeDatum */](
    radius: Double,
    x: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double],
    y: Double
  ): ForceRadial_[NodeDatum] = (^.asInstanceOf[js.Dynamic].applyDynamic("forceRadial")(radius.asInstanceOf[js.Any], x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[ForceRadial_[NodeDatum]]
  @scala.inline
  def forceRadial[NodeDatum /* <: SimulationNodeDatum */](radius: Double, x: Double): ForceRadial_[NodeDatum] = (^.asInstanceOf[js.Dynamic].applyDynamic("forceRadial")(radius.asInstanceOf[js.Any], x.asInstanceOf[js.Any])).asInstanceOf[ForceRadial_[NodeDatum]]
  @scala.inline
  def forceRadial[NodeDatum /* <: SimulationNodeDatum */](
    radius: Double,
    x: Double,
    y: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double]
  ): ForceRadial_[NodeDatum] = (^.asInstanceOf[js.Dynamic].applyDynamic("forceRadial")(radius.asInstanceOf[js.Any], x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[ForceRadial_[NodeDatum]]
  @scala.inline
  def forceRadial[NodeDatum /* <: SimulationNodeDatum */](radius: Double, x: Double, y: Double): ForceRadial_[NodeDatum] = (^.asInstanceOf[js.Dynamic].applyDynamic("forceRadial")(radius.asInstanceOf[js.Any], x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[ForceRadial_[NodeDatum]]
  @scala.inline
  def forceRadial[NodeDatum /* <: SimulationNodeDatum */](
    radius: Double,
    x: Unit,
    y: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double]
  ): ForceRadial_[NodeDatum] = (^.asInstanceOf[js.Dynamic].applyDynamic("forceRadial")(radius.asInstanceOf[js.Any], x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[ForceRadial_[NodeDatum]]
  @scala.inline
  def forceRadial[NodeDatum /* <: SimulationNodeDatum */](radius: Double, x: Unit, y: Double): ForceRadial_[NodeDatum] = (^.asInstanceOf[js.Dynamic].applyDynamic("forceRadial")(radius.asInstanceOf[js.Any], x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[ForceRadial_[NodeDatum]]
  
  @scala.inline
  def forceSimulation[NodeDatum /* <: SimulationNodeDatum */](): Simulation[NodeDatum, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("forceSimulation")().asInstanceOf[Simulation[NodeDatum, Unit]]
  @scala.inline
  def forceSimulation[NodeDatum /* <: SimulationNodeDatum */](nodesData: js.Array[NodeDatum]): Simulation[NodeDatum, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("forceSimulation")(nodesData.asInstanceOf[js.Any]).asInstanceOf[Simulation[NodeDatum, Unit]]
  
  @scala.inline
  def forceSimulation_NodeDatum_SimulationNodeDatumLinkDatum_SimulationLinkDatumNodeDatum[NodeDatum /* <: SimulationNodeDatum */, LinkDatum /* <: SimulationLinkDatum[NodeDatum] */](): Simulation[NodeDatum, LinkDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("forceSimulation")().asInstanceOf[Simulation[NodeDatum, LinkDatum]]
  @scala.inline
  def forceSimulation_NodeDatum_SimulationNodeDatumLinkDatum_SimulationLinkDatumNodeDatum[NodeDatum /* <: SimulationNodeDatum */, LinkDatum /* <: SimulationLinkDatum[NodeDatum] */](nodesData: js.Array[NodeDatum]): Simulation[NodeDatum, LinkDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("forceSimulation")(nodesData.asInstanceOf[js.Any]).asInstanceOf[Simulation[NodeDatum, LinkDatum]]
  
  @scala.inline
  def forceX[NodeDatum /* <: SimulationNodeDatum */](): ForceX_[NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("forceX")().asInstanceOf[ForceX_[NodeDatum]]
  @scala.inline
  def forceX[NodeDatum /* <: SimulationNodeDatum */](x: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double]): ForceX_[NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("forceX")(x.asInstanceOf[js.Any]).asInstanceOf[ForceX_[NodeDatum]]
  @scala.inline
  def forceX[NodeDatum /* <: SimulationNodeDatum */](x: Double): ForceX_[NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("forceX")(x.asInstanceOf[js.Any]).asInstanceOf[ForceX_[NodeDatum]]
  
  @scala.inline
  def forceY[NodeDatum /* <: SimulationNodeDatum */](): ForceY_[NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("forceY")().asInstanceOf[ForceY_[NodeDatum]]
  @scala.inline
  def forceY[NodeDatum /* <: SimulationNodeDatum */](y: js.Function3[/* d */ NodeDatum, /* i */ Double, /* data */ js.Array[NodeDatum], Double]): ForceY_[NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("forceY")(y.asInstanceOf[js.Any]).asInstanceOf[ForceY_[NodeDatum]]
  @scala.inline
  def forceY[NodeDatum /* <: SimulationNodeDatum */](y: Double): ForceY_[NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("forceY")(y.asInstanceOf[js.Any]).asInstanceOf[ForceY_[NodeDatum]]
  
  @scala.inline
  def format(specifier: String): js.Function1[/* n */ Double | ValueOf, String] = ^.asInstanceOf[js.Dynamic].applyDynamic("format")(specifier.asInstanceOf[js.Any]).asInstanceOf[js.Function1[/* n */ Double | ValueOf, String]]
  
  @scala.inline
  def formatDefaultLocale(defaultLocale: FormatLocaleDefinition): FormatLocaleObject = ^.asInstanceOf[js.Dynamic].applyDynamic("formatDefaultLocale")(defaultLocale.asInstanceOf[js.Any]).asInstanceOf[FormatLocaleObject]
  
  @scala.inline
  def formatLocale(locale: FormatLocaleDefinition): FormatLocaleObject = ^.asInstanceOf[js.Dynamic].applyDynamic("formatLocale")(locale.asInstanceOf[js.Any]).asInstanceOf[FormatLocaleObject]
  
  @scala.inline
  def formatPrefix(specifier: String, value: Double): js.Function1[/* n */ Double | ValueOf, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("formatPrefix")(specifier.asInstanceOf[js.Any], value.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* n */ Double | ValueOf, String]]
  
  @scala.inline
  def formatSpecifier(specifier: String): typings.d3Format.mod.FormatSpecifier_ = ^.asInstanceOf[js.Dynamic].applyDynamic("formatSpecifier")(specifier.asInstanceOf[js.Any]).asInstanceOf[typings.d3Format.mod.FormatSpecifier_]
  
  @scala.inline
  def fsum(values: js.Iterable[js.UndefOr[Numeric | Null]]): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("fsum")(values.asInstanceOf[js.Any]).asInstanceOf[Double]
  @scala.inline
  def fsum[T](
    values: js.Iterable[T],
    accessor: js.Function3[
      /* datum */ T, 
      /* index */ Double, 
      /* array */ js.Iterable[T], 
      js.UndefOr[Double | Null]
    ]
  ): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("fsum")(values.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[Double]
  
  @scala.inline
  def geoAlbers(): GeoConicProjection = ^.asInstanceOf[js.Dynamic].applyDynamic("geoAlbers")().asInstanceOf[GeoConicProjection]
  
  @scala.inline
  def geoAlbersUsa(): GeoProjection_ = ^.asInstanceOf[js.Dynamic].applyDynamic("geoAlbersUsa")().asInstanceOf[GeoProjection_]
  
  @scala.inline
  def geoArea(`object`: ExtendedFeature[GeoGeometryObjects | Null, GeoJsonProperties]): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("geoArea")(`object`.asInstanceOf[js.Any]).asInstanceOf[Double]
  @scala.inline
  def geoArea(`object`: ExtendedFeatureCollection[ExtendedFeature[GeoGeometryObjects | Null, GeoJsonProperties]]): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("geoArea")(`object`.asInstanceOf[js.Any]).asInstanceOf[Double]
  @scala.inline
  def geoArea(`object`: ExtendedGeometryCollection[GeoGeometryObjects]): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("geoArea")(`object`.asInstanceOf[js.Any]).asInstanceOf[Double]
  @scala.inline
  def geoArea(`object`: GeoGeometryObjects): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("geoArea")(`object`.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def geoAzimuthalEqualArea(): GeoProjection_ = ^.asInstanceOf[js.Dynamic].applyDynamic("geoAzimuthalEqualArea")().asInstanceOf[GeoProjection_]
  
  @scala.inline
  def geoAzimuthalEqualAreaRaw(): GeoRawProjection = ^.asInstanceOf[js.Dynamic].applyDynamic("geoAzimuthalEqualAreaRaw")().asInstanceOf[GeoRawProjection]
  
  @scala.inline
  def geoAzimuthalEquidistant(): GeoProjection_ = ^.asInstanceOf[js.Dynamic].applyDynamic("geoAzimuthalEquidistant")().asInstanceOf[GeoProjection_]
  
  @scala.inline
  def geoAzimuthalEquidistantRaw(): GeoRawProjection = ^.asInstanceOf[js.Dynamic].applyDynamic("geoAzimuthalEquidistantRaw")().asInstanceOf[GeoRawProjection]
  
  @scala.inline
  def geoBounds(`object`: ExtendedFeature[GeoGeometryObjects | Null, GeoJsonProperties]): js.Tuple2[js.Tuple2[Double, Double], js.Tuple2[Double, Double]] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoBounds")(`object`.asInstanceOf[js.Any]).asInstanceOf[js.Tuple2[js.Tuple2[Double, Double], js.Tuple2[Double, Double]]]
  @scala.inline
  def geoBounds(`object`: ExtendedFeatureCollection[ExtendedFeature[GeoGeometryObjects | Null, GeoJsonProperties]]): js.Tuple2[js.Tuple2[Double, Double], js.Tuple2[Double, Double]] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoBounds")(`object`.asInstanceOf[js.Any]).asInstanceOf[js.Tuple2[js.Tuple2[Double, Double], js.Tuple2[Double, Double]]]
  @scala.inline
  def geoBounds(`object`: ExtendedGeometryCollection[GeoGeometryObjects]): js.Tuple2[js.Tuple2[Double, Double], js.Tuple2[Double, Double]] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoBounds")(`object`.asInstanceOf[js.Any]).asInstanceOf[js.Tuple2[js.Tuple2[Double, Double], js.Tuple2[Double, Double]]]
  @scala.inline
  def geoBounds(`object`: GeoGeometryObjects): js.Tuple2[js.Tuple2[Double, Double], js.Tuple2[Double, Double]] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoBounds")(`object`.asInstanceOf[js.Any]).asInstanceOf[js.Tuple2[js.Tuple2[Double, Double], js.Tuple2[Double, Double]]]
  
  @scala.inline
  def geoCentroid(`object`: ExtendedFeature[GeoGeometryObjects | Null, GeoJsonProperties]): js.Tuple2[Double, Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoCentroid")(`object`.asInstanceOf[js.Any]).asInstanceOf[js.Tuple2[Double, Double]]
  @scala.inline
  def geoCentroid(`object`: ExtendedFeatureCollection[ExtendedFeature[GeoGeometryObjects | Null, GeoJsonProperties]]): js.Tuple2[Double, Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoCentroid")(`object`.asInstanceOf[js.Any]).asInstanceOf[js.Tuple2[Double, Double]]
  @scala.inline
  def geoCentroid(`object`: ExtendedGeometryCollection[GeoGeometryObjects]): js.Tuple2[Double, Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoCentroid")(`object`.asInstanceOf[js.Any]).asInstanceOf[js.Tuple2[Double, Double]]
  @scala.inline
  def geoCentroid(`object`: GeoGeometryObjects): js.Tuple2[Double, Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoCentroid")(`object`.asInstanceOf[js.Any]).asInstanceOf[js.Tuple2[Double, Double]]
  
  @scala.inline
  def geoCircle(): GeoCircleGenerator[Any, Any] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoCircle")().asInstanceOf[GeoCircleGenerator[Any, Any]]
  
  @scala.inline
  def geoCircle_Datum[Datum](): GeoCircleGenerator[Any, Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoCircle")().asInstanceOf[GeoCircleGenerator[Any, Datum]]
  
  @scala.inline
  def geoCircle_ThisDatum[This, Datum](): GeoCircleGenerator[This, Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoCircle")().asInstanceOf[GeoCircleGenerator[This, Datum]]
  
  @scala.inline
  def geoClipAntimeridian(stream: GeoStream_): GeoStream_ = ^.asInstanceOf[js.Dynamic].applyDynamic("geoClipAntimeridian")(stream.asInstanceOf[js.Any]).asInstanceOf[GeoStream_]
  
  @scala.inline
  def geoClipCircle(angle: Double): js.Function1[/* stream */ GeoStream_, GeoStream_] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoClipCircle")(angle.asInstanceOf[js.Any]).asInstanceOf[js.Function1[/* stream */ GeoStream_, GeoStream_]]
  
  @scala.inline
  def geoClipRectangle(x0: Double, y0: Double, x1: Double, y1: Double): js.Function1[/* stream */ GeoStream_, GeoStream_] = (^.asInstanceOf[js.Dynamic].applyDynamic("geoClipRectangle")(x0.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], x1.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* stream */ GeoStream_, GeoStream_]]
  
  @scala.inline
  def geoConicConformal(): GeoConicProjection = ^.asInstanceOf[js.Dynamic].applyDynamic("geoConicConformal")().asInstanceOf[GeoConicProjection]
  
  @scala.inline
  def geoConicConformalRaw(phi0: Double, phi1: Double): GeoRawProjection = (^.asInstanceOf[js.Dynamic].applyDynamic("geoConicConformalRaw")(phi0.asInstanceOf[js.Any], phi1.asInstanceOf[js.Any])).asInstanceOf[GeoRawProjection]
  
  @scala.inline
  def geoConicEqualArea(): GeoConicProjection = ^.asInstanceOf[js.Dynamic].applyDynamic("geoConicEqualArea")().asInstanceOf[GeoConicProjection]
  
  @scala.inline
  def geoConicEqualAreaRaw(phi0: Double, phi1: Double): GeoRawProjection = (^.asInstanceOf[js.Dynamic].applyDynamic("geoConicEqualAreaRaw")(phi0.asInstanceOf[js.Any], phi1.asInstanceOf[js.Any])).asInstanceOf[GeoRawProjection]
  
  @scala.inline
  def geoConicEquidistant(): GeoConicProjection = ^.asInstanceOf[js.Dynamic].applyDynamic("geoConicEquidistant")().asInstanceOf[GeoConicProjection]
  
  @scala.inline
  def geoConicEquidistantRaw(phi0: Double, phi1: Double): GeoRawProjection = (^.asInstanceOf[js.Dynamic].applyDynamic("geoConicEquidistantRaw")(phi0.asInstanceOf[js.Any], phi1.asInstanceOf[js.Any])).asInstanceOf[GeoRawProjection]
  
  @scala.inline
  def geoContains(
    `object`: ExtendedFeatureCollection[ExtendedFeature[GeoGeometryObjects | Null, GeoJsonProperties]],
    point: js.Tuple2[Double, Double]
  ): Boolean = (^.asInstanceOf[js.Dynamic].applyDynamic("geoContains")(`object`.asInstanceOf[js.Any], point.asInstanceOf[js.Any])).asInstanceOf[Boolean]
  @scala.inline
  def geoContains(
    `object`: ExtendedFeature[GeoGeometryObjects | Null, GeoJsonProperties],
    point: js.Tuple2[Double, Double]
  ): Boolean = (^.asInstanceOf[js.Dynamic].applyDynamic("geoContains")(`object`.asInstanceOf[js.Any], point.asInstanceOf[js.Any])).asInstanceOf[Boolean]
  @scala.inline
  def geoContains(`object`: ExtendedGeometryCollection[GeoGeometryObjects], point: js.Tuple2[Double, Double]): Boolean = (^.asInstanceOf[js.Dynamic].applyDynamic("geoContains")(`object`.asInstanceOf[js.Any], point.asInstanceOf[js.Any])).asInstanceOf[Boolean]
  @scala.inline
  def geoContains(`object`: GeoGeometryObjects, point: js.Tuple2[Double, Double]): Boolean = (^.asInstanceOf[js.Dynamic].applyDynamic("geoContains")(`object`.asInstanceOf[js.Any], point.asInstanceOf[js.Any])).asInstanceOf[Boolean]
  
  @scala.inline
  def geoDistance(a: js.Tuple2[Double, Double], b: js.Tuple2[Double, Double]): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("geoDistance")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[Double]
  
  @scala.inline
  def geoEqualEarth(): GeoProjection_ = ^.asInstanceOf[js.Dynamic].applyDynamic("geoEqualEarth")().asInstanceOf[GeoProjection_]
  
  @scala.inline
  def geoEqualEarthRaw(): GeoRawProjection = ^.asInstanceOf[js.Dynamic].applyDynamic("geoEqualEarthRaw")().asInstanceOf[GeoRawProjection]
  
  @scala.inline
  def geoEquirectangular(): GeoProjection_ = ^.asInstanceOf[js.Dynamic].applyDynamic("geoEquirectangular")().asInstanceOf[GeoProjection_]
  
  @scala.inline
  def geoEquirectangularRaw(): GeoRawProjection = ^.asInstanceOf[js.Dynamic].applyDynamic("geoEquirectangularRaw")().asInstanceOf[GeoRawProjection]
  
  @scala.inline
  def geoGnomonic(): GeoProjection_ = ^.asInstanceOf[js.Dynamic].applyDynamic("geoGnomonic")().asInstanceOf[GeoProjection_]
  
  @scala.inline
  def geoGnomonicRaw(): GeoRawProjection = ^.asInstanceOf[js.Dynamic].applyDynamic("geoGnomonicRaw")().asInstanceOf[GeoRawProjection]
  
  @scala.inline
  def geoGraticule(): GeoGraticuleGenerator = ^.asInstanceOf[js.Dynamic].applyDynamic("geoGraticule")().asInstanceOf[GeoGraticuleGenerator]
  
  @scala.inline
  def geoGraticule10(): MultiLineString = ^.asInstanceOf[js.Dynamic].applyDynamic("geoGraticule10")().asInstanceOf[MultiLineString]
  
  @scala.inline
  def geoIdentity(): GeoIdentityTransform = ^.asInstanceOf[js.Dynamic].applyDynamic("geoIdentity")().asInstanceOf[GeoIdentityTransform]
  
  @scala.inline
  def geoInterpolate(a: js.Tuple2[Double, Double], b: js.Tuple2[Double, Double]): js.Function1[/* t */ Double, js.Tuple2[Double, Double]] = (^.asInstanceOf[js.Dynamic].applyDynamic("geoInterpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.Tuple2[Double, Double]]]
  
  @scala.inline
  def geoLength(`object`: ExtendedFeature[GeoGeometryObjects | Null, GeoJsonProperties]): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("geoLength")(`object`.asInstanceOf[js.Any]).asInstanceOf[Double]
  @scala.inline
  def geoLength(`object`: ExtendedFeatureCollection[ExtendedFeature[GeoGeometryObjects | Null, GeoJsonProperties]]): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("geoLength")(`object`.asInstanceOf[js.Any]).asInstanceOf[Double]
  @scala.inline
  def geoLength(`object`: ExtendedGeometryCollection[GeoGeometryObjects]): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("geoLength")(`object`.asInstanceOf[js.Any]).asInstanceOf[Double]
  @scala.inline
  def geoLength(`object`: GeoGeometryObjects): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("geoLength")(`object`.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def geoMercator(): GeoProjection_ = ^.asInstanceOf[js.Dynamic].applyDynamic("geoMercator")().asInstanceOf[GeoProjection_]
  
  @scala.inline
  def geoMercatorRaw(): GeoRawProjection = ^.asInstanceOf[js.Dynamic].applyDynamic("geoMercatorRaw")().asInstanceOf[GeoRawProjection]
  
  @scala.inline
  def geoNaturalEarth1(): GeoProjection_ = ^.asInstanceOf[js.Dynamic].applyDynamic("geoNaturalEarth1")().asInstanceOf[GeoProjection_]
  
  @scala.inline
  def geoNaturalEarth1Raw(): GeoRawProjection = ^.asInstanceOf[js.Dynamic].applyDynamic("geoNaturalEarth1Raw")().asInstanceOf[GeoRawProjection]
  
  @scala.inline
  def geoOrthographic(): GeoProjection_ = ^.asInstanceOf[js.Dynamic].applyDynamic("geoOrthographic")().asInstanceOf[GeoProjection_]
  
  @scala.inline
  def geoOrthographicRaw(): GeoRawProjection = ^.asInstanceOf[js.Dynamic].applyDynamic("geoOrthographicRaw")().asInstanceOf[GeoRawProjection]
  
  @scala.inline
  def geoPath(): GeoPath_[Any, GeoPermissibleObjects] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")().asInstanceOf[GeoPath_[Any, GeoPermissibleObjects]]
  @scala.inline
  def geoPath(projection: Null, context: GeoContext): GeoPath_[Any, GeoPermissibleObjects] = (^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")(projection.asInstanceOf[js.Any], context.asInstanceOf[js.Any])).asInstanceOf[GeoPath_[Any, GeoPermissibleObjects]]
  @scala.inline
  def geoPath(projection: Unit, context: GeoContext): GeoPath_[Any, GeoPermissibleObjects] = (^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")(projection.asInstanceOf[js.Any], context.asInstanceOf[js.Any])).asInstanceOf[GeoPath_[Any, GeoPermissibleObjects]]
  @scala.inline
  def geoPath(projection: GeoProjection_): GeoPath_[Any, GeoPermissibleObjects] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")(projection.asInstanceOf[js.Any]).asInstanceOf[GeoPath_[Any, GeoPermissibleObjects]]
  @scala.inline
  def geoPath(projection: GeoProjection_, context: GeoContext): GeoPath_[Any, GeoPermissibleObjects] = (^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")(projection.asInstanceOf[js.Any], context.asInstanceOf[js.Any])).asInstanceOf[GeoPath_[Any, GeoPermissibleObjects]]
  @scala.inline
  def geoPath(projection: GeoStreamWrapper): GeoPath_[Any, GeoPermissibleObjects] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")(projection.asInstanceOf[js.Any]).asInstanceOf[GeoPath_[Any, GeoPermissibleObjects]]
  @scala.inline
  def geoPath(projection: GeoStreamWrapper, context: GeoContext): GeoPath_[Any, GeoPermissibleObjects] = (^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")(projection.asInstanceOf[js.Any], context.asInstanceOf[js.Any])).asInstanceOf[GeoPath_[Any, GeoPermissibleObjects]]
  
  @scala.inline
  def geoPath_DatumObject_GeoPermissibleObjects[DatumObject /* <: GeoPermissibleObjects */](): GeoPath_[Any, DatumObject] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")().asInstanceOf[GeoPath_[Any, DatumObject]]
  @scala.inline
  def geoPath_DatumObject_GeoPermissibleObjects[DatumObject /* <: GeoPermissibleObjects */](projection: Null, context: GeoContext): GeoPath_[Any, DatumObject] = (^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")(projection.asInstanceOf[js.Any], context.asInstanceOf[js.Any])).asInstanceOf[GeoPath_[Any, DatumObject]]
  @scala.inline
  def geoPath_DatumObject_GeoPermissibleObjects[DatumObject /* <: GeoPermissibleObjects */](projection: Unit, context: GeoContext): GeoPath_[Any, DatumObject] = (^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")(projection.asInstanceOf[js.Any], context.asInstanceOf[js.Any])).asInstanceOf[GeoPath_[Any, DatumObject]]
  @scala.inline
  def geoPath_DatumObject_GeoPermissibleObjects[DatumObject /* <: GeoPermissibleObjects */](projection: GeoProjection_): GeoPath_[Any, DatumObject] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")(projection.asInstanceOf[js.Any]).asInstanceOf[GeoPath_[Any, DatumObject]]
  @scala.inline
  def geoPath_DatumObject_GeoPermissibleObjects[DatumObject /* <: GeoPermissibleObjects */](projection: GeoProjection_, context: GeoContext): GeoPath_[Any, DatumObject] = (^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")(projection.asInstanceOf[js.Any], context.asInstanceOf[js.Any])).asInstanceOf[GeoPath_[Any, DatumObject]]
  @scala.inline
  def geoPath_DatumObject_GeoPermissibleObjects[DatumObject /* <: GeoPermissibleObjects */](projection: GeoStreamWrapper): GeoPath_[Any, DatumObject] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")(projection.asInstanceOf[js.Any]).asInstanceOf[GeoPath_[Any, DatumObject]]
  @scala.inline
  def geoPath_DatumObject_GeoPermissibleObjects[DatumObject /* <: GeoPermissibleObjects */](projection: GeoStreamWrapper, context: GeoContext): GeoPath_[Any, DatumObject] = (^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")(projection.asInstanceOf[js.Any], context.asInstanceOf[js.Any])).asInstanceOf[GeoPath_[Any, DatumObject]]
  
  @scala.inline
  def geoPath_ThisDatumObject_GeoPermissibleObjects[This, DatumObject /* <: GeoPermissibleObjects */](): GeoPath_[This, DatumObject] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")().asInstanceOf[GeoPath_[This, DatumObject]]
  @scala.inline
  def geoPath_ThisDatumObject_GeoPermissibleObjects[This, DatumObject /* <: GeoPermissibleObjects */](projection: Null, context: GeoContext): GeoPath_[This, DatumObject] = (^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")(projection.asInstanceOf[js.Any], context.asInstanceOf[js.Any])).asInstanceOf[GeoPath_[This, DatumObject]]
  @scala.inline
  def geoPath_ThisDatumObject_GeoPermissibleObjects[This, DatumObject /* <: GeoPermissibleObjects */](projection: Unit, context: GeoContext): GeoPath_[This, DatumObject] = (^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")(projection.asInstanceOf[js.Any], context.asInstanceOf[js.Any])).asInstanceOf[GeoPath_[This, DatumObject]]
  @scala.inline
  def geoPath_ThisDatumObject_GeoPermissibleObjects[This, DatumObject /* <: GeoPermissibleObjects */](projection: GeoProjection_): GeoPath_[This, DatumObject] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")(projection.asInstanceOf[js.Any]).asInstanceOf[GeoPath_[This, DatumObject]]
  @scala.inline
  def geoPath_ThisDatumObject_GeoPermissibleObjects[This, DatumObject /* <: GeoPermissibleObjects */](projection: GeoProjection_, context: GeoContext): GeoPath_[This, DatumObject] = (^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")(projection.asInstanceOf[js.Any], context.asInstanceOf[js.Any])).asInstanceOf[GeoPath_[This, DatumObject]]
  @scala.inline
  def geoPath_ThisDatumObject_GeoPermissibleObjects[This, DatumObject /* <: GeoPermissibleObjects */](projection: GeoStreamWrapper): GeoPath_[This, DatumObject] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")(projection.asInstanceOf[js.Any]).asInstanceOf[GeoPath_[This, DatumObject]]
  @scala.inline
  def geoPath_ThisDatumObject_GeoPermissibleObjects[This, DatumObject /* <: GeoPermissibleObjects */](projection: GeoStreamWrapper, context: GeoContext): GeoPath_[This, DatumObject] = (^.asInstanceOf[js.Dynamic].applyDynamic("geoPath")(projection.asInstanceOf[js.Any], context.asInstanceOf[js.Any])).asInstanceOf[GeoPath_[This, DatumObject]]
  
  @scala.inline
  def geoProjection(project: GeoRawProjection): GeoProjection_ = ^.asInstanceOf[js.Dynamic].applyDynamic("geoProjection")(project.asInstanceOf[js.Any]).asInstanceOf[GeoProjection_]
  
  @scala.inline
  def geoProjectionMutator(factory: js.Function1[/* repeated */ Any, GeoRawProjection]): js.Function0[GeoProjection_] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoProjectionMutator")(factory.asInstanceOf[js.Any]).asInstanceOf[js.Function0[GeoProjection_]]
  
  @scala.inline
  def geoRotation(angles: js.Tuple2[Double, Double]): GeoRotation_ = ^.asInstanceOf[js.Dynamic].applyDynamic("geoRotation")(angles.asInstanceOf[js.Any]).asInstanceOf[GeoRotation_]
  @scala.inline
  def geoRotation(angles: js.Tuple3[Double, Double, Double]): GeoRotation_ = ^.asInstanceOf[js.Dynamic].applyDynamic("geoRotation")(angles.asInstanceOf[js.Any]).asInstanceOf[GeoRotation_]
  
  @scala.inline
  def geoStereographic(): GeoProjection_ = ^.asInstanceOf[js.Dynamic].applyDynamic("geoStereographic")().asInstanceOf[GeoProjection_]
  
  @scala.inline
  def geoStereographicRaw(): GeoRawProjection = ^.asInstanceOf[js.Dynamic].applyDynamic("geoStereographicRaw")().asInstanceOf[GeoRawProjection]
  
  @scala.inline
  def geoStream(
    `object`: ExtendedFeatureCollection[ExtendedFeature[GeoGeometryObjects | Null, GeoJsonProperties]],
    stream: GeoStream_
  ): Unit = (^.asInstanceOf[js.Dynamic].applyDynamic("geoStream")(`object`.asInstanceOf[js.Any], stream.asInstanceOf[js.Any])).asInstanceOf[Unit]
  @scala.inline
  def geoStream(`object`: ExtendedFeature[GeoGeometryObjects | Null, GeoJsonProperties], stream: GeoStream_): Unit = (^.asInstanceOf[js.Dynamic].applyDynamic("geoStream")(`object`.asInstanceOf[js.Any], stream.asInstanceOf[js.Any])).asInstanceOf[Unit]
  @scala.inline
  def geoStream(`object`: ExtendedGeometryCollection[GeoGeometryObjects], stream: GeoStream_): Unit = (^.asInstanceOf[js.Dynamic].applyDynamic("geoStream")(`object`.asInstanceOf[js.Any], stream.asInstanceOf[js.Any])).asInstanceOf[Unit]
  @scala.inline
  def geoStream(`object`: GeoGeometryObjects, stream: GeoStream_): Unit = (^.asInstanceOf[js.Dynamic].applyDynamic("geoStream")(`object`.asInstanceOf[js.Any], stream.asInstanceOf[js.Any])).asInstanceOf[Unit]
  
  @scala.inline
  def geoTransform[T /* <: GeoTransformPrototype */](methods: T): `0`[T] = ^.asInstanceOf[js.Dynamic].applyDynamic("geoTransform")(methods.asInstanceOf[js.Any]).asInstanceOf[`0`[T]]
  
  @scala.inline
  def geoTransverseMercator(): GeoProjection_ = ^.asInstanceOf[js.Dynamic].applyDynamic("geoTransverseMercator")().asInstanceOf[GeoProjection_]
  
  @scala.inline
  def geoTransverseMercatorRaw(): GeoRawProjection = ^.asInstanceOf[js.Dynamic].applyDynamic("geoTransverseMercatorRaw")().asInstanceOf[GeoRawProjection]
  
  @JSImport("d3", "gray")
  @js.native
  val gray: GrayColorFactory = js.native
  
  @scala.inline
  def greatest[T](iterable: js.Iterable[T]): js.UndefOr[T] = ^.asInstanceOf[js.Dynamic].applyDynamic("greatest")(iterable.asInstanceOf[js.Any]).asInstanceOf[js.UndefOr[T]]
  @scala.inline
  def greatest[T](iterable: js.Iterable[T], accessor: js.Function1[/* a */ T, Any]): js.UndefOr[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("greatest")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[js.UndefOr[T]]
  @scala.inline
  def greatest[T](iterable: js.Iterable[T], comparator: js.Function2[/* a */ T, /* b */ T, Double]): js.UndefOr[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("greatest")(iterable.asInstanceOf[js.Any], comparator.asInstanceOf[js.Any])).asInstanceOf[js.UndefOr[T]]
  
  @scala.inline
  def greatestIndex(iterable: js.Iterable[Any]): js.UndefOr[Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("greatestIndex")(iterable.asInstanceOf[js.Any]).asInstanceOf[js.UndefOr[Double]]
  @scala.inline
  def greatestIndex[T](iterable: js.Iterable[T], accessor: js.Function1[/* a */ T, Any]): js.UndefOr[Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("greatestIndex")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[js.UndefOr[Double]]
  @scala.inline
  def greatestIndex[T](iterable: js.Iterable[T], comparator: js.Function2[/* a */ T, /* b */ T, Double]): js.UndefOr[Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("greatestIndex")(iterable.asInstanceOf[js.Any], comparator.asInstanceOf[js.Any])).asInstanceOf[js.UndefOr[Double]]
  
  @scala.inline
  def group[TObject, TKey](iterable: js.Iterable[TObject], key: js.Function1[/* value */ TObject, TKey]): typings.d3Array.mod.InternMap[TKey, js.Array[TObject]] = (^.asInstanceOf[js.Dynamic].applyDynamic("group")(iterable.asInstanceOf[js.Any], key.asInstanceOf[js.Any])).asInstanceOf[typings.d3Array.mod.InternMap[TKey, js.Array[TObject]]]
  @scala.inline
  def group[TObject, TKey1, TKey2](
    iterable: js.Iterable[TObject],
    key1: js.Function1[/* value */ TObject, TKey1],
    key2: js.Function1[/* value */ TObject, TKey2]
  ): typings.d3Array.mod.InternMap[TKey1, typings.d3Array.mod.InternMap[TKey2, js.Array[TObject]]] = (^.asInstanceOf[js.Dynamic].applyDynamic("group")(iterable.asInstanceOf[js.Any], key1.asInstanceOf[js.Any], key2.asInstanceOf[js.Any])).asInstanceOf[typings.d3Array.mod.InternMap[TKey1, typings.d3Array.mod.InternMap[TKey2, js.Array[TObject]]]]
  @scala.inline
  def group[TObject, TKey1, TKey2, TKey3](
    iterable: js.Iterable[TObject],
    key1: js.Function1[/* value */ TObject, TKey1],
    key2: js.Function1[/* value */ TObject, TKey2],
    key3: js.Function1[/* value */ TObject, TKey3]
  ): typings.d3Array.mod.InternMap[
    TKey1, 
    typings.d3Array.mod.InternMap[TKey2, typings.d3Array.mod.InternMap[TKey3, js.Array[TObject]]]
  ] = (^.asInstanceOf[js.Dynamic].applyDynamic("group")(iterable.asInstanceOf[js.Any], key1.asInstanceOf[js.Any], key2.asInstanceOf[js.Any], key3.asInstanceOf[js.Any])).asInstanceOf[typings.d3Array.mod.InternMap[
    TKey1, 
    typings.d3Array.mod.InternMap[TKey2, typings.d3Array.mod.InternMap[TKey3, js.Array[TObject]]]
  ]]
  
  @scala.inline
  def groupSort[TObject, TKey](
    iterable: js.Iterable[TObject],
    // tslint:disable-next-line:unified-signatures
  accessor: js.Function1[/* value */ js.Array[TObject], Any],
    key: js.Function1[/* value */ TObject, TKey]
  ): js.Array[TKey] = (^.asInstanceOf[js.Dynamic].applyDynamic("groupSort")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any], key.asInstanceOf[js.Any])).asInstanceOf[js.Array[TKey]]
  @scala.inline
  def groupSort[TObject, TKey](
    iterable: js.Iterable[TObject],
    comparator: js.Function2[/* a */ js.Array[TObject], /* b */ js.Array[TObject], Double],
    key: js.Function1[/* value */ TObject, TKey]
  ): js.Array[TKey] = (^.asInstanceOf[js.Dynamic].applyDynamic("groupSort")(iterable.asInstanceOf[js.Any], comparator.asInstanceOf[js.Any], key.asInstanceOf[js.Any])).asInstanceOf[js.Array[TKey]]
  
  @scala.inline
  def groups[TObject, TKey](iterable: js.Iterable[TObject], key: js.Function1[/* value */ TObject, TKey]): js.Array[js.Tuple2[TKey, js.Array[TObject]]] = (^.asInstanceOf[js.Dynamic].applyDynamic("groups")(iterable.asInstanceOf[js.Any], key.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Tuple2[TKey, js.Array[TObject]]]]
  @scala.inline
  def groups[TObject, TKey1, TKey2](
    iterable: js.Iterable[TObject],
    key1: js.Function1[/* value */ TObject, TKey1],
    key2: js.Function1[/* value */ TObject, TKey2]
  ): js.Array[js.Tuple2[TKey1, js.Array[js.Tuple2[TKey2, js.Array[TObject]]]]] = (^.asInstanceOf[js.Dynamic].applyDynamic("groups")(iterable.asInstanceOf[js.Any], key1.asInstanceOf[js.Any], key2.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Tuple2[TKey1, js.Array[js.Tuple2[TKey2, js.Array[TObject]]]]]]
  @scala.inline
  def groups[TObject, TKey1, TKey2, TKey3](
    iterable: js.Iterable[TObject],
    key1: js.Function1[/* value */ TObject, TKey1],
    key2: js.Function1[/* value */ TObject, TKey2],
    key3: js.Function1[/* value */ TObject, TKey3]
  ): js.Array[
    js.Tuple2[TKey1, js.Array[js.Tuple2[TKey2, js.Array[js.Tuple2[TKey3, js.Array[TObject]]]]]]
  ] = (^.asInstanceOf[js.Dynamic].applyDynamic("groups")(iterable.asInstanceOf[js.Any], key1.asInstanceOf[js.Any], key2.asInstanceOf[js.Any], key3.asInstanceOf[js.Any])).asInstanceOf[js.Array[
    js.Tuple2[TKey1, js.Array[js.Tuple2[TKey2, js.Array[js.Tuple2[TKey3, js.Array[TObject]]]]]]
  ]]
  
  @JSImport("d3", "hcl")
  @js.native
  val hcl: HCLColorFactory = js.native
  
  @scala.inline
  def hierarchy[Datum](data: Datum): HierarchyNode[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("hierarchy")(data.asInstanceOf[js.Any]).asInstanceOf[HierarchyNode[Datum]]
  @scala.inline
  def hierarchy[Datum](data: Datum, children: js.Function1[/* d */ Datum, js.UndefOr[js.Iterable[Datum] | Null]]): HierarchyNode[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("hierarchy")(data.asInstanceOf[js.Any], children.asInstanceOf[js.Any])).asInstanceOf[HierarchyNode[Datum]]
  
  @scala.inline
  def histogram(): HistogramGeneratorNumber[Double, Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("histogram")().asInstanceOf[HistogramGeneratorNumber[Double, Double]]
  
  @scala.inline
  def `histogram_DatumValue_UnionDate<undefined>_HistogramGeneratorDate`[Datum, Value /* <: js.UndefOr[js.Date] */](): HistogramGeneratorDate[Datum, Value] = ^.asInstanceOf[js.Dynamic].applyDynamic("histogram")().asInstanceOf[HistogramGeneratorDate[Datum, Value]]
  
  @scala.inline
  def `histogram_DatumValue_UnionDouble<undefined>`[Datum, Value /* <: js.UndefOr[Double] */](): HistogramGeneratorNumber[Datum, Value] = ^.asInstanceOf[js.Dynamic].applyDynamic("histogram")().asInstanceOf[HistogramGeneratorNumber[Datum, Value]]
  
  @JSImport("d3", "hsl")
  @js.native
  val hsl: HSLColorFactory = js.native
  
  @scala.inline
  def html(url: String): js.Promise[Document] = ^.asInstanceOf[js.Dynamic].applyDynamic("html")(url.asInstanceOf[js.Any]).asInstanceOf[js.Promise[Document]]
  @scala.inline
  def html(url: String, init: RequestInit): js.Promise[Document] = (^.asInstanceOf[js.Dynamic].applyDynamic("html")(url.asInstanceOf[js.Any], init.asInstanceOf[js.Any])).asInstanceOf[js.Promise[Document]]
  
  @scala.inline
  def image(url: String): js.Promise[HTMLImageElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("image")(url.asInstanceOf[js.Any]).asInstanceOf[js.Promise[HTMLImageElement]]
  @scala.inline
  def image(url: String, init: PartialHTMLImageElement): js.Promise[HTMLImageElement] = (^.asInstanceOf[js.Dynamic].applyDynamic("image")(url.asInstanceOf[js.Any], init.asInstanceOf[js.Any])).asInstanceOf[js.Promise[HTMLImageElement]]
  
  @scala.inline
  def index[TObject, TKey](iterable: js.Iterable[TObject], key: js.Function1[/* value */ TObject, TKey]): typings.d3Array.mod.InternMap[TKey, TObject] = (^.asInstanceOf[js.Dynamic].applyDynamic("index")(iterable.asInstanceOf[js.Any], key.asInstanceOf[js.Any])).asInstanceOf[typings.d3Array.mod.InternMap[TKey, TObject]]
  @scala.inline
  def index[TObject, TKey1, TKey2](
    iterable: js.Iterable[TObject],
    key1: js.Function1[/* value */ TObject, TKey1],
    key2: js.Function1[/* value */ TObject, TKey2]
  ): typings.d3Array.mod.InternMap[TKey1, typings.d3Array.mod.InternMap[TKey2, TObject]] = (^.asInstanceOf[js.Dynamic].applyDynamic("index")(iterable.asInstanceOf[js.Any], key1.asInstanceOf[js.Any], key2.asInstanceOf[js.Any])).asInstanceOf[typings.d3Array.mod.InternMap[TKey1, typings.d3Array.mod.InternMap[TKey2, TObject]]]
  @scala.inline
  def index[TObject, TKey1, TKey2, TKey3](
    iterable: js.Iterable[TObject],
    key1: js.Function1[/* value */ TObject, TKey1],
    key2: js.Function1[/* value */ TObject, TKey2],
    key3: js.Function1[/* value */ TObject, TKey3]
  ): typings.d3Array.mod.InternMap[
    TKey1, 
    typings.d3Array.mod.InternMap[TKey2, typings.d3Array.mod.InternMap[TKey3, TObject]]
  ] = (^.asInstanceOf[js.Dynamic].applyDynamic("index")(iterable.asInstanceOf[js.Any], key1.asInstanceOf[js.Any], key2.asInstanceOf[js.Any], key3.asInstanceOf[js.Any])).asInstanceOf[typings.d3Array.mod.InternMap[
    TKey1, 
    typings.d3Array.mod.InternMap[TKey2, typings.d3Array.mod.InternMap[TKey3, TObject]]
  ]]
  
  @scala.inline
  def indexes[TObject, TKey](iterable: js.Iterable[TObject], key: js.Function1[/* value */ TObject, TKey]): js.Array[js.Tuple2[TKey, TObject]] = (^.asInstanceOf[js.Dynamic].applyDynamic("indexes")(iterable.asInstanceOf[js.Any], key.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Tuple2[TKey, TObject]]]
  @scala.inline
  def indexes[TObject, TKey1, TKey2](
    iterable: js.Iterable[TObject],
    key1: js.Function1[/* value */ TObject, TKey1],
    key2: js.Function1[/* value */ TObject, TKey2]
  ): js.Array[js.Tuple2[TKey1, js.Array[js.Tuple2[TKey2, TObject]]]] = (^.asInstanceOf[js.Dynamic].applyDynamic("indexes")(iterable.asInstanceOf[js.Any], key1.asInstanceOf[js.Any], key2.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Tuple2[TKey1, js.Array[js.Tuple2[TKey2, TObject]]]]]
  @scala.inline
  def indexes[TObject, TKey1, TKey2, TKey3](
    iterable: js.Iterable[TObject],
    key1: js.Function1[/* value */ TObject, TKey1],
    key2: js.Function1[/* value */ TObject, TKey2],
    key3: js.Function1[/* value */ TObject, TKey3]
  ): js.Array[
    js.Tuple2[TKey1, js.Array[js.Tuple2[TKey2, js.Array[js.Tuple2[TKey3, TObject]]]]]
  ] = (^.asInstanceOf[js.Dynamic].applyDynamic("indexes")(iterable.asInstanceOf[js.Any], key1.asInstanceOf[js.Any], key2.asInstanceOf[js.Any], key3.asInstanceOf[js.Any])).asInstanceOf[js.Array[
    js.Tuple2[TKey1, js.Array[js.Tuple2[TKey2, js.Array[js.Tuple2[TKey3, TObject]]]]]
  ]]
  
  @scala.inline
  def interpolate(a: String, b: String): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolate(a: String, b: ColorCommonInstance): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolate(a: js.Array[Double], b: js.typedarray.DataView): js.Function1[/* t */ Double, js.typedarray.DataView] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.DataView]]
  @scala.inline
  def interpolate(a: js.Array[Double], b: js.typedarray.Float32Array): js.Function1[/* t */ Double, js.typedarray.Float32Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Float32Array]]
  @scala.inline
  def interpolate(a: js.Array[Double], b: js.typedarray.Float64Array): js.Function1[/* t */ Double, js.typedarray.Float64Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Float64Array]]
  @scala.inline
  def interpolate(a: js.Array[Double], b: js.typedarray.Int16Array): js.Function1[/* t */ Double, js.typedarray.Int16Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Int16Array]]
  @scala.inline
  def interpolate(a: js.Array[Double], b: js.typedarray.Int32Array): js.Function1[/* t */ Double, js.typedarray.Int32Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Int32Array]]
  @scala.inline
  def interpolate(a: js.Array[Double], b: js.typedarray.Int8Array): js.Function1[/* t */ Double, js.typedarray.Int8Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Int8Array]]
  @scala.inline
  def interpolate(a: js.Array[Double], b: js.typedarray.Uint16Array): js.Function1[/* t */ Double, js.typedarray.Uint16Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint16Array]]
  @scala.inline
  def interpolate(a: js.Array[Double], b: js.typedarray.Uint32Array): js.Function1[/* t */ Double, js.typedarray.Uint32Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint32Array]]
  @scala.inline
  def interpolate(a: js.Array[Double], b: js.typedarray.Uint8Array): js.Function1[/* t */ Double, js.typedarray.Uint8Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint8Array]]
  @scala.inline
  def interpolate(a: js.Array[Double], b: js.typedarray.Uint8ClampedArray): js.Function1[/* t */ Double, js.typedarray.Uint8ClampedArray] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint8ClampedArray]]
  @scala.inline
  def interpolate(a: js.Date, b: js.Date): js.Function1[/* t */ Double, js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.Date]]
  @scala.inline
  def interpolate(a: Any, b: Boolean): js.Function1[/* t */ Double, Boolean] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, Boolean]]
  @scala.inline
  def interpolate(a: Any, b: Null): js.Function1[/* t */ Double, Null] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, Null]]
  @scala.inline
  def interpolate(a: Double, b: Double): js.Function1[/* t */ Double, Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, Double]]
  @scala.inline
  def interpolate(a: Double, b: typings.d3Interpolate.anon.ValueOf): js.Function1[/* t */ Double, Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, Double]]
  @scala.inline
  def interpolate(a: ColorCommonInstance, b: ColorCommonInstance): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolate(a: ToString, b: String): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolate(a: typings.d3Interpolate.anon.ValueOf, b: Double): js.Function1[/* t */ Double, Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, Double]]
  @scala.inline
  def interpolate(a: typings.d3Interpolate.anon.ValueOf, b: typings.d3Interpolate.anon.ValueOf): js.Function1[/* t */ Double, Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, Double]]
  @scala.inline
  def interpolate(a: NumberArray, b: js.typedarray.DataView): js.Function1[/* t */ Double, js.typedarray.DataView] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.DataView]]
  @scala.inline
  def interpolate(a: NumberArray, b: js.typedarray.Float32Array): js.Function1[/* t */ Double, js.typedarray.Float32Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Float32Array]]
  @scala.inline
  def interpolate(a: NumberArray, b: js.typedarray.Float64Array): js.Function1[/* t */ Double, js.typedarray.Float64Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Float64Array]]
  @scala.inline
  def interpolate(a: NumberArray, b: js.typedarray.Int16Array): js.Function1[/* t */ Double, js.typedarray.Int16Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Int16Array]]
  @scala.inline
  def interpolate(a: NumberArray, b: js.typedarray.Int32Array): js.Function1[/* t */ Double, js.typedarray.Int32Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Int32Array]]
  @scala.inline
  def interpolate(a: NumberArray, b: js.typedarray.Int8Array): js.Function1[/* t */ Double, js.typedarray.Int8Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Int8Array]]
  @scala.inline
  def interpolate(a: NumberArray, b: js.typedarray.Uint16Array): js.Function1[/* t */ Double, js.typedarray.Uint16Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint16Array]]
  @scala.inline
  def interpolate(a: NumberArray, b: js.typedarray.Uint32Array): js.Function1[/* t */ Double, js.typedarray.Uint32Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint32Array]]
  @scala.inline
  def interpolate(a: NumberArray, b: js.typedarray.Uint8Array): js.Function1[/* t */ Double, js.typedarray.Uint8Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint8Array]]
  @scala.inline
  def interpolate(a: NumberArray, b: js.typedarray.Uint8ClampedArray): js.Function1[/* t */ Double, js.typedarray.Uint8ClampedArray] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint8ClampedArray]]
  @scala.inline
  def interpolate[U /* <: js.Array[Any] */](a: js.Array[Any], b: U): js.Function1[/* t */ Double, U] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, U]]
  @scala.inline
  def interpolate[U /* <: js.Object */](a: Any, b: U): js.Function1[/* t */ Double, U] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, U]]
  
  @scala.inline
  def interpolateArray(a: js.Array[Double], b: js.typedarray.DataView): js.Function1[/* t */ Double, js.typedarray.DataView] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.DataView]]
  @scala.inline
  def interpolateArray(a: js.Array[Double], b: js.typedarray.Float32Array): js.Function1[/* t */ Double, js.typedarray.Float32Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Float32Array]]
  @scala.inline
  def interpolateArray(a: js.Array[Double], b: js.typedarray.Float64Array): js.Function1[/* t */ Double, js.typedarray.Float64Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Float64Array]]
  @scala.inline
  def interpolateArray(a: js.Array[Double], b: js.typedarray.Int16Array): js.Function1[/* t */ Double, js.typedarray.Int16Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Int16Array]]
  @scala.inline
  def interpolateArray(a: js.Array[Double], b: js.typedarray.Int32Array): js.Function1[/* t */ Double, js.typedarray.Int32Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Int32Array]]
  @scala.inline
  def interpolateArray(a: js.Array[Double], b: js.typedarray.Int8Array): js.Function1[/* t */ Double, js.typedarray.Int8Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Int8Array]]
  @scala.inline
  def interpolateArray(a: js.Array[Double], b: js.typedarray.Uint16Array): js.Function1[/* t */ Double, js.typedarray.Uint16Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint16Array]]
  @scala.inline
  def interpolateArray(a: js.Array[Double], b: js.typedarray.Uint32Array): js.Function1[/* t */ Double, js.typedarray.Uint32Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint32Array]]
  @scala.inline
  def interpolateArray(a: js.Array[Double], b: js.typedarray.Uint8Array): js.Function1[/* t */ Double, js.typedarray.Uint8Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint8Array]]
  @scala.inline
  def interpolateArray(a: js.Array[Double], b: js.typedarray.Uint8ClampedArray): js.Function1[/* t */ Double, js.typedarray.Uint8ClampedArray] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint8ClampedArray]]
  @scala.inline
  def interpolateArray(a: NumberArray, b: js.typedarray.DataView): js.Function1[/* t */ Double, js.typedarray.DataView] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.DataView]]
  @scala.inline
  def interpolateArray(a: NumberArray, b: js.typedarray.Float32Array): js.Function1[/* t */ Double, js.typedarray.Float32Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Float32Array]]
  @scala.inline
  def interpolateArray(a: NumberArray, b: js.typedarray.Float64Array): js.Function1[/* t */ Double, js.typedarray.Float64Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Float64Array]]
  @scala.inline
  def interpolateArray(a: NumberArray, b: js.typedarray.Int16Array): js.Function1[/* t */ Double, js.typedarray.Int16Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Int16Array]]
  @scala.inline
  def interpolateArray(a: NumberArray, b: js.typedarray.Int32Array): js.Function1[/* t */ Double, js.typedarray.Int32Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Int32Array]]
  @scala.inline
  def interpolateArray(a: NumberArray, b: js.typedarray.Int8Array): js.Function1[/* t */ Double, js.typedarray.Int8Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Int8Array]]
  @scala.inline
  def interpolateArray(a: NumberArray, b: js.typedarray.Uint16Array): js.Function1[/* t */ Double, js.typedarray.Uint16Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint16Array]]
  @scala.inline
  def interpolateArray(a: NumberArray, b: js.typedarray.Uint32Array): js.Function1[/* t */ Double, js.typedarray.Uint32Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint32Array]]
  @scala.inline
  def interpolateArray(a: NumberArray, b: js.typedarray.Uint8Array): js.Function1[/* t */ Double, js.typedarray.Uint8Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint8Array]]
  @scala.inline
  def interpolateArray(a: NumberArray, b: js.typedarray.Uint8ClampedArray): js.Function1[/* t */ Double, js.typedarray.Uint8ClampedArray] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint8ClampedArray]]
  @scala.inline
  def interpolateArray[A /* <: js.Array[Any] */](a: js.Array[Any], b: A): ArrayInterpolator[A] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[ArrayInterpolator[A]]
  
  @scala.inline
  def interpolateBasis(splineNodes: js.Array[Double]): js.Function1[/* t */ Double, Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateBasis")(splineNodes.asInstanceOf[js.Any]).asInstanceOf[js.Function1[/* t */ Double, Double]]
  
  @scala.inline
  def interpolateBasisClosed(splineNodes: js.Array[Double]): js.Function1[/* t */ Double, Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateBasisClosed")(splineNodes.asInstanceOf[js.Any]).asInstanceOf[js.Function1[/* t */ Double, Double]]
  
  @scala.inline
  def interpolateBlues(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateBlues")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateBrBG(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateBrBG")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateBuGn(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateBuGn")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateBuPu(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateBuPu")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateCividis(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateCividis")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateCool(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateCool")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @JSImport("d3", "interpolateCubehelix")
  @js.native
  val interpolateCubehelix: ColorGammaInterpolationFactory = js.native
  
  @scala.inline
  def interpolateCubehelixDefault(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateCubehelixDefault")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @JSImport("d3", "interpolateCubehelixLong")
  @js.native
  val interpolateCubehelixLong: ColorGammaInterpolationFactory = js.native
  
  @scala.inline
  def interpolateDate(a: js.Date, b: js.Date): js.Function1[/* t */ Double, js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateDate")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.Date]]
  
  @scala.inline
  def interpolateDiscrete[T](values: js.Array[T]): js.Function1[/* t */ Double, T] = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateDiscrete")(values.asInstanceOf[js.Any]).asInstanceOf[js.Function1[/* t */ Double, T]]
  
  @scala.inline
  def interpolateGnBu(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateGnBu")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateGreens(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateGreens")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateGreys(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateGreys")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateHcl(a: String, b: String): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateHcl")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolateHcl(a: String, b: ColorCommonInstance): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateHcl")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolateHcl(a: ColorCommonInstance, b: String): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateHcl")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolateHcl(a: ColorCommonInstance, b: ColorCommonInstance): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateHcl")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  
  @scala.inline
  def interpolateHclLong(a: String, b: String): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateHclLong")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolateHclLong(a: String, b: ColorCommonInstance): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateHclLong")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolateHclLong(a: ColorCommonInstance, b: String): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateHclLong")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolateHclLong(a: ColorCommonInstance, b: ColorCommonInstance): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateHclLong")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  
  @scala.inline
  def interpolateHsl(a: String, b: String): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateHsl")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolateHsl(a: String, b: ColorCommonInstance): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateHsl")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolateHsl(a: ColorCommonInstance, b: String): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateHsl")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolateHsl(a: ColorCommonInstance, b: ColorCommonInstance): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateHsl")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  
  @scala.inline
  def interpolateHslLong(a: String, b: String): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateHslLong")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolateHslLong(a: String, b: ColorCommonInstance): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateHslLong")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolateHslLong(a: ColorCommonInstance, b: String): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateHslLong")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolateHslLong(a: ColorCommonInstance, b: ColorCommonInstance): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateHslLong")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  
  @scala.inline
  def interpolateHue(a: Double, b: Double): js.Function1[/* t */ Double, Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateHue")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, Double]]
  
  @scala.inline
  def interpolateInferno(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateInferno")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateLab(a: String, b: String): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateLab")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolateLab(a: String, b: ColorCommonInstance): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateLab")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolateLab(a: ColorCommonInstance, b: String): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateLab")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolateLab(a: ColorCommonInstance, b: ColorCommonInstance): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateLab")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  
  @scala.inline
  def interpolateMagma(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateMagma")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateNumber(a: Double, b: Double): js.Function1[/* t */ Double, Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumber")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, Double]]
  @scala.inline
  def interpolateNumber(a: Double, b: typings.d3Interpolate.anon.ValueOf): js.Function1[/* t */ Double, Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumber")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, Double]]
  @scala.inline
  def interpolateNumber(a: typings.d3Interpolate.anon.ValueOf, b: Double): js.Function1[/* t */ Double, Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumber")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, Double]]
  @scala.inline
  def interpolateNumber(a: typings.d3Interpolate.anon.ValueOf, b: typings.d3Interpolate.anon.ValueOf): js.Function1[/* t */ Double, Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumber")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, Double]]
  
  @scala.inline
  def interpolateNumberArray(a: js.Array[Double], b: js.Array[Double]): js.Function1[/* t */ Double, js.Array[Double]] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.Array[Double]]]
  @scala.inline
  def interpolateNumberArray(a: js.Array[Double], b: js.typedarray.DataView): js.Function1[/* t */ Double, js.typedarray.DataView] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.DataView]]
  @scala.inline
  def interpolateNumberArray(a: js.Array[Double], b: js.typedarray.Float32Array): js.Function1[/* t */ Double, js.typedarray.Float32Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Float32Array]]
  @scala.inline
  def interpolateNumberArray(a: js.Array[Double], b: js.typedarray.Float64Array): js.Function1[/* t */ Double, js.typedarray.Float64Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Float64Array]]
  @scala.inline
  def interpolateNumberArray(a: js.Array[Double], b: js.typedarray.Int16Array): js.Function1[/* t */ Double, js.typedarray.Int16Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Int16Array]]
  @scala.inline
  def interpolateNumberArray(a: js.Array[Double], b: js.typedarray.Int32Array): js.Function1[/* t */ Double, js.typedarray.Int32Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Int32Array]]
  @scala.inline
  def interpolateNumberArray(a: js.Array[Double], b: js.typedarray.Int8Array): js.Function1[/* t */ Double, js.typedarray.Int8Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Int8Array]]
  @scala.inline
  def interpolateNumberArray(a: js.Array[Double], b: js.typedarray.Uint16Array): js.Function1[/* t */ Double, js.typedarray.Uint16Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint16Array]]
  @scala.inline
  def interpolateNumberArray(a: js.Array[Double], b: js.typedarray.Uint32Array): js.Function1[/* t */ Double, js.typedarray.Uint32Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint32Array]]
  @scala.inline
  def interpolateNumberArray(a: js.Array[Double], b: js.typedarray.Uint8Array): js.Function1[/* t */ Double, js.typedarray.Uint8Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint8Array]]
  @scala.inline
  def interpolateNumberArray(a: js.Array[Double], b: js.typedarray.Uint8ClampedArray): js.Function1[/* t */ Double, js.typedarray.Uint8ClampedArray] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint8ClampedArray]]
  @scala.inline
  def interpolateNumberArray(a: NumberArray, b: js.Array[Double]): js.Function1[/* t */ Double, js.Array[Double]] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.Array[Double]]]
  @scala.inline
  def interpolateNumberArray(a: NumberArray, b: js.typedarray.DataView): js.Function1[/* t */ Double, js.typedarray.DataView] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.DataView]]
  @scala.inline
  def interpolateNumberArray(a: NumberArray, b: js.typedarray.Float32Array): js.Function1[/* t */ Double, js.typedarray.Float32Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Float32Array]]
  @scala.inline
  def interpolateNumberArray(a: NumberArray, b: js.typedarray.Float64Array): js.Function1[/* t */ Double, js.typedarray.Float64Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Float64Array]]
  @scala.inline
  def interpolateNumberArray(a: NumberArray, b: js.typedarray.Int16Array): js.Function1[/* t */ Double, js.typedarray.Int16Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Int16Array]]
  @scala.inline
  def interpolateNumberArray(a: NumberArray, b: js.typedarray.Int32Array): js.Function1[/* t */ Double, js.typedarray.Int32Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Int32Array]]
  @scala.inline
  def interpolateNumberArray(a: NumberArray, b: js.typedarray.Int8Array): js.Function1[/* t */ Double, js.typedarray.Int8Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Int8Array]]
  @scala.inline
  def interpolateNumberArray(a: NumberArray, b: js.typedarray.Uint16Array): js.Function1[/* t */ Double, js.typedarray.Uint16Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint16Array]]
  @scala.inline
  def interpolateNumberArray(a: NumberArray, b: js.typedarray.Uint32Array): js.Function1[/* t */ Double, js.typedarray.Uint32Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint32Array]]
  @scala.inline
  def interpolateNumberArray(a: NumberArray, b: js.typedarray.Uint8Array): js.Function1[/* t */ Double, js.typedarray.Uint8Array] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint8Array]]
  @scala.inline
  def interpolateNumberArray(a: NumberArray, b: js.typedarray.Uint8ClampedArray): js.Function1[/* t */ Double, js.typedarray.Uint8ClampedArray] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateNumberArray")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, js.typedarray.Uint8ClampedArray]]
  
  @scala.inline
  def interpolateObject[U /* <: js.Object */](a: Any, b: U): js.Function1[/* t */ Double, U] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateObject")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, U]]
  
  @scala.inline
  def interpolateOrRd(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateOrRd")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateOranges(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateOranges")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolatePRGn(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolatePRGn")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolatePiYG(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolatePiYG")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolatePlasma(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolatePlasma")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolatePuBu(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolatePuBu")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolatePuBuGn(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolatePuBuGn")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolatePuOr(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolatePuOr")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolatePuRd(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolatePuRd")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolatePurples(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolatePurples")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateRainbow(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateRainbow")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateRdBu(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateRdBu")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateRdGy(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateRdGy")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateRdPu(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateRdPu")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateRdYlBu(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateRdYlBu")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateRdYlGn(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateRdYlGn")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateReds(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateReds")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @JSImport("d3", "interpolateRgb")
  @js.native
  val interpolateRgb: ColorGammaInterpolationFactory = js.native
  
  @scala.inline
  def interpolateRgbBasis(colors: js.Array[String | ColorCommonInstance]): js.Function1[/* t */ Double, String] = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateRgbBasis")(colors.asInstanceOf[js.Any]).asInstanceOf[js.Function1[/* t */ Double, String]]
  
  @scala.inline
  def interpolateRgbBasisClosed(colors: js.Array[String | ColorCommonInstance]): js.Function1[/* t */ Double, String] = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateRgbBasisClosed")(colors.asInstanceOf[js.Any]).asInstanceOf[js.Function1[/* t */ Double, String]]
  
  @scala.inline
  def interpolateRound(a: Double, b: Double): js.Function1[/* t */ Double, Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateRound")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, Double]]
  @scala.inline
  def interpolateRound(a: Double, b: typings.d3Interpolate.anon.ValueOf): js.Function1[/* t */ Double, Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateRound")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, Double]]
  @scala.inline
  def interpolateRound(a: typings.d3Interpolate.anon.ValueOf, b: Double): js.Function1[/* t */ Double, Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateRound")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, Double]]
  @scala.inline
  def interpolateRound(a: typings.d3Interpolate.anon.ValueOf, b: typings.d3Interpolate.anon.ValueOf): js.Function1[/* t */ Double, Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateRound")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, Double]]
  
  @scala.inline
  def interpolateSinebow(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateSinebow")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateSpectral(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateSpectral")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateString(a: String, b: String): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateString")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolateString(a: String, b: ToString): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateString")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolateString(a: ToString, b: String): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateString")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  @scala.inline
  def interpolateString(a: ToString, b: ToString): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateString")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  
  @scala.inline
  def interpolateTransformCss(a: String, b: String): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateTransformCss")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  
  @scala.inline
  def interpolateTransformSvg(a: String, b: String): js.Function1[/* t */ Double, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateTransformSvg")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, String]]
  
  @scala.inline
  def interpolateTurbo(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateTurbo")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateViridis(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateViridis")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateWarm(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateWarm")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateYlGn(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateYlGn")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateYlGnBu(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateYlGnBu")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateYlOrBr(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateYlOrBr")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateYlOrRd(t: Double): String = ^.asInstanceOf[js.Dynamic].applyDynamic("interpolateYlOrRd")(t.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def interpolateZoom(a: ZoomView, b: ZoomView): ZoomInterpolator = (^.asInstanceOf[js.Dynamic].applyDynamic("interpolateZoom")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[ZoomInterpolator]
  
  @scala.inline
  def interrupt(node: BaseType): Unit = ^.asInstanceOf[js.Dynamic].applyDynamic("interrupt")(node.asInstanceOf[js.Any]).asInstanceOf[Unit]
  @scala.inline
  def interrupt(node: BaseType, name: String): Unit = (^.asInstanceOf[js.Dynamic].applyDynamic("interrupt")(node.asInstanceOf[js.Any], name.asInstanceOf[js.Any])).asInstanceOf[Unit]
  
  @scala.inline
  def intersection[T](iterables: js.Iterable[T]*): typings.d3Array.mod.InternSet[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("intersection")(iterables.asInstanceOf[Seq[js.Any]] :_*)).asInstanceOf[typings.d3Array.mod.InternSet[T]]
  
  @scala.inline
  def interval(callback: js.Function1[/* elapsed */ Double, Unit]): Timer_ = ^.asInstanceOf[js.Dynamic].applyDynamic("interval")(callback.asInstanceOf[js.Any]).asInstanceOf[Timer_]
  @scala.inline
  def interval(callback: js.Function1[/* elapsed */ Double, Unit], delay: Double): Timer_ = (^.asInstanceOf[js.Dynamic].applyDynamic("interval")(callback.asInstanceOf[js.Any], delay.asInstanceOf[js.Any])).asInstanceOf[Timer_]
  @scala.inline
  def interval(callback: js.Function1[/* elapsed */ Double, Unit], delay: Double, time: Double): Timer_ = (^.asInstanceOf[js.Dynamic].applyDynamic("interval")(callback.asInstanceOf[js.Any], delay.asInstanceOf[js.Any], time.asInstanceOf[js.Any])).asInstanceOf[Timer_]
  @scala.inline
  def interval(callback: js.Function1[/* elapsed */ Double, Unit], delay: Unit, time: Double): Timer_ = (^.asInstanceOf[js.Dynamic].applyDynamic("interval")(callback.asInstanceOf[js.Any], delay.asInstanceOf[js.Any], time.asInstanceOf[js.Any])).asInstanceOf[Timer_]
  
  @scala.inline
  def isoFormat(date: js.Date): String = ^.asInstanceOf[js.Dynamic].applyDynamic("isoFormat")(date.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def isoParse(dateString: String): js.Date | Null = ^.asInstanceOf[js.Dynamic].applyDynamic("isoParse")(dateString.asInstanceOf[js.Any]).asInstanceOf[js.Date | Null]
  
  @scala.inline
  def json[ParsedJSONObject /* <: Any */](url: String): js.Promise[js.UndefOr[ParsedJSONObject]] = ^.asInstanceOf[js.Dynamic].applyDynamic("json")(url.asInstanceOf[js.Any]).asInstanceOf[js.Promise[js.UndefOr[ParsedJSONObject]]]
  @scala.inline
  def json[ParsedJSONObject /* <: Any */](url: String, init: RequestInit): js.Promise[js.UndefOr[ParsedJSONObject]] = (^.asInstanceOf[js.Dynamic].applyDynamic("json")(url.asInstanceOf[js.Any], init.asInstanceOf[js.Any])).asInstanceOf[js.Promise[js.UndefOr[ParsedJSONObject]]]
  
  @JSImport("d3", "lab")
  @js.native
  val lab: LabColorFactory = js.native
  
  @JSImport("d3", "lch")
  @js.native
  val lch: LCHColorFactory = js.native
  
  @scala.inline
  def least[T](iterable: js.Iterable[T]): js.UndefOr[T] = ^.asInstanceOf[js.Dynamic].applyDynamic("least")(iterable.asInstanceOf[js.Any]).asInstanceOf[js.UndefOr[T]]
  @scala.inline
  def least[T](iterable: js.Iterable[T], accessor: js.Function1[/* a */ T, Any]): js.UndefOr[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("least")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[js.UndefOr[T]]
  @scala.inline
  def least[T](iterable: js.Iterable[T], comparator: js.Function2[/* a */ T, /* b */ T, Double]): js.UndefOr[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("least")(iterable.asInstanceOf[js.Any], comparator.asInstanceOf[js.Any])).asInstanceOf[js.UndefOr[T]]
  
  @scala.inline
  def leastIndex(iterable: js.Iterable[Any]): js.UndefOr[Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("leastIndex")(iterable.asInstanceOf[js.Any]).asInstanceOf[js.UndefOr[Double]]
  @scala.inline
  def leastIndex[T](iterable: js.Iterable[T], accessor: js.Function1[/* a */ T, Any]): js.UndefOr[Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("leastIndex")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[js.UndefOr[Double]]
  @scala.inline
  def leastIndex[T](iterable: js.Iterable[T], comparator: js.Function2[/* a */ T, /* b */ T, Double]): js.UndefOr[Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("leastIndex")(iterable.asInstanceOf[js.Any], comparator.asInstanceOf[js.Any])).asInstanceOf[js.UndefOr[Double]]
  
  @scala.inline
  def line[Datum](): Line_[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("line")().asInstanceOf[Line_[Datum]]
  @scala.inline
  def line[Datum](x: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double]): Line_[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("line")(x.asInstanceOf[js.Any]).asInstanceOf[Line_[Datum]]
  @scala.inline
  def line[Datum](
    x: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double],
    y: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double]
  ): Line_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("line")(x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[Line_[Datum]]
  @scala.inline
  def line[Datum](x: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double], y: Double): Line_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("line")(x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[Line_[Datum]]
  @scala.inline
  def line[Datum](x: Double): Line_[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("line")(x.asInstanceOf[js.Any]).asInstanceOf[Line_[Datum]]
  @scala.inline
  def line[Datum](x: Double, y: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double]): Line_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("line")(x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[Line_[Datum]]
  @scala.inline
  def line[Datum](x: Double, y: Double): Line_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("line")(x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[Line_[Datum]]
  @scala.inline
  def line[Datum](x: Unit, y: js.Function3[/* d */ Datum, /* index */ Double, /* data */ js.Array[Datum], Double]): Line_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("line")(x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[Line_[Datum]]
  @scala.inline
  def line[Datum](x: Unit, y: Double): Line_[Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("line")(x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[Line_[Datum]]
  
  @scala.inline
  def lineRadial(): LineRadial_[js.Tuple2[Double, Double]] = ^.asInstanceOf[js.Dynamic].applyDynamic("lineRadial")().asInstanceOf[LineRadial_[js.Tuple2[Double, Double]]]
  
  @scala.inline
  def lineRadial_Datum[Datum](): LineRadial_[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("lineRadial")().asInstanceOf[LineRadial_[Datum]]
  
  @scala.inline
  def link(curve: CurveFactory): Link_[Any, DefaultLinkObject, js.Tuple2[Double, Double]] = ^.asInstanceOf[js.Dynamic].applyDynamic("link")(curve.asInstanceOf[js.Any]).asInstanceOf[Link_[Any, DefaultLinkObject, js.Tuple2[Double, Double]]]
  
  @scala.inline
  def linkHorizontal(): Link_[Any, DefaultLinkObject, js.Tuple2[Double, Double]] = ^.asInstanceOf[js.Dynamic].applyDynamic("linkHorizontal")().asInstanceOf[Link_[Any, DefaultLinkObject, js.Tuple2[Double, Double]]]
  
  @scala.inline
  def linkHorizontal_LinkDatumNodeDatum[LinkDatum, NodeDatum](): Link_[Any, LinkDatum, NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("linkHorizontal")().asInstanceOf[Link_[Any, LinkDatum, NodeDatum]]
  
  @scala.inline
  def linkHorizontal_ThisLinkDatumNodeDatum[This, LinkDatum, NodeDatum](): Link_[This, LinkDatum, NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("linkHorizontal")().asInstanceOf[Link_[This, LinkDatum, NodeDatum]]
  
  @scala.inline
  def linkRadial(): LinkRadial_[Any, DefaultLinkObject, js.Tuple2[Double, Double]] = ^.asInstanceOf[js.Dynamic].applyDynamic("linkRadial")().asInstanceOf[LinkRadial_[Any, DefaultLinkObject, js.Tuple2[Double, Double]]]
  
  @scala.inline
  def linkRadial_LinkDatumNodeDatum[LinkDatum, NodeDatum](): LinkRadial_[Any, LinkDatum, NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("linkRadial")().asInstanceOf[LinkRadial_[Any, LinkDatum, NodeDatum]]
  
  @scala.inline
  def linkRadial_ThisLinkDatumNodeDatum[This, LinkDatum, NodeDatum](): LinkRadial_[This, LinkDatum, NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("linkRadial")().asInstanceOf[LinkRadial_[This, LinkDatum, NodeDatum]]
  
  @scala.inline
  def linkVertical(): Link_[Any, DefaultLinkObject, js.Tuple2[Double, Double]] = ^.asInstanceOf[js.Dynamic].applyDynamic("linkVertical")().asInstanceOf[Link_[Any, DefaultLinkObject, js.Tuple2[Double, Double]]]
  
  @scala.inline
  def linkVertical_LinkDatumNodeDatum[LinkDatum, NodeDatum](): Link_[Any, LinkDatum, NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("linkVertical")().asInstanceOf[Link_[Any, LinkDatum, NodeDatum]]
  
  @scala.inline
  def linkVertical_ThisLinkDatumNodeDatum[This, LinkDatum, NodeDatum](): Link_[This, LinkDatum, NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("linkVertical")().asInstanceOf[Link_[This, LinkDatum, NodeDatum]]
  
  @scala.inline
  def link_LinkDatumNodeDatum[LinkDatum, NodeDatum](curve: CurveFactory): Link_[Any, LinkDatum, NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("link")(curve.asInstanceOf[js.Any]).asInstanceOf[Link_[Any, LinkDatum, NodeDatum]]
  
  @scala.inline
  def link_ThisLinkDatumNodeDatum[This, LinkDatum, NodeDatum](curve: CurveFactory): Link_[This, LinkDatum, NodeDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("link")(curve.asInstanceOf[js.Any]).asInstanceOf[Link_[This, LinkDatum, NodeDatum]]
  
  @scala.inline
  def local[T](): Local_[T] = ^.asInstanceOf[js.Dynamic].applyDynamic("local")().asInstanceOf[Local_[T]]
  
  @scala.inline
  def map[T, U](
    iterable: js.Iterable[T],
    mapper: js.Function3[/* value */ T, /* index */ Double, /* iterable */ js.Iterable[T], U]
  ): js.Array[U] = (^.asInstanceOf[js.Dynamic].applyDynamic("map")(iterable.asInstanceOf[js.Any], mapper.asInstanceOf[js.Any])).asInstanceOf[js.Array[U]]
  
  @scala.inline
  def matcher(selector: String): js.ThisFunction0[/* this */ BaseType, Boolean] = ^.asInstanceOf[js.Dynamic].applyDynamic("matcher")(selector.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, Boolean]]
  
  @scala.inline
  def max(iterable: js.Iterable[String]): js.UndefOr[String] = ^.asInstanceOf[js.Dynamic].applyDynamic("max")(iterable.asInstanceOf[js.Any]).asInstanceOf[js.UndefOr[String]]
  @scala.inline
  def max[T](
    iterable: js.Iterable[T],
    accessor: js.Function3[
      /* datum */ T, 
      /* index */ Double, 
      /* array */ js.Iterable[T], 
      js.UndefOr[String | Null]
    ]
  ): js.UndefOr[String] = (^.asInstanceOf[js.Dynamic].applyDynamic("max")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[js.UndefOr[String]]
  
  @scala.inline
  def maxIndex(iterable: js.Iterable[Any]): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("maxIndex")(iterable.asInstanceOf[js.Any]).asInstanceOf[Double]
  @scala.inline
  def maxIndex[TDatum](
    iterable: js.Iterable[TDatum],
    accessor: js.Function3[/* datum */ TDatum, /* index */ Double, /* array */ js.Iterable[TDatum], Any]
  ): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("maxIndex")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[Double]
  
  @scala.inline
  def max_TU_Numeric[T, U /* <: Numeric */](
    iterable: js.Iterable[T],
    accessor: js.Function3[/* datum */ T, /* index */ Double, /* array */ js.Iterable[T], js.UndefOr[U | Null]]
  ): js.UndefOr[U] = (^.asInstanceOf[js.Dynamic].applyDynamic("max")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[js.UndefOr[U]]
  
  @scala.inline
  def max_T_Numeric[T /* <: Numeric */](iterable: js.Iterable[T]): js.UndefOr[T] = ^.asInstanceOf[js.Dynamic].applyDynamic("max")(iterable.asInstanceOf[js.Any]).asInstanceOf[js.UndefOr[T]]
  
  @scala.inline
  def mean(iterable: js.Iterable[js.UndefOr[Numeric | Null]]): js.UndefOr[Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("mean")(iterable.asInstanceOf[js.Any]).asInstanceOf[js.UndefOr[Double]]
  @scala.inline
  def mean[T](
    iterable: js.Iterable[T],
    accessor: js.Function3[
      /* datum */ T, 
      /* index */ Double, 
      /* array */ js.Iterable[T], 
      js.UndefOr[Double | Null]
    ]
  ): js.UndefOr[Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("mean")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[js.UndefOr[Double]]
  
  @scala.inline
  def median(iterable: js.Iterable[js.UndefOr[Numeric | Null]]): js.UndefOr[Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("median")(iterable.asInstanceOf[js.Any]).asInstanceOf[js.UndefOr[Double]]
  @scala.inline
  def median[T](
    iterable: js.Iterable[T],
    accessor: js.Function3[/* element */ T, /* i */ Double, /* array */ js.Iterable[T], js.UndefOr[Double | Null]]
  ): js.UndefOr[Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("median")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[js.UndefOr[Double]]
  
  @scala.inline
  def merge[T](iterables: js.Iterable[js.Iterable[T]]): js.Array[T] = ^.asInstanceOf[js.Dynamic].applyDynamic("merge")(iterables.asInstanceOf[js.Any]).asInstanceOf[js.Array[T]]
  
  @scala.inline
  def min(iterable: js.Iterable[String]): js.UndefOr[String] = ^.asInstanceOf[js.Dynamic].applyDynamic("min")(iterable.asInstanceOf[js.Any]).asInstanceOf[js.UndefOr[String]]
  @scala.inline
  def min[T](
    iterable: js.Iterable[T],
    accessor: js.Function3[
      /* datum */ T, 
      /* index */ Double, 
      /* array */ js.Iterable[T], 
      js.UndefOr[String | Null]
    ]
  ): js.UndefOr[String] = (^.asInstanceOf[js.Dynamic].applyDynamic("min")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[js.UndefOr[String]]
  
  @scala.inline
  def minIndex(iterable: js.Iterable[Any]): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("minIndex")(iterable.asInstanceOf[js.Any]).asInstanceOf[Double]
  @scala.inline
  def minIndex[TDatum](
    iterable: js.Iterable[TDatum],
    accessor: js.Function3[/* datum */ TDatum, /* index */ Double, /* array */ js.Iterable[TDatum], Any]
  ): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("minIndex")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[Double]
  
  @scala.inline
  def min_TU_Numeric[T, U /* <: Numeric */](
    iterable: js.Iterable[T],
    accessor: js.Function3[/* datum */ T, /* index */ Double, /* array */ js.Iterable[T], js.UndefOr[U | Null]]
  ): js.UndefOr[U] = (^.asInstanceOf[js.Dynamic].applyDynamic("min")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[js.UndefOr[U]]
  
  @scala.inline
  def min_T_Numeric[T /* <: Numeric */](iterable: js.Iterable[T]): js.UndefOr[T] = ^.asInstanceOf[js.Dynamic].applyDynamic("min")(iterable.asInstanceOf[js.Any]).asInstanceOf[js.UndefOr[T]]
  
  @scala.inline
  def mode(iterable: js.Iterable[js.UndefOr[Numeric | Null]]): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("mode")(iterable.asInstanceOf[js.Any]).asInstanceOf[Double]
  @scala.inline
  def mode[T](
    iterable: js.Iterable[T],
    accessor: js.Function3[
      /* datum */ T, 
      /* index */ Double, 
      /* array */ js.Iterable[T], 
      js.UndefOr[Double | Null]
    ]
  ): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("mode")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[Double]
  
  @scala.inline
  def namespace(prefixedLocal: String): NamespaceLocalObject | String = ^.asInstanceOf[js.Dynamic].applyDynamic("namespace")(prefixedLocal.asInstanceOf[js.Any]).asInstanceOf[NamespaceLocalObject | String]
  
  @JSImport("d3", "namespaces")
  @js.native
  val namespaces: NamespaceMap = js.native
  
  @scala.inline
  def nice(start: Double, stop: Double, count: Double): js.Tuple2[Double, Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("nice")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], count.asInstanceOf[js.Any])).asInstanceOf[js.Tuple2[Double, Double]]
  
  @scala.inline
  def now(): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("now")().asInstanceOf[Double]
  
  @scala.inline
  def pack[Datum](): PackLayout[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("pack")().asInstanceOf[PackLayout[Datum]]
  
  @scala.inline
  def packEnclose[Datum /* <: PackCircle */](circles: js.Array[Datum]): PackCircle = ^.asInstanceOf[js.Dynamic].applyDynamic("packEnclose")(circles.asInstanceOf[js.Any]).asInstanceOf[PackCircle]
  
  @scala.inline
  def packSiblings[Datum /* <: PackRadius */](circles: js.Array[Datum]): js.Array[Datum with PackCircle] = ^.asInstanceOf[js.Dynamic].applyDynamic("packSiblings")(circles.asInstanceOf[js.Any]).asInstanceOf[js.Array[Datum with PackCircle]]
  
  @scala.inline
  def pairs[T](iterable: js.Iterable[T]): js.Array[js.Tuple2[T, T]] = ^.asInstanceOf[js.Dynamic].applyDynamic("pairs")(iterable.asInstanceOf[js.Any]).asInstanceOf[js.Array[js.Tuple2[T, T]]]
  @scala.inline
  def pairs[T, U](iterable: js.Iterable[T], reducer: js.Function2[/* a */ T, /* b */ T, U]): js.Array[U] = (^.asInstanceOf[js.Dynamic].applyDynamic("pairs")(iterable.asInstanceOf[js.Any], reducer.asInstanceOf[js.Any])).asInstanceOf[js.Array[U]]
  
  @scala.inline
  def partition[Datum](): PartitionLayout[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("partition")().asInstanceOf[PartitionLayout[Datum]]
  
  @scala.inline
  def path(): Path_ = ^.asInstanceOf[js.Dynamic].applyDynamic("path")().asInstanceOf[Path_]
  
  @scala.inline
  def permute[T](source: NumberDictionary[T], keys: js.Iterable[Double]): js.Array[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("permute")(source.asInstanceOf[js.Any], keys.asInstanceOf[js.Any])).asInstanceOf[js.Array[T]]
  @scala.inline
  def permute[T, K /* <: /* keyof T */ String */](source: T, keys: js.Iterable[K]): js.Array[
    /* import warning: importer.ImportType#apply Failed type conversion: T[K] */ js.Any
  ] = (^.asInstanceOf[js.Dynamic].applyDynamic("permute")(source.asInstanceOf[js.Any], keys.asInstanceOf[js.Any])).asInstanceOf[js.Array[
    /* import warning: importer.ImportType#apply Failed type conversion: T[K] */ js.Any
  ]]
  
  @scala.inline
  def pie(): Pie_[Any, Double | typings.d3Shape.anon.ValueOf] = ^.asInstanceOf[js.Dynamic].applyDynamic("pie")().asInstanceOf[Pie_[Any, Double | typings.d3Shape.anon.ValueOf]]
  
  @scala.inline
  def pie_Datum[Datum](): Pie_[Any, Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("pie")().asInstanceOf[Pie_[Any, Datum]]
  
  @scala.inline
  def pie_ThisDatum[This, Datum](): Pie_[This, Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("pie")().asInstanceOf[Pie_[This, Datum]]
  
  @scala.inline
  def piecewise(
    interpolate: js.Function2[/* a */ ZoomView, /* b */ ZoomView, ZoomInterpolator],
    values: js.Array[ZoomView]
  ): ZoomInterpolator = (^.asInstanceOf[js.Dynamic].applyDynamic("piecewise")(interpolate.asInstanceOf[js.Any], values.asInstanceOf[js.Any])).asInstanceOf[ZoomInterpolator]
  @scala.inline
  def piecewise(values: js.Array[Any]): js.Function1[/* t */ Double, Any] = ^.asInstanceOf[js.Dynamic].applyDynamic("piecewise")(values.asInstanceOf[js.Any]).asInstanceOf[js.Function1[/* t */ Double, Any]]
  
  @scala.inline
  def piecewise_A_ArrayAny_ArrayInterpolator[A /* <: js.Array[Any] */](
    interpolate: js.Function2[/* a */ js.Array[Any], /* b */ A, ArrayInterpolator[A]],
    values: js.Array[A]
  ): ArrayInterpolator[A] = (^.asInstanceOf[js.Dynamic].applyDynamic("piecewise")(interpolate.asInstanceOf[js.Any], values.asInstanceOf[js.Any])).asInstanceOf[ArrayInterpolator[A]]
  @scala.inline
  def piecewise_A_ArrayAny_ArrayInterpolator[A /* <: js.Array[Any] */](values: js.Array[A]): ArrayInterpolator[A] = ^.asInstanceOf[js.Dynamic].applyDynamic("piecewise")(values.asInstanceOf[js.Any]).asInstanceOf[ArrayInterpolator[A]]
  
  @scala.inline
  def piecewise_TData_Function1[TData](interpolate: js.Function2[/* a */ TData, /* b */ TData, Any], values: js.Array[TData]): js.Function1[/* t */ Double, Any] = (^.asInstanceOf[js.Dynamic].applyDynamic("piecewise")(interpolate.asInstanceOf[js.Any], values.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* t */ Double, Any]]
  
  @scala.inline
  def piecewise_ZoomInterpolator(values: js.Array[ZoomView]): ZoomInterpolator = ^.asInstanceOf[js.Dynamic].applyDynamic("piecewise")(values.asInstanceOf[js.Any]).asInstanceOf[ZoomInterpolator]
  
  @scala.inline
  def pointRadial(angle: Double, radius: Double): js.Tuple2[Double, Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("pointRadial")(angle.asInstanceOf[js.Any], radius.asInstanceOf[js.Any])).asInstanceOf[js.Tuple2[Double, Double]]
  
  @scala.inline
  def pointer(event: Any): js.Tuple2[Double, Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("pointer")(event.asInstanceOf[js.Any]).asInstanceOf[js.Tuple2[Double, Double]]
  @scala.inline
  def pointer(event: Any, target: Any): js.Tuple2[Double, Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("pointer")(event.asInstanceOf[js.Any], target.asInstanceOf[js.Any])).asInstanceOf[js.Tuple2[Double, Double]]
  
  @scala.inline
  def pointers(event: Any): js.Array[js.Tuple2[Double, Double]] = ^.asInstanceOf[js.Dynamic].applyDynamic("pointers")(event.asInstanceOf[js.Any]).asInstanceOf[js.Array[js.Tuple2[Double, Double]]]
  @scala.inline
  def pointers(event: Any, target: Any): js.Array[js.Tuple2[Double, Double]] = (^.asInstanceOf[js.Dynamic].applyDynamic("pointers")(event.asInstanceOf[js.Any], target.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Tuple2[Double, Double]]]
  
  @scala.inline
  def polygonArea(polygon: js.Array[js.Tuple2[Double, Double]]): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("polygonArea")(polygon.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def polygonCentroid(polygon: js.Array[js.Tuple2[Double, Double]]): js.Tuple2[Double, Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("polygonCentroid")(polygon.asInstanceOf[js.Any]).asInstanceOf[js.Tuple2[Double, Double]]
  
  @scala.inline
  def polygonContains(polygon: js.Array[js.Tuple2[Double, Double]], point: js.Tuple2[Double, Double]): Boolean = (^.asInstanceOf[js.Dynamic].applyDynamic("polygonContains")(polygon.asInstanceOf[js.Any], point.asInstanceOf[js.Any])).asInstanceOf[Boolean]
  
  @scala.inline
  def polygonHull(points: js.Array[js.Tuple2[Double, Double]]): (js.Array[js.Tuple2[Double, Double]]) | Null = ^.asInstanceOf[js.Dynamic].applyDynamic("polygonHull")(points.asInstanceOf[js.Any]).asInstanceOf[(js.Array[js.Tuple2[Double, Double]]) | Null]
  
  @scala.inline
  def polygonLength(polygon: js.Array[js.Tuple2[Double, Double]]): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("polygonLength")(polygon.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def precisionFixed(step: Double): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("precisionFixed")(step.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def precisionPrefix(step: Double, value: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("precisionPrefix")(step.asInstanceOf[js.Any], value.asInstanceOf[js.Any])).asInstanceOf[Double]
  
  @scala.inline
  def precisionRound(step: Double, max: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("precisionRound")(step.asInstanceOf[js.Any], max.asInstanceOf[js.Any])).asInstanceOf[Double]
  
  @scala.inline
  def quadtree[T](): Quadtree_[T] = ^.asInstanceOf[js.Dynamic].applyDynamic("quadtree")().asInstanceOf[Quadtree_[T]]
  @scala.inline
  def quadtree[T](data: js.Array[T]): Quadtree_[T] = ^.asInstanceOf[js.Dynamic].applyDynamic("quadtree")(data.asInstanceOf[js.Any]).asInstanceOf[Quadtree_[T]]
  @scala.inline
  def quadtree[T](data: js.Array[T], x: js.Function1[/* d */ T, Double], y: js.Function1[/* d */ T, Double]): Quadtree_[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("quadtree")(data.asInstanceOf[js.Any], x.asInstanceOf[js.Any], y.asInstanceOf[js.Any])).asInstanceOf[Quadtree_[T]]
  
  @scala.inline
  def quantile(iterable: js.Iterable[js.UndefOr[Numeric | Null]], p: Double): js.UndefOr[Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("quantile")(iterable.asInstanceOf[js.Any], p.asInstanceOf[js.Any])).asInstanceOf[js.UndefOr[Double]]
  @scala.inline
  def quantile[T](
    iterable: js.Iterable[T],
    p: Double,
    accessor: js.Function3[/* element */ T, /* i */ Double, /* array */ js.Iterable[T], js.UndefOr[Double | Null]]
  ): js.UndefOr[Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("quantile")(iterable.asInstanceOf[js.Any], p.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[js.UndefOr[Double]]
  
  @scala.inline
  def quantileSorted(array: js.Array[js.UndefOr[Numeric | Null]], p: Double): js.UndefOr[Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("quantileSorted")(array.asInstanceOf[js.Any], p.asInstanceOf[js.Any])).asInstanceOf[js.UndefOr[Double]]
  @scala.inline
  def quantileSorted[T](
    array: js.Array[T],
    p: Double,
    accessor: js.Function3[/* element */ T, /* i */ Double, /* array */ js.Array[T], js.UndefOr[Double | Null]]
  ): js.UndefOr[Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("quantileSorted")(array.asInstanceOf[js.Any], p.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[js.UndefOr[Double]]
  
  @scala.inline
  def quantize[T](interpolator: js.Function1[/* t */ Double, T], n: Double): js.Array[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("quantize")(interpolator.asInstanceOf[js.Any], n.asInstanceOf[js.Any])).asInstanceOf[js.Array[T]]
  
  @scala.inline
  def quickselect[T](array: ArrayLike[T], k: Double): js.Array[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("quickselect")(array.asInstanceOf[js.Any], k.asInstanceOf[js.Any])).asInstanceOf[js.Array[T]]
  @scala.inline
  def quickselect[T](array: ArrayLike[T], k: Double, left: Double): js.Array[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("quickselect")(array.asInstanceOf[js.Any], k.asInstanceOf[js.Any], left.asInstanceOf[js.Any])).asInstanceOf[js.Array[T]]
  @scala.inline
  def quickselect[T](array: ArrayLike[T], k: Double, left: Double, right: Double): js.Array[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("quickselect")(array.asInstanceOf[js.Any], k.asInstanceOf[js.Any], left.asInstanceOf[js.Any], right.asInstanceOf[js.Any])).asInstanceOf[js.Array[T]]
  @scala.inline
  def quickselect[T](
    array: ArrayLike[T],
    k: Double,
    left: Double,
    right: Double,
    compare: js.Function2[/* a */ js.UndefOr[Primitive], /* b */ js.UndefOr[Primitive], Double]
  ): js.Array[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("quickselect")(array.asInstanceOf[js.Any], k.asInstanceOf[js.Any], left.asInstanceOf[js.Any], right.asInstanceOf[js.Any], compare.asInstanceOf[js.Any])).asInstanceOf[js.Array[T]]
  @scala.inline
  def quickselect[T](
    array: ArrayLike[T],
    k: Double,
    left: Double,
    right: Unit,
    compare: js.Function2[/* a */ js.UndefOr[Primitive], /* b */ js.UndefOr[Primitive], Double]
  ): js.Array[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("quickselect")(array.asInstanceOf[js.Any], k.asInstanceOf[js.Any], left.asInstanceOf[js.Any], right.asInstanceOf[js.Any], compare.asInstanceOf[js.Any])).asInstanceOf[js.Array[T]]
  @scala.inline
  def quickselect[T](array: ArrayLike[T], k: Double, left: Unit, right: Double): js.Array[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("quickselect")(array.asInstanceOf[js.Any], k.asInstanceOf[js.Any], left.asInstanceOf[js.Any], right.asInstanceOf[js.Any])).asInstanceOf[js.Array[T]]
  @scala.inline
  def quickselect[T](
    array: ArrayLike[T],
    k: Double,
    left: Unit,
    right: Double,
    compare: js.Function2[/* a */ js.UndefOr[Primitive], /* b */ js.UndefOr[Primitive], Double]
  ): js.Array[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("quickselect")(array.asInstanceOf[js.Any], k.asInstanceOf[js.Any], left.asInstanceOf[js.Any], right.asInstanceOf[js.Any], compare.asInstanceOf[js.Any])).asInstanceOf[js.Array[T]]
  @scala.inline
  def quickselect[T](
    array: ArrayLike[T],
    k: Double,
    left: Unit,
    right: Unit,
    compare: js.Function2[/* a */ js.UndefOr[Primitive], /* b */ js.UndefOr[Primitive], Double]
  ): js.Array[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("quickselect")(array.asInstanceOf[js.Any], k.asInstanceOf[js.Any], left.asInstanceOf[js.Any], right.asInstanceOf[js.Any], compare.asInstanceOf[js.Any])).asInstanceOf[js.Array[T]]
  
  @scala.inline
  def radialArea(): RadialArea_[js.Tuple2[Double, Double]] = ^.asInstanceOf[js.Dynamic].applyDynamic("radialArea")().asInstanceOf[RadialArea_[js.Tuple2[Double, Double]]]
  
  @scala.inline
  def radialArea_Datum[Datum](): RadialArea_[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("radialArea")().asInstanceOf[RadialArea_[Datum]]
  
  @scala.inline
  def radialLine(): RadialLine_[js.Tuple2[Double, Double]] = ^.asInstanceOf[js.Dynamic].applyDynamic("radialLine")().asInstanceOf[RadialLine_[js.Tuple2[Double, Double]]]
  
  @scala.inline
  def radialLine_Datum[Datum](): RadialLine_[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("radialLine")().asInstanceOf[RadialLine_[Datum]]
  
  @JSImport("d3", "randomBates")
  @js.native
  val randomBates: RandomBates_ = js.native
  
  @JSImport("d3", "randomBernoulli")
  @js.native
  val randomBernoulli: RandomBernoulli_ = js.native
  
  @JSImport("d3", "randomBeta")
  @js.native
  val randomBeta: RandomBeta_ = js.native
  
  @JSImport("d3", "randomBinomial")
  @js.native
  val randomBinomial: RandomBinomial_ = js.native
  
  @JSImport("d3", "randomCauchy")
  @js.native
  val randomCauchy: RandomCauchy_ = js.native
  
  @JSImport("d3", "randomExponential")
  @js.native
  val randomExponential: RandomExponential_ = js.native
  
  @JSImport("d3", "randomGamma")
  @js.native
  val randomGamma: RandomGamma_ = js.native
  
  @JSImport("d3", "randomGeometric")
  @js.native
  val randomGeometric: RandomGeometric_ = js.native
  
  @JSImport("d3", "randomInt")
  @js.native
  val randomInt: RandomInt_ = js.native
  
  @JSImport("d3", "randomIrwinHall")
  @js.native
  val randomIrwinHall: RandomIrwinHall_ = js.native
  
  @scala.inline
  def randomLcg(): js.Function0[Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("randomLcg")().asInstanceOf[js.Function0[Double]]
  @scala.inline
  def randomLcg(seed: Double): js.Function0[Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("randomLcg")(seed.asInstanceOf[js.Any]).asInstanceOf[js.Function0[Double]]
  
  @JSImport("d3", "randomLogNormal")
  @js.native
  val randomLogNormal: RandomLogNormal_ = js.native
  
  @JSImport("d3", "randomLogistic")
  @js.native
  val randomLogistic: RandomLogistic_ = js.native
  
  @JSImport("d3", "randomNormal")
  @js.native
  val randomNormal: RandomNormal_ = js.native
  
  @JSImport("d3", "randomPareto")
  @js.native
  val randomPareto: RandomPareto_ = js.native
  
  @JSImport("d3", "randomPoisson")
  @js.native
  val randomPoisson: RandomPoisson_ = js.native
  
  @JSImport("d3", "randomUniform")
  @js.native
  val randomUniform: RandomUniform_ = js.native
  
  @JSImport("d3", "randomWeibull")
  @js.native
  val randomWeibull: RandomWeibull_ = js.native
  
  @scala.inline
  def range(start: Double, stop: Double): js.Array[Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("range")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[Double]]
  @scala.inline
  def range(start: Double, stop: Double, step: Double): js.Array[Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("range")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[Double]]
  @scala.inline
  def range(stop: Double): js.Array[Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("range")(stop.asInstanceOf[js.Any]).asInstanceOf[js.Array[Double]]
  
  @scala.inline
  def rank(iterable: js.Iterable[js.UndefOr[Numeric | Null]]): js.typedarray.Float64Array = ^.asInstanceOf[js.Dynamic].applyDynamic("rank")(iterable.asInstanceOf[js.Any]).asInstanceOf[js.typedarray.Float64Array]
  @scala.inline
  def rank[T](
    iterable: js.Iterable[T],
    accessorOrComparator: js.Function2[/* a */ T, /* b */ T, js.UndefOr[Double | Null]]
  ): js.typedarray.Float64Array = (^.asInstanceOf[js.Dynamic].applyDynamic("rank")(iterable.asInstanceOf[js.Any], accessorOrComparator.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Float64Array]
  @scala.inline
  def rank[T](
    iterable: js.Iterable[T],
    accessorOrComparator: js.Function3[
      /* datum */ T, 
      /* index */ Double, 
      /* array */ js.Iterable[T], 
      js.UndefOr[Double | Null]
    ]
  ): js.typedarray.Float64Array = (^.asInstanceOf[js.Dynamic].applyDynamic("rank")(iterable.asInstanceOf[js.Any], accessorOrComparator.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Float64Array]
  
  @scala.inline
  def reduce[T](
    iterable: js.Iterable[T],
    reducer: js.Function4[
      /* previousValue */ T, 
      /* currentValue */ T, 
      /* currentIndex */ Double, 
      /* iterable */ js.Iterable[T], 
      T
    ]
  ): T = (^.asInstanceOf[js.Dynamic].applyDynamic("reduce")(iterable.asInstanceOf[js.Any], reducer.asInstanceOf[js.Any])).asInstanceOf[T]
  @scala.inline
  def reduce[T](
    iterable: js.Iterable[T],
    reducer: js.Function4[
      /* previousValue */ T, 
      /* currentValue */ T, 
      /* currentIndex */ Double, 
      /* iterable */ js.Iterable[T], 
      T
    ],
    initialValue: T
  ): T = (^.asInstanceOf[js.Dynamic].applyDynamic("reduce")(iterable.asInstanceOf[js.Any], reducer.asInstanceOf[js.Any], initialValue.asInstanceOf[js.Any])).asInstanceOf[T]
  
  @scala.inline
  def reduce_TU_U[T, U](
    iterable: js.Iterable[T],
    reducer: js.Function4[
      /* previousValue */ U, 
      /* currentValue */ T, 
      /* currentIndex */ Double, 
      /* iterable */ js.Iterable[T], 
      U
    ],
    initialValue: U
  ): U = (^.asInstanceOf[js.Dynamic].applyDynamic("reduce")(iterable.asInstanceOf[js.Any], reducer.asInstanceOf[js.Any], initialValue.asInstanceOf[js.Any])).asInstanceOf[U]
  
  @scala.inline
  def reverse[T](iterable: js.Iterable[T]): js.Array[T] = ^.asInstanceOf[js.Dynamic].applyDynamic("reverse")(iterable.asInstanceOf[js.Any]).asInstanceOf[js.Array[T]]
  
  @JSImport("d3", "rgb")
  @js.native
  val rgb: RGBColorFactory = js.native
  
  @scala.inline
  def ribbon(): RibbonGenerator[Any, Ribbon_, RibbonSubgroup] = ^.asInstanceOf[js.Dynamic].applyDynamic("ribbon")().asInstanceOf[RibbonGenerator[Any, Ribbon_, RibbonSubgroup]]
  
  @scala.inline
  def ribbonArrow(): RibbonArrowGenerator[Any, Ribbon_, RibbonSubgroup] = ^.asInstanceOf[js.Dynamic].applyDynamic("ribbonArrow")().asInstanceOf[RibbonArrowGenerator[Any, Ribbon_, RibbonSubgroup]]
  
  @scala.inline
  def ribbonArrow_DatumSubgroupDatum[Datum, SubgroupDatum](): RibbonArrowGenerator[Any, Datum, SubgroupDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("ribbonArrow")().asInstanceOf[RibbonArrowGenerator[Any, Datum, SubgroupDatum]]
  
  @scala.inline
  def ribbonArrow_ThisDatumSubgroupDatum[This, Datum, SubgroupDatum](): RibbonArrowGenerator[This, Datum, SubgroupDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("ribbonArrow")().asInstanceOf[RibbonArrowGenerator[This, Datum, SubgroupDatum]]
  
  @scala.inline
  def ribbon_DatumSubgroupDatum[Datum, SubgroupDatum](): RibbonGenerator[Any, Datum, SubgroupDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("ribbon")().asInstanceOf[RibbonGenerator[Any, Datum, SubgroupDatum]]
  
  @scala.inline
  def ribbon_ThisDatumSubgroupDatum[This, Datum, SubgroupDatum](): RibbonGenerator[This, Datum, SubgroupDatum] = ^.asInstanceOf[js.Dynamic].applyDynamic("ribbon")().asInstanceOf[RibbonGenerator[This, Datum, SubgroupDatum]]
  
  @scala.inline
  def rollup[TObject, TReduce, TKey](
    iterable: js.Iterable[TObject],
    reduce: js.Function1[/* value */ js.Array[TObject], TReduce],
    key: js.Function1[/* value */ TObject, TKey]
  ): typings.d3Array.mod.InternMap[TKey, TReduce] = (^.asInstanceOf[js.Dynamic].applyDynamic("rollup")(iterable.asInstanceOf[js.Any], reduce.asInstanceOf[js.Any], key.asInstanceOf[js.Any])).asInstanceOf[typings.d3Array.mod.InternMap[TKey, TReduce]]
  @scala.inline
  def rollup[TObject, TReduce, TKey1, TKey2](
    iterable: js.Iterable[TObject],
    reduce: js.Function1[/* value */ js.Array[TObject], TReduce],
    key1: js.Function1[/* value */ TObject, TKey1],
    key2: js.Function1[/* value */ TObject, TKey2]
  ): typings.d3Array.mod.InternMap[TKey1, typings.d3Array.mod.InternMap[TKey2, TReduce]] = (^.asInstanceOf[js.Dynamic].applyDynamic("rollup")(iterable.asInstanceOf[js.Any], reduce.asInstanceOf[js.Any], key1.asInstanceOf[js.Any], key2.asInstanceOf[js.Any])).asInstanceOf[typings.d3Array.mod.InternMap[TKey1, typings.d3Array.mod.InternMap[TKey2, TReduce]]]
  @scala.inline
  def rollup[TObject, TReduce, TKey1, TKey2, TKey3](
    iterable: js.Iterable[TObject],
    reduce: js.Function1[/* value */ js.Array[TObject], TReduce],
    key1: js.Function1[/* value */ TObject, TKey1],
    key2: js.Function1[/* value */ TObject, TKey2],
    key3: js.Function1[/* value */ TObject, TKey3]
  ): typings.d3Array.mod.InternMap[
    TKey1, 
    typings.d3Array.mod.InternMap[TKey2, typings.d3Array.mod.InternMap[TKey3, TReduce]]
  ] = (^.asInstanceOf[js.Dynamic].applyDynamic("rollup")(iterable.asInstanceOf[js.Any], reduce.asInstanceOf[js.Any], key1.asInstanceOf[js.Any], key2.asInstanceOf[js.Any], key3.asInstanceOf[js.Any])).asInstanceOf[typings.d3Array.mod.InternMap[
    TKey1, 
    typings.d3Array.mod.InternMap[TKey2, typings.d3Array.mod.InternMap[TKey3, TReduce]]
  ]]
  
  @scala.inline
  def rollups[TObject, TReduce, TKey](
    iterable: js.Iterable[TObject],
    reduce: js.Function1[/* value */ js.Array[TObject], TReduce],
    key: js.Function1[/* value */ TObject, TKey]
  ): js.Array[js.Tuple2[TKey, TReduce]] = (^.asInstanceOf[js.Dynamic].applyDynamic("rollups")(iterable.asInstanceOf[js.Any], reduce.asInstanceOf[js.Any], key.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Tuple2[TKey, TReduce]]]
  @scala.inline
  def rollups[TObject, TReduce, TKey1, TKey2](
    iterable: js.Iterable[TObject],
    reduce: js.Function1[/* value */ js.Array[TObject], TReduce],
    key1: js.Function1[/* value */ TObject, TKey1],
    key2: js.Function1[/* value */ TObject, TKey2]
  ): js.Array[js.Tuple2[TKey1, js.Array[js.Tuple2[TKey2, TReduce]]]] = (^.asInstanceOf[js.Dynamic].applyDynamic("rollups")(iterable.asInstanceOf[js.Any], reduce.asInstanceOf[js.Any], key1.asInstanceOf[js.Any], key2.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Tuple2[TKey1, js.Array[js.Tuple2[TKey2, TReduce]]]]]
  @scala.inline
  def rollups[TObject, TReduce, TKey1, TKey2, TKey3](
    iterable: js.Iterable[TObject],
    reduce: js.Function1[/* value */ js.Array[TObject], TReduce],
    key1: js.Function1[/* value */ TObject, TKey1],
    key2: js.Function1[/* value */ TObject, TKey2],
    key3: js.Function1[/* value */ TObject, TKey3]
  ): js.Array[
    js.Tuple2[TKey1, js.Array[js.Tuple2[TKey2, js.Array[js.Tuple2[TKey3, TReduce]]]]]
  ] = (^.asInstanceOf[js.Dynamic].applyDynamic("rollups")(iterable.asInstanceOf[js.Any], reduce.asInstanceOf[js.Any], key1.asInstanceOf[js.Any], key2.asInstanceOf[js.Any], key3.asInstanceOf[js.Any])).asInstanceOf[js.Array[
    js.Tuple2[TKey1, js.Array[js.Tuple2[TKey2, js.Array[js.Tuple2[TKey3, TReduce]]]]]
  ]]
  
  @scala.inline
  def scaleBand[Domain /* <: typings.d3Scale.anon.ToString */](): ScaleBand_[Domain] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleBand")().asInstanceOf[ScaleBand_[Domain]]
  @scala.inline
  def scaleBand[Domain /* <: typings.d3Scale.anon.ToString */](domain: js.Iterable[Domain], range: js.Iterable[NumberValue]): ScaleBand_[Domain] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleBand")(domain.asInstanceOf[js.Any], range.asInstanceOf[js.Any])).asInstanceOf[ScaleBand_[Domain]]
  @scala.inline
  def scaleBand[Domain /* <: typings.d3Scale.anon.ToString */](range: js.Iterable[NumberValue]): ScaleBand_[Domain] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleBand")(range.asInstanceOf[js.Any]).asInstanceOf[ScaleBand_[Domain]]
  
  @scala.inline
  def scaleDiverging[Output, Unknown](): ScaleDiverging_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleDiverging")().asInstanceOf[ScaleDiverging_[Output, Unknown]]
  @scala.inline
  def scaleDiverging[Output, Unknown](domain: js.Iterable[NumberValue], interpolator: js.Function1[/* t */ Double, Output]): ScaleDiverging_[Output, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleDiverging")(domain.asInstanceOf[js.Any], interpolator.asInstanceOf[js.Any])).asInstanceOf[ScaleDiverging_[Output, Unknown]]
  @scala.inline
  def scaleDiverging[Output, Unknown](domain: js.Iterable[NumberValue], interpolator: js.Iterable[Output]): ScaleDiverging_[Output, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleDiverging")(domain.asInstanceOf[js.Any], interpolator.asInstanceOf[js.Any])).asInstanceOf[ScaleDiverging_[Output, Unknown]]
  @scala.inline
  def scaleDiverging[Output, Unknown](interpolator: js.Function1[/* t */ Double, Output]): ScaleDiverging_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleDiverging")(interpolator.asInstanceOf[js.Any]).asInstanceOf[ScaleDiverging_[Output, Unknown]]
  @scala.inline
  def scaleDiverging[Output, Unknown](interpolator: js.Iterable[Output]): ScaleDiverging_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleDiverging")(interpolator.asInstanceOf[js.Any]).asInstanceOf[ScaleDiverging_[Output, Unknown]]
  
  @scala.inline
  def scaleDivergingLog[Output, Unknown](): ScaleDiverging_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleDivergingLog")().asInstanceOf[ScaleDiverging_[Output, Unknown]]
  @scala.inline
  def scaleDivergingLog[Output, Unknown](domain: js.Iterable[NumberValue], interpolator: js.Function1[/* t */ Double, Output]): ScaleDiverging_[Output, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleDivergingLog")(domain.asInstanceOf[js.Any], interpolator.asInstanceOf[js.Any])).asInstanceOf[ScaleDiverging_[Output, Unknown]]
  @scala.inline
  def scaleDivergingLog[Output, Unknown](interpolator: js.Function1[/* t */ Double, Output]): ScaleDiverging_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleDivergingLog")(interpolator.asInstanceOf[js.Any]).asInstanceOf[ScaleDiverging_[Output, Unknown]]
  
  @scala.inline
  def scaleDivergingPow[Output, Unknown](): ScaleDiverging_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleDivergingPow")().asInstanceOf[ScaleDiverging_[Output, Unknown]]
  @scala.inline
  def scaleDivergingPow[Output, Unknown](domain: js.Iterable[NumberValue], interpolator: js.Function1[/* t */ Double, Output]): ScaleDiverging_[Output, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleDivergingPow")(domain.asInstanceOf[js.Any], interpolator.asInstanceOf[js.Any])).asInstanceOf[ScaleDiverging_[Output, Unknown]]
  @scala.inline
  def scaleDivergingPow[Output, Unknown](interpolator: js.Function1[/* t */ Double, Output]): ScaleDiverging_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleDivergingPow")(interpolator.asInstanceOf[js.Any]).asInstanceOf[ScaleDiverging_[Output, Unknown]]
  
  @scala.inline
  def scaleDivergingSqrt[Output, Unknown](): ScaleDiverging_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleDivergingSqrt")().asInstanceOf[ScaleDiverging_[Output, Unknown]]
  @scala.inline
  def scaleDivergingSqrt[Output, Unknown](domain: js.Iterable[NumberValue], interpolator: js.Function1[/* t */ Double, Output]): ScaleDiverging_[Output, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleDivergingSqrt")(domain.asInstanceOf[js.Any], interpolator.asInstanceOf[js.Any])).asInstanceOf[ScaleDiverging_[Output, Unknown]]
  @scala.inline
  def scaleDivergingSqrt[Output, Unknown](interpolator: js.Function1[/* t */ Double, Output]): ScaleDiverging_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleDivergingSqrt")(interpolator.asInstanceOf[js.Any]).asInstanceOf[ScaleDiverging_[Output, Unknown]]
  
  @scala.inline
  def scaleDivergingSymlog[Output, Unknown](): ScaleDiverging_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleDivergingSymlog")().asInstanceOf[ScaleDiverging_[Output, Unknown]]
  @scala.inline
  def scaleDivergingSymlog[Output, Unknown](domain: js.Iterable[NumberValue], interpolator: js.Function1[/* t */ Double, Output]): ScaleDiverging_[Output, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleDivergingSymlog")(domain.asInstanceOf[js.Any], interpolator.asInstanceOf[js.Any])).asInstanceOf[ScaleDiverging_[Output, Unknown]]
  @scala.inline
  def scaleDivergingSymlog[Output, Unknown](interpolator: js.Function1[/* t */ Double, Output]): ScaleDiverging_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleDivergingSymlog")(interpolator.asInstanceOf[js.Any]).asInstanceOf[ScaleDiverging_[Output, Unknown]]
  
  @scala.inline
  def scaleIdentity[Unknown](): ScaleIdentity_[Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleIdentity")().asInstanceOf[ScaleIdentity_[Unknown]]
  @scala.inline
  def scaleIdentity[Unknown](range: js.Iterable[NumberValue]): ScaleIdentity_[Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleIdentity")(range.asInstanceOf[js.Any]).asInstanceOf[ScaleIdentity_[Unknown]]
  
  object scaleImplicit {
    
    @JSImport("d3", "scaleImplicit")
    @js.native
    val ^ : js.Any = js.native
    
    @JSImport("d3", "scaleImplicit.name")
    @js.native
    def name: `implicit` = js.native
    @scala.inline
    def name_=(x: `implicit`): Unit = ^.asInstanceOf[js.Dynamic].updateDynamic("name")(x.asInstanceOf[js.Any])
  }
  
  @scala.inline
  def scaleLinear[Range, Output, Unknown](): ScaleLinear_[Range, Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleLinear")().asInstanceOf[ScaleLinear_[Range, Output, Unknown]]
  @scala.inline
  def scaleLinear[Range, Output, Unknown](domain: js.Iterable[NumberValue], range: js.Iterable[Range]): ScaleLinear_[Range, Output, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleLinear")(domain.asInstanceOf[js.Any], range.asInstanceOf[js.Any])).asInstanceOf[ScaleLinear_[Range, Output, Unknown]]
  @scala.inline
  def scaleLinear[Range, Output, Unknown](range: js.Iterable[Range]): ScaleLinear_[Range, Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleLinear")(range.asInstanceOf[js.Any]).asInstanceOf[ScaleLinear_[Range, Output, Unknown]]
  
  @scala.inline
  def scaleLog[Range, Output, Unknown](): ScaleLogarithmic[Range, Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleLog")().asInstanceOf[ScaleLogarithmic[Range, Output, Unknown]]
  @scala.inline
  def scaleLog[Range, Output, Unknown](domain: js.Iterable[NumberValue], range: js.Iterable[Range]): ScaleLogarithmic[Range, Output, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleLog")(domain.asInstanceOf[js.Any], range.asInstanceOf[js.Any])).asInstanceOf[ScaleLogarithmic[Range, Output, Unknown]]
  @scala.inline
  def scaleLog[Range, Output, Unknown](range: js.Iterable[Range]): ScaleLogarithmic[Range, Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleLog")(range.asInstanceOf[js.Any]).asInstanceOf[ScaleLogarithmic[Range, Output, Unknown]]
  
  @scala.inline
  def scaleOrdinal[Range](): ScaleOrdinal_[String, Range, scala.Nothing] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleOrdinal")().asInstanceOf[ScaleOrdinal_[String, Range, scala.Nothing]]
  @scala.inline
  def scaleOrdinal[Range](range: js.Iterable[Range]): ScaleOrdinal_[String, Range, scala.Nothing] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleOrdinal")(range.asInstanceOf[js.Any]).asInstanceOf[ScaleOrdinal_[String, Range, scala.Nothing]]
  @scala.inline
  def scaleOrdinal[Domain /* <: typings.d3Scale.anon.ToString */, Range, Unknown](domain: js.Iterable[Domain], range: js.Iterable[Range]): ScaleOrdinal_[Domain, Range, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleOrdinal")(domain.asInstanceOf[js.Any], range.asInstanceOf[js.Any])).asInstanceOf[ScaleOrdinal_[Domain, Range, Unknown]]
  
  @scala.inline
  def scaleOrdinal_Domain_ToStringRangeUnknown[Domain /* <: typings.d3Scale.anon.ToString */, Range, Unknown](): ScaleOrdinal_[Domain, Range, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleOrdinal")().asInstanceOf[ScaleOrdinal_[Domain, Range, Unknown]]
  @scala.inline
  def scaleOrdinal_Domain_ToStringRangeUnknown[Domain /* <: typings.d3Scale.anon.ToString */, Range, Unknown](range: js.Iterable[Range]): ScaleOrdinal_[Domain, Range, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleOrdinal")(range.asInstanceOf[js.Any]).asInstanceOf[ScaleOrdinal_[Domain, Range, Unknown]]
  
  @scala.inline
  def scalePoint[Domain /* <: typings.d3Scale.anon.ToString */](): ScalePoint_[Domain] = ^.asInstanceOf[js.Dynamic].applyDynamic("scalePoint")().asInstanceOf[ScalePoint_[Domain]]
  @scala.inline
  def scalePoint[Domain /* <: typings.d3Scale.anon.ToString */](domain: js.Iterable[Domain], range: js.Iterable[NumberValue]): ScalePoint_[Domain] = (^.asInstanceOf[js.Dynamic].applyDynamic("scalePoint")(domain.asInstanceOf[js.Any], range.asInstanceOf[js.Any])).asInstanceOf[ScalePoint_[Domain]]
  @scala.inline
  def scalePoint[Domain /* <: typings.d3Scale.anon.ToString */](range: js.Iterable[NumberValue]): ScalePoint_[Domain] = ^.asInstanceOf[js.Dynamic].applyDynamic("scalePoint")(range.asInstanceOf[js.Any]).asInstanceOf[ScalePoint_[Domain]]
  
  @scala.inline
  def scalePow[Range, Output, Unknown](): ScalePower[Range, Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scalePow")().asInstanceOf[ScalePower[Range, Output, Unknown]]
  @scala.inline
  def scalePow[Range, Output, Unknown](domain: js.Iterable[NumberValue], range: js.Iterable[Range]): ScalePower[Range, Output, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scalePow")(domain.asInstanceOf[js.Any], range.asInstanceOf[js.Any])).asInstanceOf[ScalePower[Range, Output, Unknown]]
  @scala.inline
  def scalePow[Range, Output, Unknown](range: js.Iterable[Range]): ScalePower[Range, Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scalePow")(range.asInstanceOf[js.Any]).asInstanceOf[ScalePower[Range, Output, Unknown]]
  
  @scala.inline
  def scaleQuantile[Range, Unknown](): ScaleQuantile_[Range, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleQuantile")().asInstanceOf[ScaleQuantile_[Range, Unknown]]
  @scala.inline
  def scaleQuantile[Range, Unknown](domain: js.Iterable[js.UndefOr[NumberValue | Null]], range: js.Iterable[Range]): ScaleQuantile_[Range, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleQuantile")(domain.asInstanceOf[js.Any], range.asInstanceOf[js.Any])).asInstanceOf[ScaleQuantile_[Range, Unknown]]
  @scala.inline
  def scaleQuantile[Range, Unknown](range: js.Iterable[Range]): ScaleQuantile_[Range, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleQuantile")(range.asInstanceOf[js.Any]).asInstanceOf[ScaleQuantile_[Range, Unknown]]
  
  @scala.inline
  def scaleQuantize[Range, Unknown](): ScaleQuantize_[Range, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleQuantize")().asInstanceOf[ScaleQuantize_[Range, Unknown]]
  @scala.inline
  def scaleQuantize[Range, Unknown](domain: js.Iterable[NumberValue], range: js.Iterable[Range]): ScaleQuantize_[Range, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleQuantize")(domain.asInstanceOf[js.Any], range.asInstanceOf[js.Any])).asInstanceOf[ScaleQuantize_[Range, Unknown]]
  @scala.inline
  def scaleQuantize[Range, Unknown](range: js.Iterable[Range]): ScaleQuantize_[Range, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleQuantize")(range.asInstanceOf[js.Any]).asInstanceOf[ScaleQuantize_[Range, Unknown]]
  
  @scala.inline
  def scaleRadial[Range, Unknown](): ScaleRadial_[Range, Range, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleRadial")().asInstanceOf[ScaleRadial_[Range, Range, Unknown]]
  @scala.inline
  def scaleRadial[Range, Unknown](domain: js.Iterable[NumberValue], range: js.Iterable[Range]): ScaleRadial_[Range, Range, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleRadial")(domain.asInstanceOf[js.Any], range.asInstanceOf[js.Any])).asInstanceOf[ScaleRadial_[Range, Range, Unknown]]
  @scala.inline
  def scaleRadial[Range, Unknown](range: js.Iterable[Range]): ScaleRadial_[Range, Range, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleRadial")(range.asInstanceOf[js.Any]).asInstanceOf[ScaleRadial_[Range, Range, Unknown]]
  
  @scala.inline
  def scaleSequential[Output, Unknown](): ScaleSequential_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleSequential")().asInstanceOf[ScaleSequential_[Output, Unknown]]
  @scala.inline
  def scaleSequential[Output, Unknown](domain: js.Iterable[NumberValue], interpolator: js.Function1[/* t */ Double, Output]): ScaleSequential_[Output, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleSequential")(domain.asInstanceOf[js.Any], interpolator.asInstanceOf[js.Any])).asInstanceOf[ScaleSequential_[Output, Unknown]]
  @scala.inline
  def scaleSequential[Output, Unknown](domain: js.Iterable[NumberValue], interpolator: js.Iterable[Output]): ScaleSequential_[Output, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleSequential")(domain.asInstanceOf[js.Any], interpolator.asInstanceOf[js.Any])).asInstanceOf[ScaleSequential_[Output, Unknown]]
  @scala.inline
  def scaleSequential[Output, Unknown](interpolator: js.Function1[/* t */ Double, Output]): ScaleSequential_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleSequential")(interpolator.asInstanceOf[js.Any]).asInstanceOf[ScaleSequential_[Output, Unknown]]
  @scala.inline
  def scaleSequential[Output, Unknown](interpolator: js.Iterable[Output]): ScaleSequential_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleSequential")(interpolator.asInstanceOf[js.Any]).asInstanceOf[ScaleSequential_[Output, Unknown]]
  
  @scala.inline
  def scaleSequentialLog[Output, Unknown](): ScaleSequential_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleSequentialLog")().asInstanceOf[ScaleSequential_[Output, Unknown]]
  @scala.inline
  def scaleSequentialLog[Output, Unknown](domain: js.Iterable[NumberValue], interpolator: js.Function1[/* t */ Double, Output]): ScaleSequential_[Output, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleSequentialLog")(domain.asInstanceOf[js.Any], interpolator.asInstanceOf[js.Any])).asInstanceOf[ScaleSequential_[Output, Unknown]]
  @scala.inline
  def scaleSequentialLog[Output, Unknown](interpolator: js.Function1[/* t */ Double, Output]): ScaleSequential_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleSequentialLog")(interpolator.asInstanceOf[js.Any]).asInstanceOf[ScaleSequential_[Output, Unknown]]
  
  @scala.inline
  def scaleSequentialPow[Output, Unknown](): ScaleSequential_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleSequentialPow")().asInstanceOf[ScaleSequential_[Output, Unknown]]
  @scala.inline
  def scaleSequentialPow[Output, Unknown](domain: js.Iterable[NumberValue], interpolator: js.Function1[/* t */ Double, Output]): ScaleSequential_[Output, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleSequentialPow")(domain.asInstanceOf[js.Any], interpolator.asInstanceOf[js.Any])).asInstanceOf[ScaleSequential_[Output, Unknown]]
  @scala.inline
  def scaleSequentialPow[Output, Unknown](interpolator: js.Function1[/* t */ Double, Output]): ScaleSequential_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleSequentialPow")(interpolator.asInstanceOf[js.Any]).asInstanceOf[ScaleSequential_[Output, Unknown]]
  
  @scala.inline
  def scaleSequentialQuantile[Output, Unknown](): ScaleSequentialQuantile_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleSequentialQuantile")().asInstanceOf[ScaleSequentialQuantile_[Output, Unknown]]
  @scala.inline
  def scaleSequentialQuantile[Output, Unknown](domain: js.Iterable[NumberValue], interpolator: js.Function1[/* t */ Double, Output]): ScaleSequentialQuantile_[Output, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleSequentialQuantile")(domain.asInstanceOf[js.Any], interpolator.asInstanceOf[js.Any])).asInstanceOf[ScaleSequentialQuantile_[Output, Unknown]]
  @scala.inline
  def scaleSequentialQuantile[Output, Unknown](interpolator: js.Function1[/* t */ Double, Output]): ScaleSequentialQuantile_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleSequentialQuantile")(interpolator.asInstanceOf[js.Any]).asInstanceOf[ScaleSequentialQuantile_[Output, Unknown]]
  
  @scala.inline
  def scaleSequentialSqrt[Output, Unknown](): ScaleSequential_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleSequentialSqrt")().asInstanceOf[ScaleSequential_[Output, Unknown]]
  @scala.inline
  def scaleSequentialSqrt[Output, Unknown](domain: js.Iterable[NumberValue], interpolator: js.Function1[/* t */ Double, Output]): ScaleSequential_[Output, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleSequentialSqrt")(domain.asInstanceOf[js.Any], interpolator.asInstanceOf[js.Any])).asInstanceOf[ScaleSequential_[Output, Unknown]]
  @scala.inline
  def scaleSequentialSqrt[Output, Unknown](interpolator: js.Function1[/* t */ Double, Output]): ScaleSequential_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleSequentialSqrt")(interpolator.asInstanceOf[js.Any]).asInstanceOf[ScaleSequential_[Output, Unknown]]
  
  @scala.inline
  def scaleSequentialSymlog[Output, Unknown](): ScaleSequential_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleSequentialSymlog")().asInstanceOf[ScaleSequential_[Output, Unknown]]
  @scala.inline
  def scaleSequentialSymlog[Output, Unknown](domain: js.Iterable[NumberValue], interpolator: js.Function1[/* t */ Double, Output]): ScaleSequential_[Output, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleSequentialSymlog")(domain.asInstanceOf[js.Any], interpolator.asInstanceOf[js.Any])).asInstanceOf[ScaleSequential_[Output, Unknown]]
  @scala.inline
  def scaleSequentialSymlog[Output, Unknown](interpolator: js.Function1[/* t */ Double, Output]): ScaleSequential_[Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleSequentialSymlog")(interpolator.asInstanceOf[js.Any]).asInstanceOf[ScaleSequential_[Output, Unknown]]
  
  @scala.inline
  def scaleSqrt[Range, Output, Unknown](): ScalePower[Range, Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleSqrt")().asInstanceOf[ScalePower[Range, Output, Unknown]]
  @scala.inline
  def scaleSqrt[Range, Output, Unknown](domain: js.Iterable[NumberValue], range: js.Iterable[Range]): ScalePower[Range, Output, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleSqrt")(domain.asInstanceOf[js.Any], range.asInstanceOf[js.Any])).asInstanceOf[ScalePower[Range, Output, Unknown]]
  @scala.inline
  def scaleSqrt[Range, Output, Unknown](range: js.Iterable[Range]): ScalePower[Range, Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleSqrt")(range.asInstanceOf[js.Any]).asInstanceOf[ScalePower[Range, Output, Unknown]]
  
  @scala.inline
  def scaleSymlog[Range, Output, Unknown](): ScaleSymLog_[Range, Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleSymlog")().asInstanceOf[ScaleSymLog_[Range, Output, Unknown]]
  @scala.inline
  def scaleSymlog[Range, Output, Unknown](domain: js.Iterable[NumberValue], range: js.Iterable[Range]): ScaleSymLog_[Range, Output, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleSymlog")(domain.asInstanceOf[js.Any], range.asInstanceOf[js.Any])).asInstanceOf[ScaleSymLog_[Range, Output, Unknown]]
  @scala.inline
  def scaleSymlog[Range, Output, Unknown](range: js.Iterable[Range]): ScaleSymLog_[Range, Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleSymlog")(range.asInstanceOf[js.Any]).asInstanceOf[ScaleSymLog_[Range, Output, Unknown]]
  
  @scala.inline
  def scaleThreshold[Domain /* <: Double | String | js.Date */, Range, Unknown](): ScaleThreshold_[Domain, Range, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleThreshold")().asInstanceOf[ScaleThreshold_[Domain, Range, Unknown]]
  @scala.inline
  def scaleThreshold[Domain /* <: Double | String | js.Date */, Range, Unknown](domain: js.Iterable[Domain], range: js.Iterable[Range]): ScaleThreshold_[Domain, Range, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleThreshold")(domain.asInstanceOf[js.Any], range.asInstanceOf[js.Any])).asInstanceOf[ScaleThreshold_[Domain, Range, Unknown]]
  @scala.inline
  def scaleThreshold[Domain /* <: Double | String | js.Date */, Range, Unknown](range: js.Iterable[Range]): ScaleThreshold_[Domain, Range, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleThreshold")(range.asInstanceOf[js.Any]).asInstanceOf[ScaleThreshold_[Domain, Range, Unknown]]
  
  @scala.inline
  def scaleTime[Range, Output, Unknown](): ScaleTime_[Range, Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleTime")().asInstanceOf[ScaleTime_[Range, Output, Unknown]]
  @scala.inline
  def scaleTime[Range, Output, Unknown](domain: js.Iterable[js.Date | NumberValue], range: js.Iterable[Range]): ScaleTime_[Range, Output, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleTime")(domain.asInstanceOf[js.Any], range.asInstanceOf[js.Any])).asInstanceOf[ScaleTime_[Range, Output, Unknown]]
  @scala.inline
  def scaleTime[Range, Output, Unknown](range: js.Iterable[Range]): ScaleTime_[Range, Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleTime")(range.asInstanceOf[js.Any]).asInstanceOf[ScaleTime_[Range, Output, Unknown]]
  
  @scala.inline
  def scaleUtc[Range, Output, Unknown](): ScaleTime_[Range, Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleUtc")().asInstanceOf[ScaleTime_[Range, Output, Unknown]]
  @scala.inline
  def scaleUtc[Range, Output, Unknown](domain: js.Iterable[NumberValue], range: js.Iterable[Range]): ScaleTime_[Range, Output, Unknown] = (^.asInstanceOf[js.Dynamic].applyDynamic("scaleUtc")(domain.asInstanceOf[js.Any], range.asInstanceOf[js.Any])).asInstanceOf[ScaleTime_[Range, Output, Unknown]]
  @scala.inline
  def scaleUtc[Range, Output, Unknown](range: js.Iterable[Range]): ScaleTime_[Range, Output, Unknown] = ^.asInstanceOf[js.Dynamic].applyDynamic("scaleUtc")(range.asInstanceOf[js.Any]).asInstanceOf[ScaleTime_[Range, Output, Unknown]]
  
  @JSImport("d3", "schemeAccent")
  @js.native
  val schemeAccent: js.Array[String] = js.native
  
  @JSImport("d3", "schemeBlues")
  @js.native
  val schemeBlues: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemeBrBG")
  @js.native
  val schemeBrBG: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemeBuGn")
  @js.native
  val schemeBuGn: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemeBuPu")
  @js.native
  val schemeBuPu: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemeCategory10")
  @js.native
  val schemeCategory10: js.Array[String] = js.native
  
  @JSImport("d3", "schemeDark2")
  @js.native
  val schemeDark2: js.Array[String] = js.native
  
  @JSImport("d3", "schemeGnBu")
  @js.native
  val schemeGnBu: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemeGreens")
  @js.native
  val schemeGreens: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemeGreys")
  @js.native
  val schemeGreys: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemeOrRd")
  @js.native
  val schemeOrRd: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemeOranges")
  @js.native
  val schemeOranges: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemePRGn")
  @js.native
  val schemePRGn: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemePaired")
  @js.native
  val schemePaired: js.Array[String] = js.native
  
  @JSImport("d3", "schemePastel1")
  @js.native
  val schemePastel1: js.Array[String] = js.native
  
  @JSImport("d3", "schemePastel2")
  @js.native
  val schemePastel2: js.Array[String] = js.native
  
  @JSImport("d3", "schemePiYG")
  @js.native
  val schemePiYG: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemePuBu")
  @js.native
  val schemePuBu: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemePuBuGn")
  @js.native
  val schemePuBuGn: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemePuOr")
  @js.native
  val schemePuOr: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemePuRd")
  @js.native
  val schemePuRd: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemePurples")
  @js.native
  val schemePurples: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemeRdBu")
  @js.native
  val schemeRdBu: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemeRdGy")
  @js.native
  val schemeRdGy: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemeRdPu")
  @js.native
  val schemeRdPu: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemeRdYlBu")
  @js.native
  val schemeRdYlBu: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemeRdYlGn")
  @js.native
  val schemeRdYlGn: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemeReds")
  @js.native
  val schemeReds: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemeSet1")
  @js.native
  val schemeSet1: js.Array[String] = js.native
  
  @JSImport("d3", "schemeSet2")
  @js.native
  val schemeSet2: js.Array[String] = js.native
  
  @JSImport("d3", "schemeSet3")
  @js.native
  val schemeSet3: js.Array[String] = js.native
  
  @JSImport("d3", "schemeSpectral")
  @js.native
  val schemeSpectral: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemeTableau10")
  @js.native
  val schemeTableau10: js.Array[String] = js.native
  
  @JSImport("d3", "schemeYlGn")
  @js.native
  val schemeYlGn: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemeYlGnBu")
  @js.native
  val schemeYlGnBu: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemeYlOrBr")
  @js.native
  val schemeYlOrBr: js.Array[js.Array[String]] = js.native
  
  @JSImport("d3", "schemeYlOrRd")
  @js.native
  val schemeYlOrRd: js.Array[js.Array[String]] = js.native
  
  @scala.inline
  def select[OldDatum](node: Document): Selection_[Document, OldDatum, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("select")(node.asInstanceOf[js.Any]).asInstanceOf[Selection_[Document, OldDatum, Null, Unit]]
  @scala.inline
  def select[OldDatum](node: Element): Selection_[Element, OldDatum, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("select")(node.asInstanceOf[js.Any]).asInstanceOf[Selection_[Element, OldDatum, Null, Unit]]
  @scala.inline
  def select[OldDatum](node: Window): Selection_[Window, OldDatum, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("select")(node.asInstanceOf[js.Any]).asInstanceOf[Selection_[Window, OldDatum, Null, Unit]]
  @scala.inline
  def select[OldDatum](node: Null): Selection_[Null, OldDatum, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("select")(node.asInstanceOf[js.Any]).asInstanceOf[Selection_[Null, OldDatum, Null, Unit]]
  @scala.inline
  def select[OldDatum](node: EnterElement): Selection_[EnterElement, OldDatum, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("select")(node.asInstanceOf[js.Any]).asInstanceOf[Selection_[EnterElement, OldDatum, Null, Unit]]
  @scala.inline
  def select[GElement /* <: BaseType */, OldDatum](selector: String): Selection_[GElement, OldDatum, HTMLElement, Any] = ^.asInstanceOf[js.Dynamic].applyDynamic("select")(selector.asInstanceOf[js.Any]).asInstanceOf[Selection_[GElement, OldDatum, HTMLElement, Any]]
  
  @scala.inline
  def selectAll(): Selection_[Null, Unit, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("selectAll")().asInstanceOf[Selection_[Null, Unit, Null, Unit]]
  @scala.inline
  def selectAll[GElement /* <: BaseType */, OldDatum](nodes: js.Array[GElement]): Selection_[GElement, OldDatum, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("selectAll")(nodes.asInstanceOf[js.Any]).asInstanceOf[Selection_[GElement, OldDatum, Null, Unit]]
  @scala.inline
  def selectAll[GElement /* <: BaseType */, OldDatum](nodes: js.Iterable[GElement]): Selection_[GElement, OldDatum, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("selectAll")(nodes.asInstanceOf[js.Any]).asInstanceOf[Selection_[GElement, OldDatum, Null, Unit]]
  @scala.inline
  def selectAll[GElement /* <: BaseType */, OldDatum](nodes: typings.d3Selection.mod.ArrayLike[GElement]): Selection_[GElement, OldDatum, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("selectAll")(nodes.asInstanceOf[js.Any]).asInstanceOf[Selection_[GElement, OldDatum, Null, Unit]]
  @scala.inline
  def selectAll[GElement /* <: BaseType */, OldDatum](selector: String): Selection_[GElement, OldDatum, HTMLElement, Any] = ^.asInstanceOf[js.Dynamic].applyDynamic("selectAll")(selector.asInstanceOf[js.Any]).asInstanceOf[Selection_[GElement, OldDatum, HTMLElement, Any]]
  
  @JSImport("d3", "selection")
  @js.native
  val selection: SelectionFn = js.native
  
  @scala.inline
  def selector[DescElement /* <: Element */](selector: String): js.ThisFunction0[/* this */ BaseType, DescElement] = ^.asInstanceOf[js.Dynamic].applyDynamic("selector")(selector.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, DescElement]]
  
  @scala.inline
  def selectorAll[DescElement /* <: Element */](selector: String): js.ThisFunction0[/* this */ BaseType, NodeList[DescElement with Node]] = ^.asInstanceOf[js.Dynamic].applyDynamic("selectorAll")(selector.asInstanceOf[js.Any]).asInstanceOf[js.ThisFunction0[/* this */ BaseType, NodeList[DescElement with Node]]]
  
  @scala.inline
  def shuffle(array: js.typedarray.Float32Array): js.typedarray.Float32Array = ^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any]).asInstanceOf[js.typedarray.Float32Array]
  @scala.inline
  def shuffle(array: js.typedarray.Float32Array, lo: Double): js.typedarray.Float32Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Float32Array]
  @scala.inline
  def shuffle(array: js.typedarray.Float32Array, lo: Double, hi: Double): js.typedarray.Float32Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Float32Array]
  @scala.inline
  def shuffle(array: js.typedarray.Float32Array, lo: Unit, hi: Double): js.typedarray.Float32Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Float32Array]
  @scala.inline
  def shuffle(array: js.typedarray.Float64Array): js.typedarray.Float64Array = ^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any]).asInstanceOf[js.typedarray.Float64Array]
  @scala.inline
  def shuffle(array: js.typedarray.Float64Array, lo: Double): js.typedarray.Float64Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Float64Array]
  @scala.inline
  def shuffle(array: js.typedarray.Float64Array, lo: Double, hi: Double): js.typedarray.Float64Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Float64Array]
  @scala.inline
  def shuffle(array: js.typedarray.Float64Array, lo: Unit, hi: Double): js.typedarray.Float64Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Float64Array]
  @scala.inline
  def shuffle(array: js.typedarray.Int16Array): js.typedarray.Int16Array = ^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any]).asInstanceOf[js.typedarray.Int16Array]
  @scala.inline
  def shuffle(array: js.typedarray.Int16Array, lo: Double): js.typedarray.Int16Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Int16Array]
  @scala.inline
  def shuffle(array: js.typedarray.Int16Array, lo: Double, hi: Double): js.typedarray.Int16Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Int16Array]
  @scala.inline
  def shuffle(array: js.typedarray.Int16Array, lo: Unit, hi: Double): js.typedarray.Int16Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Int16Array]
  @scala.inline
  def shuffle(array: js.typedarray.Int32Array): js.typedarray.Int32Array = ^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any]).asInstanceOf[js.typedarray.Int32Array]
  @scala.inline
  def shuffle(array: js.typedarray.Int32Array, lo: Double): js.typedarray.Int32Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Int32Array]
  @scala.inline
  def shuffle(array: js.typedarray.Int32Array, lo: Double, hi: Double): js.typedarray.Int32Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Int32Array]
  @scala.inline
  def shuffle(array: js.typedarray.Int32Array, lo: Unit, hi: Double): js.typedarray.Int32Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Int32Array]
  @scala.inline
  def shuffle(array: js.typedarray.Int8Array): js.typedarray.Int8Array = ^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any]).asInstanceOf[js.typedarray.Int8Array]
  @scala.inline
  def shuffle(array: js.typedarray.Int8Array, lo: Double): js.typedarray.Int8Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Int8Array]
  @scala.inline
  def shuffle(array: js.typedarray.Int8Array, lo: Double, hi: Double): js.typedarray.Int8Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Int8Array]
  @scala.inline
  def shuffle(array: js.typedarray.Int8Array, lo: Unit, hi: Double): js.typedarray.Int8Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Int8Array]
  @scala.inline
  def shuffle(array: js.typedarray.Uint16Array): js.typedarray.Uint16Array = ^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any]).asInstanceOf[js.typedarray.Uint16Array]
  @scala.inline
  def shuffle(array: js.typedarray.Uint16Array, lo: Double): js.typedarray.Uint16Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Uint16Array]
  @scala.inline
  def shuffle(array: js.typedarray.Uint16Array, lo: Double, hi: Double): js.typedarray.Uint16Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Uint16Array]
  @scala.inline
  def shuffle(array: js.typedarray.Uint16Array, lo: Unit, hi: Double): js.typedarray.Uint16Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Uint16Array]
  @scala.inline
  def shuffle(array: js.typedarray.Uint32Array): js.typedarray.Uint32Array = ^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any]).asInstanceOf[js.typedarray.Uint32Array]
  @scala.inline
  def shuffle(array: js.typedarray.Uint32Array, lo: Double): js.typedarray.Uint32Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Uint32Array]
  @scala.inline
  def shuffle(array: js.typedarray.Uint32Array, lo: Double, hi: Double): js.typedarray.Uint32Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Uint32Array]
  @scala.inline
  def shuffle(array: js.typedarray.Uint32Array, lo: Unit, hi: Double): js.typedarray.Uint32Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Uint32Array]
  @scala.inline
  def shuffle(array: js.typedarray.Uint8Array): js.typedarray.Uint8Array = ^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any]).asInstanceOf[js.typedarray.Uint8Array]
  @scala.inline
  def shuffle(array: js.typedarray.Uint8Array, lo: Double): js.typedarray.Uint8Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Uint8Array]
  @scala.inline
  def shuffle(array: js.typedarray.Uint8Array, lo: Double, hi: Double): js.typedarray.Uint8Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Uint8Array]
  @scala.inline
  def shuffle(array: js.typedarray.Uint8Array, lo: Unit, hi: Double): js.typedarray.Uint8Array = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Uint8Array]
  @scala.inline
  def shuffle(array: js.typedarray.Uint8ClampedArray): js.typedarray.Uint8ClampedArray = ^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any]).asInstanceOf[js.typedarray.Uint8ClampedArray]
  @scala.inline
  def shuffle(array: js.typedarray.Uint8ClampedArray, lo: Double): js.typedarray.Uint8ClampedArray = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Uint8ClampedArray]
  @scala.inline
  def shuffle(array: js.typedarray.Uint8ClampedArray, lo: Double, hi: Double): js.typedarray.Uint8ClampedArray = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Uint8ClampedArray]
  @scala.inline
  def shuffle(array: js.typedarray.Uint8ClampedArray, lo: Unit, hi: Double): js.typedarray.Uint8ClampedArray = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[js.typedarray.Uint8ClampedArray]
  @scala.inline
  def shuffle[T](array: js.Array[T]): js.Array[T] = ^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any]).asInstanceOf[js.Array[T]]
  @scala.inline
  def shuffle[T](array: js.Array[T], lo: Double): js.Array[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any])).asInstanceOf[js.Array[T]]
  @scala.inline
  def shuffle[T](array: js.Array[T], lo: Double, hi: Double): js.Array[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[js.Array[T]]
  @scala.inline
  def shuffle[T](array: js.Array[T], lo: Unit, hi: Double): js.Array[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("shuffle")(array.asInstanceOf[js.Any], lo.asInstanceOf[js.Any], hi.asInstanceOf[js.Any])).asInstanceOf[js.Array[T]]
  
  @scala.inline
  def shuffler(random: js.Function0[Double]): FnCall = ^.asInstanceOf[js.Dynamic].applyDynamic("shuffler")(random.asInstanceOf[js.Any]).asInstanceOf[FnCall]
  
  @scala.inline
  def some[T](
    iterable: js.Iterable[T],
    test: js.Function3[/* value */ T, /* index */ Double, /* iterable */ js.Iterable[T], Any]
  ): Boolean = (^.asInstanceOf[js.Dynamic].applyDynamic("some")(iterable.asInstanceOf[js.Any], test.asInstanceOf[js.Any])).asInstanceOf[Boolean]
  
  @scala.inline
  def sort[T](iterable: js.Iterable[T]): js.Array[T] = ^.asInstanceOf[js.Dynamic].applyDynamic("sort")(iterable.asInstanceOf[js.Any]).asInstanceOf[js.Array[T]]
  @scala.inline
  def sort[T](iterable: js.Iterable[T], accessors: (js.Function1[/* a */ T, Any])*): js.Array[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("sort")(List(iterable.asInstanceOf[js.Any]).`++`(accessors.asInstanceOf[Seq[js.Any]]) :_*)).asInstanceOf[js.Array[T]]
  @scala.inline
  def sort[T](iterable: js.Iterable[T], comparator: js.Function2[/* a */ T, /* b */ T, Double]): js.Array[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("sort")(iterable.asInstanceOf[js.Any], comparator.asInstanceOf[js.Any])).asInstanceOf[js.Array[T]]
  
  @scala.inline
  def stack(): Stack_[Any, StringDictionary[Double], String] = ^.asInstanceOf[js.Dynamic].applyDynamic("stack")().asInstanceOf[Stack_[Any, StringDictionary[Double], String]]
  
  @scala.inline
  def stackOffsetDiverging(series: Series[Any, Any], order: js.Iterable[Double]): Unit = (^.asInstanceOf[js.Dynamic].applyDynamic("stackOffsetDiverging")(series.asInstanceOf[js.Any], order.asInstanceOf[js.Any])).asInstanceOf[Unit]
  
  @scala.inline
  def stackOffsetExpand(series: Series[Any, Any], order: js.Iterable[Double]): Unit = (^.asInstanceOf[js.Dynamic].applyDynamic("stackOffsetExpand")(series.asInstanceOf[js.Any], order.asInstanceOf[js.Any])).asInstanceOf[Unit]
  
  @scala.inline
  def stackOffsetNone(series: Series[Any, Any], order: js.Iterable[Double]): Unit = (^.asInstanceOf[js.Dynamic].applyDynamic("stackOffsetNone")(series.asInstanceOf[js.Any], order.asInstanceOf[js.Any])).asInstanceOf[Unit]
  
  @scala.inline
  def stackOffsetSilhouette(series: Series[Any, Any], order: js.Iterable[Double]): Unit = (^.asInstanceOf[js.Dynamic].applyDynamic("stackOffsetSilhouette")(series.asInstanceOf[js.Any], order.asInstanceOf[js.Any])).asInstanceOf[Unit]
  
  @scala.inline
  def stackOffsetWiggle(series: Series[Any, Any], order: js.Iterable[Double]): Unit = (^.asInstanceOf[js.Dynamic].applyDynamic("stackOffsetWiggle")(series.asInstanceOf[js.Any], order.asInstanceOf[js.Any])).asInstanceOf[Unit]
  
  @scala.inline
  def stackOrderAppearance(series: Series[Any, Any]): js.Array[Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("stackOrderAppearance")(series.asInstanceOf[js.Any]).asInstanceOf[js.Array[Double]]
  
  @scala.inline
  def stackOrderAscending(series: Series[Any, Any]): js.Array[Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("stackOrderAscending")(series.asInstanceOf[js.Any]).asInstanceOf[js.Array[Double]]
  
  @scala.inline
  def stackOrderDescending(series: Series[Any, Any]): js.Array[Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("stackOrderDescending")(series.asInstanceOf[js.Any]).asInstanceOf[js.Array[Double]]
  
  @scala.inline
  def stackOrderInsideOut(series: Series[Any, Any]): js.Array[Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("stackOrderInsideOut")(series.asInstanceOf[js.Any]).asInstanceOf[js.Array[Double]]
  
  @scala.inline
  def stackOrderNone(series: Series[Any, Any]): js.Array[Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("stackOrderNone")(series.asInstanceOf[js.Any]).asInstanceOf[js.Array[Double]]
  
  @scala.inline
  def stackOrderReverse(series: Series[Any, Any]): js.Array[Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("stackOrderReverse")(series.asInstanceOf[js.Any]).asInstanceOf[js.Array[Double]]
  
  @scala.inline
  def stack_Datum[Datum](): Stack_[Any, Datum, String] = ^.asInstanceOf[js.Dynamic].applyDynamic("stack")().asInstanceOf[Stack_[Any, Datum, String]]
  
  @scala.inline
  def stack_DatumKey[Datum, Key](): Stack_[Any, Datum, Key] = ^.asInstanceOf[js.Dynamic].applyDynamic("stack")().asInstanceOf[Stack_[Any, Datum, Key]]
  
  @scala.inline
  def stack_ThisDatumKey[This, Datum, Key](): Stack_[This, Datum, Key] = ^.asInstanceOf[js.Dynamic].applyDynamic("stack")().asInstanceOf[Stack_[This, Datum, Key]]
  
  @scala.inline
  def stratify[Datum](): StratifyOperator[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("stratify")().asInstanceOf[StratifyOperator[Datum]]
  
  @scala.inline
  def style(node: Element, name: String): String = (^.asInstanceOf[js.Dynamic].applyDynamic("style")(node.asInstanceOf[js.Any], name.asInstanceOf[js.Any])).asInstanceOf[String]
  
  @scala.inline
  def subset[T](a: js.Iterable[T], b: js.Iterable[T]): Boolean = (^.asInstanceOf[js.Dynamic].applyDynamic("subset")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[Boolean]
  
  @scala.inline
  def sum(iterable: js.Iterable[js.UndefOr[Numeric | Null]]): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("sum")(iterable.asInstanceOf[js.Any]).asInstanceOf[Double]
  @scala.inline
  def sum[T](
    iterable: js.Iterable[T],
    accessor: js.Function3[
      /* datum */ T, 
      /* index */ Double, 
      /* array */ js.Iterable[T], 
      js.UndefOr[Double | Null]
    ]
  ): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("sum")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[Double]
  
  @scala.inline
  def superset[T](a: js.Iterable[T], b: js.Iterable[T]): Boolean = (^.asInstanceOf[js.Dynamic].applyDynamic("superset")(a.asInstanceOf[js.Any], b.asInstanceOf[js.Any])).asInstanceOf[Boolean]
  
  @scala.inline
  def svg(url: String): js.Promise[Document] = ^.asInstanceOf[js.Dynamic].applyDynamic("svg")(url.asInstanceOf[js.Any]).asInstanceOf[js.Promise[Document]]
  @scala.inline
  def svg(url: String, init: RequestInit): js.Promise[Document] = (^.asInstanceOf[js.Dynamic].applyDynamic("svg")(url.asInstanceOf[js.Any], init.asInstanceOf[js.Any])).asInstanceOf[js.Promise[Document]]
  
  @scala.inline
  def symbol[Datum](): Symbol_[Any, Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("symbol")().asInstanceOf[Symbol_[Any, Datum]]
  @scala.inline
  def symbol[Datum](`type`: js.ThisFunction2[/* this */ Any, /* d */ Datum, /* repeated */ Any, SymbolType]): Symbol_[Any, Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("symbol")(`type`.asInstanceOf[js.Any]).asInstanceOf[Symbol_[Any, Datum]]
  @scala.inline
  def symbol[Datum](
    `type`: js.ThisFunction2[/* this */ Any, /* d */ Datum, /* repeated */ Any, SymbolType],
    size: js.ThisFunction2[/* this */ Any, /* d */ Datum, /* repeated */ Any, Double]
  ): Symbol_[Any, Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("symbol")(`type`.asInstanceOf[js.Any], size.asInstanceOf[js.Any])).asInstanceOf[Symbol_[Any, Datum]]
  @scala.inline
  def symbol[Datum](
    `type`: js.ThisFunction2[/* this */ Any, /* d */ Datum, /* repeated */ Any, SymbolType],
    size: Double
  ): Symbol_[Any, Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("symbol")(`type`.asInstanceOf[js.Any], size.asInstanceOf[js.Any])).asInstanceOf[Symbol_[Any, Datum]]
  @scala.inline
  def symbol[Datum](`type`: Unit, size: js.ThisFunction2[/* this */ Any, /* d */ Datum, /* repeated */ Any, Double]): Symbol_[Any, Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("symbol")(`type`.asInstanceOf[js.Any], size.asInstanceOf[js.Any])).asInstanceOf[Symbol_[Any, Datum]]
  @scala.inline
  def symbol[Datum](`type`: Unit, size: Double): Symbol_[Any, Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("symbol")(`type`.asInstanceOf[js.Any], size.asInstanceOf[js.Any])).asInstanceOf[Symbol_[Any, Datum]]
  @scala.inline
  def symbol[Datum](`type`: SymbolType): Symbol_[Any, Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("symbol")(`type`.asInstanceOf[js.Any]).asInstanceOf[Symbol_[Any, Datum]]
  @scala.inline
  def symbol[Datum](
    `type`: SymbolType,
    size: js.ThisFunction2[/* this */ Any, /* d */ Datum, /* repeated */ Any, Double]
  ): Symbol_[Any, Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("symbol")(`type`.asInstanceOf[js.Any], size.asInstanceOf[js.Any])).asInstanceOf[Symbol_[Any, Datum]]
  @scala.inline
  def symbol[Datum](`type`: SymbolType, size: Double): Symbol_[Any, Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("symbol")(`type`.asInstanceOf[js.Any], size.asInstanceOf[js.Any])).asInstanceOf[Symbol_[Any, Datum]]
  
  @JSImport("d3", "symbolAsterisk")
  @js.native
  val symbolAsterisk: SymbolType = js.native
  
  @JSImport("d3", "symbolCircle")
  @js.native
  val symbolCircle: SymbolType = js.native
  
  @JSImport("d3", "symbolCross")
  @js.native
  val symbolCross: SymbolType = js.native
  
  @JSImport("d3", "symbolDiamond")
  @js.native
  val symbolDiamond: SymbolType = js.native
  
  @JSImport("d3", "symbolDiamond2")
  @js.native
  val symbolDiamond2: SymbolType = js.native
  
  @JSImport("d3", "symbolPlus")
  @js.native
  val symbolPlus: SymbolType = js.native
  
  @JSImport("d3", "symbolSquare")
  @js.native
  val symbolSquare: SymbolType = js.native
  
  @JSImport("d3", "symbolSquare2")
  @js.native
  val symbolSquare2: SymbolType = js.native
  
  @JSImport("d3", "symbolStar")
  @js.native
  val symbolStar: SymbolType = js.native
  
  @JSImport("d3", "symbolTriangle")
  @js.native
  val symbolTriangle: SymbolType = js.native
  
  @JSImport("d3", "symbolTriangle2")
  @js.native
  val symbolTriangle2: SymbolType = js.native
  
  @JSImport("d3", "symbolWye")
  @js.native
  val symbolWye: SymbolType = js.native
  
  @JSImport("d3", "symbolX")
  @js.native
  val symbolX: SymbolType = js.native
  
  @scala.inline
  def symbol_ThisDatum[This, Datum](): Symbol_[This, Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("symbol")().asInstanceOf[Symbol_[This, Datum]]
  @scala.inline
  def symbol_ThisDatum[This, Datum](`type`: js.ThisFunction2[/* this */ This, /* d */ Datum, /* repeated */ Any, SymbolType]): Symbol_[This, Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("symbol")(`type`.asInstanceOf[js.Any]).asInstanceOf[Symbol_[This, Datum]]
  @scala.inline
  def symbol_ThisDatum[This, Datum](
    `type`: js.ThisFunction2[/* this */ This, /* d */ Datum, /* repeated */ Any, SymbolType],
    size: js.ThisFunction2[/* this */ This, /* d */ Datum, /* repeated */ Any, Double]
  ): Symbol_[This, Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("symbol")(`type`.asInstanceOf[js.Any], size.asInstanceOf[js.Any])).asInstanceOf[Symbol_[This, Datum]]
  @scala.inline
  def symbol_ThisDatum[This, Datum](
    `type`: js.ThisFunction2[/* this */ This, /* d */ Datum, /* repeated */ Any, SymbolType],
    size: Double
  ): Symbol_[This, Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("symbol")(`type`.asInstanceOf[js.Any], size.asInstanceOf[js.Any])).asInstanceOf[Symbol_[This, Datum]]
  @scala.inline
  def symbol_ThisDatum[This, Datum](`type`: Unit, size: js.ThisFunction2[/* this */ This, /* d */ Datum, /* repeated */ Any, Double]): Symbol_[This, Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("symbol")(`type`.asInstanceOf[js.Any], size.asInstanceOf[js.Any])).asInstanceOf[Symbol_[This, Datum]]
  @scala.inline
  def symbol_ThisDatum[This, Datum](`type`: Unit, size: Double): Symbol_[This, Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("symbol")(`type`.asInstanceOf[js.Any], size.asInstanceOf[js.Any])).asInstanceOf[Symbol_[This, Datum]]
  @scala.inline
  def symbol_ThisDatum[This, Datum](`type`: SymbolType): Symbol_[This, Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("symbol")(`type`.asInstanceOf[js.Any]).asInstanceOf[Symbol_[This, Datum]]
  @scala.inline
  def symbol_ThisDatum[This, Datum](
    `type`: SymbolType,
    size: js.ThisFunction2[/* this */ This, /* d */ Datum, /* repeated */ Any, Double]
  ): Symbol_[This, Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("symbol")(`type`.asInstanceOf[js.Any], size.asInstanceOf[js.Any])).asInstanceOf[Symbol_[This, Datum]]
  @scala.inline
  def symbol_ThisDatum[This, Datum](`type`: SymbolType, size: Double): Symbol_[This, Datum] = (^.asInstanceOf[js.Dynamic].applyDynamic("symbol")(`type`.asInstanceOf[js.Any], size.asInstanceOf[js.Any])).asInstanceOf[Symbol_[This, Datum]]
  
  @JSImport("d3", "symbols")
  @js.native
  val symbols: js.Array[SymbolType] = js.native
  
  @JSImport("d3", "symbolsFill")
  @js.native
  val symbolsFill: js.Array[SymbolType] = js.native
  
  @JSImport("d3", "symbolsStroke")
  @js.native
  val symbolsStroke: js.Array[SymbolType] = js.native
  
  @scala.inline
  def text(url: String): js.Promise[String] = ^.asInstanceOf[js.Dynamic].applyDynamic("text")(url.asInstanceOf[js.Any]).asInstanceOf[js.Promise[String]]
  @scala.inline
  def text(url: String, init: RequestInit): js.Promise[String] = (^.asInstanceOf[js.Dynamic].applyDynamic("text")(url.asInstanceOf[js.Any], init.asInstanceOf[js.Any])).asInstanceOf[js.Promise[String]]
  
  @scala.inline
  def thresholdFreedmanDiaconis(values: ArrayLike[js.UndefOr[Double]], min: Double, max: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("thresholdFreedmanDiaconis")(values.asInstanceOf[js.Any], min.asInstanceOf[js.Any], max.asInstanceOf[js.Any])).asInstanceOf[Double]
  
  @scala.inline
  def thresholdScott(values: ArrayLike[js.UndefOr[Double]], min: Double, max: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("thresholdScott")(values.asInstanceOf[js.Any], min.asInstanceOf[js.Any], max.asInstanceOf[js.Any])).asInstanceOf[Double]
  
  @scala.inline
  def thresholdSturges(values: ArrayLike[js.UndefOr[Double]]): Double = ^.asInstanceOf[js.Dynamic].applyDynamic("thresholdSturges")(values.asInstanceOf[js.Any]).asInstanceOf[Double]
  
  @scala.inline
  def tickFormat(start: Double, stop: Double, count: Double): js.Function1[/* d */ NumberValue, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("tickFormat")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], count.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* d */ NumberValue, String]]
  @scala.inline
  def tickFormat(start: Double, stop: Double, count: Double, specifier: String): js.Function1[/* d */ NumberValue, String] = (^.asInstanceOf[js.Dynamic].applyDynamic("tickFormat")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], count.asInstanceOf[js.Any], specifier.asInstanceOf[js.Any])).asInstanceOf[js.Function1[/* d */ NumberValue, String]]
  
  @scala.inline
  def tickIncrement(start: Double, stop: Double, count: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("tickIncrement")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], count.asInstanceOf[js.Any])).asInstanceOf[Double]
  
  @scala.inline
  def tickStep(start: Double, stop: Double, count: Double): Double = (^.asInstanceOf[js.Dynamic].applyDynamic("tickStep")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], count.asInstanceOf[js.Any])).asInstanceOf[Double]
  
  @scala.inline
  def ticks(start: Double, stop: Double, count: Double): js.Array[Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("ticks")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], count.asInstanceOf[js.Any])).asInstanceOf[js.Array[Double]]
  
  @JSImport("d3", "timeDay")
  @js.native
  val timeDay: CountableTimeInterval = js.native
  
  @scala.inline
  def timeDays(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeDays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def timeDays(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeDays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @scala.inline
  def timeFormat(specifier: String): js.Function1[/* date */ js.Date, String] = ^.asInstanceOf[js.Dynamic].applyDynamic("timeFormat")(specifier.asInstanceOf[js.Any]).asInstanceOf[js.Function1[/* date */ js.Date, String]]
  
  @scala.inline
  def timeFormatDefaultLocale(definition: TimeLocaleDefinition): TimeLocaleObject = ^.asInstanceOf[js.Dynamic].applyDynamic("timeFormatDefaultLocale")(definition.asInstanceOf[js.Any]).asInstanceOf[TimeLocaleObject]
  
  @scala.inline
  def timeFormatLocale(definition: TimeLocaleDefinition): TimeLocaleObject = ^.asInstanceOf[js.Dynamic].applyDynamic("timeFormatLocale")(definition.asInstanceOf[js.Any]).asInstanceOf[TimeLocaleObject]
  
  @JSImport("d3", "timeFriday")
  @js.native
  val timeFriday: CountableTimeInterval = js.native
  
  @scala.inline
  def timeFridays(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeFridays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def timeFridays(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeFridays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "timeHour")
  @js.native
  val timeHour: CountableTimeInterval = js.native
  
  @scala.inline
  def timeHours(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeHours")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def timeHours(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeHours")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @scala.inline
  def timeInterval(
    floor: js.Function1[/* date */ js.Date, Unit],
    offset: js.Function2[/* date */ js.Date, /* step */ Double, Unit]
  ): TimeInterval_ = (^.asInstanceOf[js.Dynamic].applyDynamic("timeInterval")(floor.asInstanceOf[js.Any], offset.asInstanceOf[js.Any])).asInstanceOf[TimeInterval_]
  @scala.inline
  def timeInterval(
    floor: js.Function1[/* date */ js.Date, Unit],
    offset: js.Function2[/* date */ js.Date, /* step */ Double, Unit],
    count: js.Function2[/* start */ js.Date, /* end */ js.Date, Double]
  ): CountableTimeInterval = (^.asInstanceOf[js.Dynamic].applyDynamic("timeInterval")(floor.asInstanceOf[js.Any], offset.asInstanceOf[js.Any], count.asInstanceOf[js.Any])).asInstanceOf[CountableTimeInterval]
  @scala.inline
  def timeInterval(
    floor: js.Function1[/* date */ js.Date, Unit],
    offset: js.Function2[/* date */ js.Date, /* step */ Double, Unit],
    count: js.Function2[/* start */ js.Date, /* end */ js.Date, Double],
    field: js.Function1[/* date */ js.Date, Double]
  ): CountableTimeInterval = (^.asInstanceOf[js.Dynamic].applyDynamic("timeInterval")(floor.asInstanceOf[js.Any], offset.asInstanceOf[js.Any], count.asInstanceOf[js.Any], field.asInstanceOf[js.Any])).asInstanceOf[CountableTimeInterval]
  
  @JSImport("d3", "timeMillisecond")
  @js.native
  val timeMillisecond: CountableTimeInterval = js.native
  
  @scala.inline
  def timeMilliseconds(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeMilliseconds")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def timeMilliseconds(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeMilliseconds")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "timeMinute")
  @js.native
  val timeMinute: CountableTimeInterval = js.native
  
  @scala.inline
  def timeMinutes(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeMinutes")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def timeMinutes(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeMinutes")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "timeMonday")
  @js.native
  val timeMonday: CountableTimeInterval = js.native
  
  @scala.inline
  def timeMondays(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeMondays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def timeMondays(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeMondays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "timeMonth")
  @js.native
  val timeMonth: CountableTimeInterval = js.native
  
  @scala.inline
  def timeMonths(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeMonths")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def timeMonths(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeMonths")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @scala.inline
  def timeParse(specifier: String): js.Function1[/* dateString */ String, js.Date | Null] = ^.asInstanceOf[js.Dynamic].applyDynamic("timeParse")(specifier.asInstanceOf[js.Any]).asInstanceOf[js.Function1[/* dateString */ String, js.Date | Null]]
  
  @JSImport("d3", "timeSaturday")
  @js.native
  val timeSaturday: CountableTimeInterval = js.native
  
  @scala.inline
  def timeSaturdays(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeSaturdays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def timeSaturdays(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeSaturdays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "timeSecond")
  @js.native
  val timeSecond: CountableTimeInterval = js.native
  
  @scala.inline
  def timeSeconds(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeSeconds")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def timeSeconds(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeSeconds")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "timeSunday")
  @js.native
  val timeSunday: CountableTimeInterval = js.native
  
  @scala.inline
  def timeSundays(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeSundays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def timeSundays(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeSundays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "timeThursday")
  @js.native
  val timeThursday: CountableTimeInterval = js.native
  
  @scala.inline
  def timeThursdays(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeThursdays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def timeThursdays(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeThursdays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @scala.inline
  def timeTickInterval(start: js.Date, stop: js.Date, count: Double): TimeInterval_ | Null = (^.asInstanceOf[js.Dynamic].applyDynamic("timeTickInterval")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], count.asInstanceOf[js.Any])).asInstanceOf[TimeInterval_ | Null]
  
  @scala.inline
  def timeTicks(start: js.Date, stop: js.Date, count: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeTicks")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], count.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "timeTuesday")
  @js.native
  val timeTuesday: CountableTimeInterval = js.native
  
  @scala.inline
  def timeTuesdays(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeTuesdays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def timeTuesdays(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeTuesdays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "timeWednesday")
  @js.native
  val timeWednesday: CountableTimeInterval = js.native
  
  @scala.inline
  def timeWednesdays(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeWednesdays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def timeWednesdays(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeWednesdays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "timeWeek")
  @js.native
  val timeWeek: CountableTimeInterval = js.native
  
  @scala.inline
  def timeWeeks(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeWeeks")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def timeWeeks(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeWeeks")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "timeYear")
  @js.native
  val timeYear: CountableTimeInterval = js.native
  
  @scala.inline
  def timeYears(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeYears")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def timeYears(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("timeYears")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @scala.inline
  def timeout(callback: js.Function1[/* elapsed */ Double, Unit]): Timer_ = ^.asInstanceOf[js.Dynamic].applyDynamic("timeout")(callback.asInstanceOf[js.Any]).asInstanceOf[Timer_]
  @scala.inline
  def timeout(callback: js.Function1[/* elapsed */ Double, Unit], delay: Double): Timer_ = (^.asInstanceOf[js.Dynamic].applyDynamic("timeout")(callback.asInstanceOf[js.Any], delay.asInstanceOf[js.Any])).asInstanceOf[Timer_]
  @scala.inline
  def timeout(callback: js.Function1[/* elapsed */ Double, Unit], delay: Double, time: Double): Timer_ = (^.asInstanceOf[js.Dynamic].applyDynamic("timeout")(callback.asInstanceOf[js.Any], delay.asInstanceOf[js.Any], time.asInstanceOf[js.Any])).asInstanceOf[Timer_]
  @scala.inline
  def timeout(callback: js.Function1[/* elapsed */ Double, Unit], delay: Unit, time: Double): Timer_ = (^.asInstanceOf[js.Dynamic].applyDynamic("timeout")(callback.asInstanceOf[js.Any], delay.asInstanceOf[js.Any], time.asInstanceOf[js.Any])).asInstanceOf[Timer_]
  
  @scala.inline
  def timer(callback: js.Function1[/* elapsed */ Double, Unit]): Timer_ = ^.asInstanceOf[js.Dynamic].applyDynamic("timer")(callback.asInstanceOf[js.Any]).asInstanceOf[Timer_]
  @scala.inline
  def timer(callback: js.Function1[/* elapsed */ Double, Unit], delay: Double): Timer_ = (^.asInstanceOf[js.Dynamic].applyDynamic("timer")(callback.asInstanceOf[js.Any], delay.asInstanceOf[js.Any])).asInstanceOf[Timer_]
  @scala.inline
  def timer(callback: js.Function1[/* elapsed */ Double, Unit], delay: Double, time: Double): Timer_ = (^.asInstanceOf[js.Dynamic].applyDynamic("timer")(callback.asInstanceOf[js.Any], delay.asInstanceOf[js.Any], time.asInstanceOf[js.Any])).asInstanceOf[Timer_]
  @scala.inline
  def timer(callback: js.Function1[/* elapsed */ Double, Unit], delay: Unit, time: Double): Timer_ = (^.asInstanceOf[js.Dynamic].applyDynamic("timer")(callback.asInstanceOf[js.Any], delay.asInstanceOf[js.Any], time.asInstanceOf[js.Any])).asInstanceOf[Timer_]
  
  @scala.inline
  def timerFlush(): Unit = ^.asInstanceOf[js.Dynamic].applyDynamic("timerFlush")().asInstanceOf[Unit]
  
  @scala.inline
  def transition[OldDatum](): Transition_[HTMLElement, OldDatum, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("transition")().asInstanceOf[Transition_[HTMLElement, OldDatum, Null, Unit]]
  @scala.inline
  def transition[OldDatum](name: String): Transition_[HTMLElement, OldDatum, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("transition")(name.asInstanceOf[js.Any]).asInstanceOf[Transition_[HTMLElement, OldDatum, Null, Unit]]
  @scala.inline
  def transition[OldDatum](transition: Transition_[BaseType, Any, BaseType, Any]): Transition_[HTMLElement, OldDatum, Null, Unit] = ^.asInstanceOf[js.Dynamic].applyDynamic("transition")(transition.asInstanceOf[js.Any]).asInstanceOf[Transition_[HTMLElement, OldDatum, Null, Unit]]
  
  @scala.inline
  def transpose[T](matrix: ArrayLike[ArrayLike[T]]): js.Array[js.Array[T]] = ^.asInstanceOf[js.Dynamic].applyDynamic("transpose")(matrix.asInstanceOf[js.Any]).asInstanceOf[js.Array[js.Array[T]]]
  
  @scala.inline
  def tree[Datum](): TreeLayout[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("tree")().asInstanceOf[TreeLayout[Datum]]
  
  @scala.inline
  def treemap[Datum](): TreemapLayout[Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("treemap")().asInstanceOf[TreemapLayout[Datum]]
  
  @scala.inline
  def treemapBinary(node: HierarchyRectangularNode[Any], x0: Double, y0: Double, x1: Double, y1: Double): Unit = (^.asInstanceOf[js.Dynamic].applyDynamic("treemapBinary")(node.asInstanceOf[js.Any], x0.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], x1.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Unit]
  
  @scala.inline
  def treemapDice(node: HierarchyRectangularNode[Any], x0: Double, y0: Double, x1: Double, y1: Double): Unit = (^.asInstanceOf[js.Dynamic].applyDynamic("treemapDice")(node.asInstanceOf[js.Any], x0.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], x1.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Unit]
  
  @JSImport("d3", "treemapResquarify")
  @js.native
  val treemapResquarify: RatioSquarifyTilingFactory = js.native
  
  @scala.inline
  def treemapSlice(node: HierarchyRectangularNode[Any], x0: Double, y0: Double, x1: Double, y1: Double): Unit = (^.asInstanceOf[js.Dynamic].applyDynamic("treemapSlice")(node.asInstanceOf[js.Any], x0.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], x1.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Unit]
  
  @scala.inline
  def treemapSliceDice(node: HierarchyRectangularNode[Any], x0: Double, y0: Double, x1: Double, y1: Double): Unit = (^.asInstanceOf[js.Dynamic].applyDynamic("treemapSliceDice")(node.asInstanceOf[js.Any], x0.asInstanceOf[js.Any], y0.asInstanceOf[js.Any], x1.asInstanceOf[js.Any], y1.asInstanceOf[js.Any])).asInstanceOf[Unit]
  
  @JSImport("d3", "treemapSquarify")
  @js.native
  val treemapSquarify: RatioSquarifyTilingFactory = js.native
  
  @scala.inline
  def tsv[Columns /* <: String */](url: String): js.Promise[DSVRowArray[Columns]] = ^.asInstanceOf[js.Dynamic].applyDynamic("tsv")(url.asInstanceOf[js.Any]).asInstanceOf[js.Promise[DSVRowArray[Columns]]]
  @scala.inline
  def tsv[Columns /* <: String */](url: String, init: RequestInit): js.Promise[DSVRowArray[Columns]] = (^.asInstanceOf[js.Dynamic].applyDynamic("tsv")(url.asInstanceOf[js.Any], init.asInstanceOf[js.Any])).asInstanceOf[js.Promise[DSVRowArray[Columns]]]
  @scala.inline
  def tsv[ParsedRow /* <: js.Object */, Columns /* <: String */](
    url: String,
    init: RequestInit,
    row: js.Function3[
      /* rawRow */ DSVRowString[Columns], 
      /* index */ Double, 
      /* columns */ js.Array[Columns], 
      js.UndefOr[ParsedRow | Null]
    ]
  ): js.Promise[DSVParsedArray[ParsedRow]] = (^.asInstanceOf[js.Dynamic].applyDynamic("tsv")(url.asInstanceOf[js.Any], init.asInstanceOf[js.Any], row.asInstanceOf[js.Any])).asInstanceOf[js.Promise[DSVParsedArray[ParsedRow]]]
  @scala.inline
  def tsv[ParsedRow /* <: js.Object */, Columns /* <: String */](
    url: String,
    row: js.Function3[
      /* rawRow */ DSVRowString[Columns], 
      /* index */ Double, 
      /* columns */ js.Array[Columns], 
      js.UndefOr[ParsedRow | Null]
    ]
  ): js.Promise[DSVParsedArray[ParsedRow]] = (^.asInstanceOf[js.Dynamic].applyDynamic("tsv")(url.asInstanceOf[js.Any], row.asInstanceOf[js.Any])).asInstanceOf[js.Promise[DSVParsedArray[ParsedRow]]]
  
  @scala.inline
  def tsvFormat[T /* <: js.Object */](rows: js.Array[T]): String = ^.asInstanceOf[js.Dynamic].applyDynamic("tsvFormat")(rows.asInstanceOf[js.Any]).asInstanceOf[String]
  @scala.inline
  def tsvFormat[T /* <: js.Object */](rows: js.Array[T], columns: js.Array[/* keyof T */ String]): String = (^.asInstanceOf[js.Dynamic].applyDynamic("tsvFormat")(rows.asInstanceOf[js.Any], columns.asInstanceOf[js.Any])).asInstanceOf[String]
  
  @scala.inline
  def tsvFormatBody[T /* <: js.Object */](rows: js.Array[T]): String = ^.asInstanceOf[js.Dynamic].applyDynamic("tsvFormatBody")(rows.asInstanceOf[js.Any]).asInstanceOf[String]
  @scala.inline
  def tsvFormatBody[T /* <: js.Object */](rows: js.Array[T], columns: js.Array[/* keyof T */ String]): String = (^.asInstanceOf[js.Dynamic].applyDynamic("tsvFormatBody")(rows.asInstanceOf[js.Any], columns.asInstanceOf[js.Any])).asInstanceOf[String]
  
  @scala.inline
  def tsvFormatRow(row: js.Array[String]): String = ^.asInstanceOf[js.Dynamic].applyDynamic("tsvFormatRow")(row.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def tsvFormatRows(rows: js.Array[js.Array[String]]): String = ^.asInstanceOf[js.Dynamic].applyDynamic("tsvFormatRows")(rows.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def tsvFormatValue(value: String): String = ^.asInstanceOf[js.Dynamic].applyDynamic("tsvFormatValue")(value.asInstanceOf[js.Any]).asInstanceOf[String]
  
  @scala.inline
  def tsvParse[Columns /* <: String */](tsvString: String): DSVRowArray[Columns] = ^.asInstanceOf[js.Dynamic].applyDynamic("tsvParse")(tsvString.asInstanceOf[js.Any]).asInstanceOf[DSVRowArray[Columns]]
  @scala.inline
  def tsvParse[ParsedRow /* <: js.Object */, Columns /* <: String */](
    tsvString: String,
    row: js.Function3[
      /* rawRow */ DSVRowString[Columns], 
      /* index */ Double, 
      /* columns */ js.Array[Columns], 
      js.UndefOr[ParsedRow | Null]
    ]
  ): DSVParsedArray[ParsedRow] = (^.asInstanceOf[js.Dynamic].applyDynamic("tsvParse")(tsvString.asInstanceOf[js.Any], row.asInstanceOf[js.Any])).asInstanceOf[DSVParsedArray[ParsedRow]]
  
  @scala.inline
  def tsvParseRows(tsvString: String): js.Array[js.Array[String]] = ^.asInstanceOf[js.Dynamic].applyDynamic("tsvParseRows")(tsvString.asInstanceOf[js.Any]).asInstanceOf[js.Array[js.Array[String]]]
  @scala.inline
  def tsvParseRows[ParsedRow /* <: js.Object */](
    tsvString: String,
    row: js.Function2[/* rawRow */ js.Array[String], /* index */ Double, js.UndefOr[ParsedRow | Null]]
  ): js.Array[ParsedRow] = (^.asInstanceOf[js.Dynamic].applyDynamic("tsvParseRows")(tsvString.asInstanceOf[js.Any], row.asInstanceOf[js.Any])).asInstanceOf[js.Array[ParsedRow]]
  
  @scala.inline
  def union[T](iterables: js.Iterable[T]*): typings.d3Array.mod.InternSet[T] = (^.asInstanceOf[js.Dynamic].applyDynamic("union")(iterables.asInstanceOf[Seq[js.Any]] :_*)).asInstanceOf[typings.d3Array.mod.InternSet[T]]
  
  @JSImport("d3", "utcDay")
  @js.native
  val utcDay: CountableTimeInterval = js.native
  
  @scala.inline
  def utcDays(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcDays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def utcDays(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcDays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @scala.inline
  def utcFormat(specifier: String): js.Function1[/* date */ js.Date, String] = ^.asInstanceOf[js.Dynamic].applyDynamic("utcFormat")(specifier.asInstanceOf[js.Any]).asInstanceOf[js.Function1[/* date */ js.Date, String]]
  
  @JSImport("d3", "utcFriday")
  @js.native
  val utcFriday: CountableTimeInterval = js.native
  
  @scala.inline
  def utcFridays(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcFridays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def utcFridays(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcFridays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "utcHour")
  @js.native
  val utcHour: CountableTimeInterval = js.native
  
  @scala.inline
  def utcHours(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcHours")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def utcHours(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcHours")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "utcMillisecond")
  @js.native
  val utcMillisecond: CountableTimeInterval = js.native
  
  @scala.inline
  def utcMilliseconds(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcMilliseconds")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def utcMilliseconds(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcMilliseconds")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "utcMinute")
  @js.native
  val utcMinute: CountableTimeInterval = js.native
  
  @scala.inline
  def utcMinutes(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcMinutes")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def utcMinutes(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcMinutes")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "utcMonday")
  @js.native
  val utcMonday: CountableTimeInterval = js.native
  
  @scala.inline
  def utcMondays(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcMondays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def utcMondays(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcMondays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "utcMonth")
  @js.native
  val utcMonth: CountableTimeInterval = js.native
  
  @scala.inline
  def utcMonths(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcMonths")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def utcMonths(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcMonths")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @scala.inline
  def utcParse(specifier: String): js.Function1[/* dateString */ String, js.Date | Null] = ^.asInstanceOf[js.Dynamic].applyDynamic("utcParse")(specifier.asInstanceOf[js.Any]).asInstanceOf[js.Function1[/* dateString */ String, js.Date | Null]]
  
  @JSImport("d3", "utcSaturday")
  @js.native
  val utcSaturday: CountableTimeInterval = js.native
  
  @scala.inline
  def utcSaturdays(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcSaturdays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def utcSaturdays(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcSaturdays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "utcSecond")
  @js.native
  val utcSecond: CountableTimeInterval = js.native
  
  @scala.inline
  def utcSeconds(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcSeconds")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def utcSeconds(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcSeconds")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "utcSunday")
  @js.native
  val utcSunday: CountableTimeInterval = js.native
  
  @scala.inline
  def utcSundays(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcSundays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def utcSundays(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcSundays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "utcThursday")
  @js.native
  val utcThursday: CountableTimeInterval = js.native
  
  @scala.inline
  def utcThursdays(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcThursdays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def utcThursdays(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcThursdays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @scala.inline
  def utcTickInterval(start: js.Date, stop: js.Date, count: Double): TimeInterval_ | Null = (^.asInstanceOf[js.Dynamic].applyDynamic("utcTickInterval")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], count.asInstanceOf[js.Any])).asInstanceOf[TimeInterval_ | Null]
  
  @scala.inline
  def utcTicks(start: js.Date, stop: js.Date, count: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcTicks")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], count.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "utcTuesday")
  @js.native
  val utcTuesday: CountableTimeInterval = js.native
  
  @scala.inline
  def utcTuesdays(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcTuesdays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def utcTuesdays(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcTuesdays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "utcWednesday")
  @js.native
  val utcWednesday: CountableTimeInterval = js.native
  
  @scala.inline
  def utcWednesdays(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcWednesdays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def utcWednesdays(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcWednesdays")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "utcWeek")
  @js.native
  val utcWeek: CountableTimeInterval = js.native
  
  @scala.inline
  def utcWeeks(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcWeeks")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def utcWeeks(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcWeeks")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @JSImport("d3", "utcYear")
  @js.native
  val utcYear: CountableTimeInterval = js.native
  
  @scala.inline
  def utcYears(start: js.Date, stop: js.Date): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcYears")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  @scala.inline
  def utcYears(start: js.Date, stop: js.Date, step: Double): js.Array[js.Date] = (^.asInstanceOf[js.Dynamic].applyDynamic("utcYears")(start.asInstanceOf[js.Any], stop.asInstanceOf[js.Any], step.asInstanceOf[js.Any])).asInstanceOf[js.Array[js.Date]]
  
  @scala.inline
  def variance(iterable: js.Iterable[js.UndefOr[Numeric | Null]]): js.UndefOr[Double] = ^.asInstanceOf[js.Dynamic].applyDynamic("variance")(iterable.asInstanceOf[js.Any]).asInstanceOf[js.UndefOr[Double]]
  @scala.inline
  def variance[T](
    iterable: js.Iterable[T],
    accessor: js.Function3[
      /* datum */ T, 
      /* index */ Double, 
      /* array */ js.Iterable[T], 
      js.UndefOr[Double | Null]
    ]
  ): js.UndefOr[Double] = (^.asInstanceOf[js.Dynamic].applyDynamic("variance")(iterable.asInstanceOf[js.Any], accessor.asInstanceOf[js.Any])).asInstanceOf[js.UndefOr[Double]]
  
  @JSImport("d3", "version")
  @js.native
  val version: String = js.native
  
  @scala.inline
  def window(DOMNode: Document): Window = ^.asInstanceOf[js.Dynamic].applyDynamic("window")(DOMNode.asInstanceOf[js.Any]).asInstanceOf[Window]
  @scala.inline
  def window(DOMNode: Element): Window = ^.asInstanceOf[js.Dynamic].applyDynamic("window")(DOMNode.asInstanceOf[js.Any]).asInstanceOf[Window]
  @scala.inline
  def window(DOMNode: Window): Window = ^.asInstanceOf[js.Dynamic].applyDynamic("window")(DOMNode.asInstanceOf[js.Any]).asInstanceOf[Window]
  
  @scala.inline
  def xml(url: String): js.Promise[XMLDocument] = ^.asInstanceOf[js.Dynamic].applyDynamic("xml")(url.asInstanceOf[js.Any]).asInstanceOf[js.Promise[XMLDocument]]
  @scala.inline
  def xml(url: String, init: RequestInit): js.Promise[XMLDocument] = (^.asInstanceOf[js.Dynamic].applyDynamic("xml")(url.asInstanceOf[js.Any], init.asInstanceOf[js.Any])).asInstanceOf[js.Promise[XMLDocument]]
  
  @scala.inline
  def zip[T](arrays: ArrayLike[T]*): js.Array[js.Array[T]] = (^.asInstanceOf[js.Dynamic].applyDynamic("zip")(arrays.asInstanceOf[Seq[js.Any]] :_*)).asInstanceOf[js.Array[js.Array[T]]]
  
  @scala.inline
  def zoom[ZoomRefElement /* <: ZoomedElementBaseType */, Datum](): ZoomBehavior[ZoomRefElement, Datum] = ^.asInstanceOf[js.Dynamic].applyDynamic("zoom")().asInstanceOf[ZoomBehavior[ZoomRefElement, Datum]]
  
  @JSImport("d3", "zoomIdentity")
  @js.native
  val zoomIdentity: typings.d3Zoom.mod.ZoomTransform_ = js.native
  
  @scala.inline
  def zoomTransform(node: ZoomedElementBaseType): typings.d3Zoom.mod.ZoomTransform_ = ^.asInstanceOf[js.Dynamic].applyDynamic("zoomTransform")(node.asInstanceOf[js.Any]).asInstanceOf[typings.d3Zoom.mod.ZoomTransform_]
  
  /**
    * Extend interface 'Selection' by declaration merging with 'd3-selection'
    */
  /* augmented module */
  object d3SelectionAugmentingMod {
    
    /**
      * A D3 Selection of elements.
      *
      * The first generic "GElement" refers to the type of the selected element(s).
      * The second generic "Datum" refers to the type of the datum of a selected element(s).
      * The third generic "PElement" refers to the type of the parent element(s) in the D3 selection.
      * The fourth generic "PDatum" refers to the type of the datum of the parent element(s).
      */
    @js.native
    trait Selection[GElement /* <: BaseType */, Datum, PElement /* <: BaseType */, PDatum] extends StObject {
      
      /**
        * Interrupts the active transition of the specified name on the selected elements, and cancels any pending transitions with the specified name, if any.
        * If a name is not specified, null is used.
        *
        * IMPORTANT: Interrupting a transition on an element has no effect on any transitions on any descendant elements.
        * For example, an axis transition consists of multiple independent, synchronized transitions on the descendants of the axis G element
        * (the tick lines, the tick labels, the domain path, etc.). To interrupt the axis transition, you must therefore interrupt the descendants.
        *
        * @param name Name of the transition.
        */
      def interrupt(): this.type = js.native
      def interrupt(name: String): this.type = js.native
      
      /**
        * Returns a new transition on the given selection with the specified name. If a name is not specified, null is used.
        * The new transition is only exclusive with other transitions of the same name.
        *
        * @param name Name of the transition.
        */
      def transition(): Transition_[GElement, Datum, PElement, PDatum] = js.native
      def transition(name: String): Transition_[GElement, Datum, PElement, PDatum] = js.native
      /**
        * Returns a new transition on the given selection.
        *
        * When using a transition instance, the returned transition has the same id and name as the specified transition.
        * If a transition with the same id already exists on a selected element, the existing transition is returned for that element.
        * Otherwise, the timing of the returned transition is inherited from the existing transition of the same id on the nearest ancestor of each selected element.
        * Thus, this method can be used to synchronize a transition across multiple selections,
        * or to re-select a transition for specific elements and modify its configuration.
        *
        * If the specified transition is not found on a selected node or its ancestors (such as if the transition already ended),
        * the default timing parameters are used; however, in a future release, this will likely be changed to throw an error.
        *
        * @param transition A transition instance.
        */
      def transition(transition: Transition_[BaseType, Any, Any, Any]): Transition_[GElement, Datum, PElement, PDatum] = js.native
    }
  }
}
